// COMPONENT
import React, { FC, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// UTILS
import utils from '../utils'
import * as api from '../api'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'

/* COMPONENT SPECIFIC TYPES */
type TableProps = {
  portfolio: type.Portfolio
} & Partial<RouteComponentProps> &
  ReturnType<typeof mapDispatchToProps>

type PortfolioProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps

type TableHeaderTheme = {
  borderBottom?: string
  borderTop?: string
}

/* STYLES */
const StyledScenariosPortfolio = styled('section')({
  borderBottom: 'none',
})

const PortfolioGridContainer = styled('div')(
  ({ theme: computedGridStyles }) => ({
    display: 'grid',
    fontSize: '14px',
    width: '100%',
    maxHeight: '80vh',
    overflow: 'scroll',
    ...computedGridStyles,
  }),
)

const gridItemStyles = {
  padding: '0 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const TableHeader = styled('div')(({ theme }) => ({
  backgroundColor: '#b6b6b677',
  borderTop: '3px solid #00000000',
  borderBottom: '3px solid #00000000',
  boxSizing: 'border-box',
  fontWeight: 'bold',
  cursor: 'pointer',
  ...gridItemStyles,
  ...theme,
}))
const TableCell = styled('div')(({ theme }) => ({
  ...gridItemStyles,
  ...theme,
}))

const EmptyPortfolio = styled('div')(({ theme }) => ({
  ...gridItemStyles,
  ...theme,
  fontWeight: 'bold',
  padding: '1rem',
  backgroundColor: '#f6f6f6',
}))

const TableContent = styled.p({
  margin: '0.5rem',
})
const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})
export const ActionButton = styled('button')(({ theme }) => ({
  margin: '.25rem 0',
  padding: '.3rem 1rem',
  backgroundColor: '#e1e1e177',
  whiteSpace: 'nowrap',
  color: 'black',
  fontSize: '14px',
  border: '1px solid #ccc',
  ...theme,

  '&:hover': {
    borderBottom: '1px solid #ccc',
  },
}))

type DoubleCheckButtonProps = {
  onClick: Function
  theme: { color }
}
export const DoubleCheckButton: FC<DoubleCheckButtonProps> = ({
  onClick,
  theme,
  children,
}) => {
  const [clicked, setClicked] = React.useState(false)
  let timeout: any = null
  const clearTimeoutsOnUnmount = () => () => timeout && clearTimeout(timeout)
  React.useEffect(clearTimeoutsOnUnmount, [])
  return (
    <ActionButton
      onClick={(...args) => {
        if (!clicked) {
          setClicked(true)
          timeout = setTimeout(() => setClicked(false), 5000)
        } else onClick(...args)
      }}
      theme={theme}>
      {clicked ? 'Confirm ' : ''}
      {children}
    </ActionButton>
  )
}

/* COMPONENTS */
let uniqueKey = 0
const PortfolioTable: FC<TableProps> = ({
  portfolio: { headings, rows },
  fetchResults,
  loadConfig,
  loadConfigList,
  reloadSchema,
  deleteScenario,
  sortPortfolio,
  history,
}) => {
  const [sortKey, setSortKey] = React.useState('created_at')
  const [descendingSort, setDescendingSort] = React.useState(true)
  const [initialSort, setInitialSort] = React.useState(false)
  useEffect(() => {
    const loadConfigAndSort = async () => {
      await loadConfigList()
      sortPortfolio(sortKey, descendingSort)
      setInitialSort(true)
    }
    loadConfigAndSort()
  }, [])
  return (
    <PortfolioGridContainer
      className="scrollbox"
      theme={utils.portfolio.computedGridStyles(headings, rows)}>
      {Object.entries(headings).map(([key, heading]) => {
        const theme: TableHeaderTheme = {}
        if (sortKey === key) {
          if (descendingSort) theme.borderBottom = '3px solid gray'
          else theme.borderTop = '3px solid gray'
        }
        return (
          <TableHeader
            role="button"
            tabIndex={0}
            key={uniqueKey++}
            theme={theme}
            onClick={() => {
              const descending = sortKey === key ? !descendingSort : true
              sortPortfolio(key, descending)
              setSortKey(key)
              setDescendingSort(descending)
            }}>
            <TableContent>{heading}</TableContent>
          </TableHeader>
        )
      })}
      {rows.length && initialSort ? (
        rows.map((scenario, i) =>
          Object.keys(headings).map(key => {
            let value = <TableContent>{scenario[key]}</TableContent>
            if (key === 'uuid') {
              value = (
                <ButtonContainer>
                  <ActionButton
                    disabled={scenario.simulation_status !== api.successMessage}
                    theme={{}}
                    onClick={async () => {
                      await fetchResults(scenario[key])
                      if (history) history.push(`/output`)
                      else throw 'unexpected history is undefined'
                    }}>
                    View Output
                  </ActionButton>
                  <ActionButton
                    theme={{}}
                    onClick={async () => {
                      const { config } = await loadConfig(scenario.uuid)
                      await reloadSchema(config)
                      if (history) history.push(`/new-scenario`)
                      else throw 'unexpected history is undefined'
                    }}>
                    Modify Scenario
                  </ActionButton>
                  <DoubleCheckButton
                    onClick={() => deleteScenario(scenario[key])}
                    theme={{ color: '#d60000' }}>
                    Delete
                  </DoubleCheckButton>
                </ButtonContainer>
              )
            } else if (key === 'created_at') {
              value = (
                <TableContent>
                  {utils.portfolio.abbreviateDate(scenario[key])}
                  <br />
                  {utils.portfolio.abbreviateTime(scenario[key])}
                </TableContent>
              )
            }
            return (
              <TableCell
                key={uniqueKey++}
                theme={{ backgroundColor: i % 2 ? '#ebebeb77' : '#ffffff77' }}>
                {value}
              </TableCell>
            )
          }),
        )
      ) : (
        <EmptyPortfolio
          theme={{
            gridColumnStart: 1,
            gridColumnEnd: Object.keys(headings).length + 1,
          }}>
          <TableContent>---None---</TableContent>
        </EmptyPortfolio>
      )}
    </PortfolioGridContainer>
  )
}

const ScenariosPorfolio: FC<PortfolioProps> = props => {
  React.useEffect(() => {
    const monitors = props.portfolio.rows
      .filter(
        ({ simulation_status }) => simulation_status !== api.successMessage,
      )
      .map(({ uuid }) => props.monitorScenario(uuid))
    monitors.push(api.setStatusMonitor(props.loadConfigList, 3000))
    return () =>
      monitors.forEach(remove => typeof remove === 'function' && remove())
  }, [])
  return (
    <StyledScenariosPortfolio>
      <styles.components.OrangeH2>
        Previously Run Simulations
      </styles.components.OrangeH2>
      <PortfolioTable {...props} />
    </StyledScenariosPortfolio>
  )
}

const mapStateToProps = store => ({
  portfolio: store.portfolio,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  fetchResults: (uuid: string) => dispatch(actions.fetchResults(uuid)),
  deleteScenario: (uuid: string) => dispatch(actions.deleteScenario(uuid)),
  monitorScenario: (uuid: string) =>
    dispatch(actions.monitorStatus(uuid, false, true)),
  loadConfigList: () => dispatch(actions.loadConfigList()),
  loadConfig: (uuid: string) => dispatch(actions.loadConfig(uuid)),
  reloadSchema: (loadedData: type.OutputData) =>
    dispatch(actions.initSchema(loadedData)),
  sortPortfolio: (key: string, descending: boolean) =>
    dispatch(actions.sortPortfolio(key, descending)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScenariosPorfolio),
)
