import * as React from 'react'

export const CdcLogo = () => (
  <svg
    x="0px"
    y="0px"
    width="470px"
    height="47px"
    viewBox="62.585 302.859 470 47"
    enableBackground="new 62.585 302.859 470 47">
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M65.205,305.092h69.562v42.534H65.205V305.092z"
        />
      </g>
      <g>
        <path
          fill="#105EAB"
          d="M65.888,305.786h68.196v41.103H65.888V305.786z"
        />
      </g>
      <polygon
        fill="#FFFFFF"
        points="93.884,346.88 134.068,306.169 134.068,305.762 133.867,305.762 93.282,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="68.546,346.88 71.262,305.762 70.834,305.762 68.118,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="70.719,346.88 76.586,305.762 76.152,305.762 70.288,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="73.044,346.88 82.222,305.762 81.787,305.762 72.605,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="75.598,346.88 88.697,305.762 88.255,305.762 75.152,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="78.006,346.88 94.835,305.762 94.372,305.762 77.542,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="80.776,346.88 101.826,305.762 101.355,305.762 80.294,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="112.343,305.762 111.832,305.762 84.58,346.88 85.09,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="122.061,305.762 121.523,305.762 88.393,346.88 88.939,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="134.068,321.321 134.068,320.781 101.217,346.88 101.894,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="134.068,332.631 134.068,332.129 110.467,346.88 111.275,346.88 	"
      />
      <polygon
        fill="#FFFFFF"
        points="134.068,343.304 134.068,342.828 125.384,346.88 126.392,346.88 	"
      />
      <path
        fill="#FFFFFF"
        d="M86.244,330.483c-0.844,0.808-2.965,1.68-4.522,1.664c-4.53-0.004-6.647-3.854-6.686-9.735
		c0.031-5.567,2.315-9.109,6.239-9.116c2.806,0.005,4.17,1.631,4.915,2.601l0.821,1.095v-5.061l-0.262-0.121
		c-1.617-0.752-3.671-1.104-5.543-1.108c-7.044,0.016-12.421,5.065-12.431,12.194c0.01,7.312,5.092,11.902,12.37,11.914h0.037
		c3.614,0,5.467-1.09,6.445-1.839l0.29-0.22l-1.206-2.707L86.244,330.483z M105.983,313.331c-2.358-1.729-5.31-2.24-7.828-2.24
		h-8.604v23.305h9.164c4.444-0.01,11.312-2.83,11.323-11.717C110.046,318.027,108.348,315.061,105.983,313.331z M95.21,331.691
		v-17.969c4.162,0.111,8.59,0.618,8.669,9.051C103.812,330.964,99.281,331.579,95.21,331.691z M129.665,330.046l-0.467,0.438
		c-0.845,0.81-2.959,1.679-4.515,1.663c-4.531,0-6.65-3.854-6.688-9.733c0.031-5.569,2.318-9.11,6.247-9.117
		c2.802,0.003,4.169,1.631,4.899,2.601l0.825,1.108v-5.072l-0.261-0.123c-1.609-0.755-3.662-1.103-5.542-1.107
		c-7.042,0.015-12.405,5.064-12.417,12.194c0.012,7.313,5.081,11.9,12.363,11.914h0.057c3.601,0,5.447-1.091,6.426-1.838l0.29-0.222
		L129.665,330.046z"
      />
      <path
        fill="#0033A0"
        d="M135.297,345.739h0.822v0.122h-0.344v0.883h-0.13v-0.883h-0.348V345.739z"
      />
      <path
        fill="#0033A0"
        d="M137.075,346.743v-0.603c0-0.021,0-0.151,0-0.246l0,0l-0.286,0.849h-0.134l-0.283-0.849h-0.003
		c0,0.095,0.003,0.225,0.003,0.246v0.603h-0.134v-1.004h0.191l0.293,0.851h0.004l0.281-0.851h0.196v1.004H137.075z"
      />
    </g>
    <g>
      <path
        d="M141.047,318.988c0.125-1.301,0.57-2.406,1.273-3.356c0.705-0.968,1.582-1.72,2.696-2.2
		c1.052-0.521,2.174-0.781,3.365-0.781c0.618,0,1.256,0.104,1.886,0.23c0.621,0.164,1.184,0.396,1.755,0.694
		c0.563,0.331,1.093,0.704,1.547,1.224c0.517,0.476,0.905,1.026,1.283,1.729h-1.56c-0.418-0.548-0.892-1.021-1.438-1.389
		c-0.519-0.363-1.073-0.646-1.661-0.834c-0.59-0.15-1.179-0.225-1.766-0.225c-0.756,0-1.512,0.143-2.26,0.395
		c-0.715,0.291-1.329,0.703-1.899,1.237c-0.571,0.493-1.044,1.17-1.356,1.903c-0.337,0.733-0.531,1.562-0.531,2.401
		c0.015,1.132,0.334,2.193,0.897,3.064c0.563,0.908,1.299,1.597,2.208,2.142c0.909,0.465,1.891,0.767,2.942,0.776
		c0.571-0.01,1.165-0.146,1.771-0.356c0.606-0.173,1.173-0.43,1.699-0.811c0.528-0.387,1.039-0.9,1.443-1.486h1.558
		c-0.499,0.907-1.113,1.636-1.781,2.252c-0.717,0.574-1.468,0.985-2.229,1.279c-0.792,0.251-1.637,0.438-2.461,0.438
		c-0.941,0-1.89-0.19-2.778-0.557c-0.873-0.351-1.689-0.81-2.384-1.51c-0.696-0.618-1.263-1.411-1.671-2.296
		c-0.418-0.927-0.629-1.921-0.629-3.001C140.967,319.582,140.994,319.284,141.047,318.988z"
      />
      <path
        d="M156.218,321.771c0.029-0.867,0.186-1.623,0.524-2.267c0.292-0.693,0.719-1.254,1.225-1.741
		c0.506-0.464,1.088-0.832,1.747-1.063c0.623-0.247,1.275-0.372,1.941-0.386c0.915,0,1.757,0.229,2.625,0.62
		c0.805,0.455,1.531,1.12,2.058,1.985c0.524,0.868,0.801,1.921,0.801,3.111v0.272h-9.471c0.023,0.783,0.275,1.43,0.704,1.994
		c0.427,0.56,0.91,0.947,1.509,1.244c0.6,0.268,1.186,0.417,1.758,0.417c0.49,0,0.986-0.135,1.527-0.346
		c0.526-0.195,0.95-0.451,1.353-0.797c0.405-0.347,0.717-0.744,0.875-1.192h1.439c-0.267,0.823-0.71,1.479-1.273,2.015
		c-0.563,0.574-1.214,0.997-1.893,1.271c-0.687,0.229-1.429,0.405-2.126,0.405c-0.929-0.049-1.78-0.298-2.612-0.752
		c-0.833-0.478-1.451-1.13-1.98-1.94C156.483,323.769,156.218,322.831,156.218,321.771z M165.757,321.077
		c-0.129-0.636-0.364-1.173-0.744-1.705c-0.38-0.548-0.891-0.931-1.468-1.254c-0.563-0.286-1.16-0.433-1.866-0.433
		c-0.884,0-1.628,0.187-2.235,0.628c-0.605,0.44-1.002,0.886-1.313,1.444c-0.255,0.529-0.44,0.951-0.46,1.319H165.757
		L165.757,321.077z"
      />
      <path
        d="M168.963,327.095v-10.546h1.352v1.592c0.336-0.563,0.789-1.028,1.388-1.318c0.611-0.29,1.273-0.474,1.924-0.474
		c0.598,0,1.168,0.117,1.712,0.317c0.543,0.238,1.056,0.524,1.462,0.94c0.452,0.414,0.788,0.882,1.068,1.519
		c0.23,0.602,0.388,1.251,0.388,1.998v5.972h-1.401v-5.972c0-0.714-0.145-1.33-0.462-1.845c-0.321-0.479-0.738-0.865-1.222-1.162
		c-0.484-0.254-1.046-0.387-1.608-0.387c-0.571,0-1.101,0.131-1.558,0.365c-0.519,0.282-0.903,0.645-1.186,1.104
		c-0.28,0.51-0.47,1.118-0.47,1.775v6.12H168.963z"
      />
      <path d="M181.308,327.095v-9.234h-2.225v-1.312h2.225v-3.676h1.411v3.676h2.428v1.312h-2.428v9.234H181.308z" />
      <path
        d="M185.469,321.771c0.029-0.867,0.189-1.623,0.532-2.267c0.284-0.693,0.71-1.254,1.217-1.741
		c0.514-0.464,1.088-0.832,1.747-1.063c0.657-0.247,1.275-0.372,1.976-0.386c0.879,0,1.723,0.229,2.59,0.62
		c0.819,0.455,1.533,1.12,2.059,1.985c0.524,0.868,0.818,1.921,0.818,3.111v0.272h-9.489c0.058,0.783,0.276,1.43,0.704,1.994
		c0.428,0.56,0.91,0.947,1.509,1.244c0.601,0.268,1.187,0.417,1.76,0.417c0.489,0,0.998-0.135,1.549-0.346
		c0.501-0.195,0.96-0.451,1.338-0.797c0.396-0.347,0.709-0.744,0.867-1.192h1.439c-0.267,0.823-0.711,1.479-1.274,2.015
		c-0.562,0.574-1.213,0.997-1.892,1.271c-0.679,0.229-1.407,0.405-2.126,0.405c-0.93-0.049-1.779-0.298-2.612-0.752
		c-0.826-0.478-1.451-1.13-1.96-1.94C185.732,323.768,185.469,322.831,185.469,321.771z M195.007,321.077
		c-0.097-0.636-0.364-1.173-0.745-1.705c-0.378-0.548-0.861-0.931-1.424-1.254c-0.57-0.284-1.205-0.433-1.89-0.433
		c-0.903,0-1.649,0.187-2.255,0.628c-0.583,0.44-1.002,0.886-1.313,1.444c-0.249,0.529-0.405,0.951-0.46,1.319H195.007
		L195.007,321.077z"
      />
      <path
        d="M198.225,327.095v-10.551h1.327v1.547c0.588-1.161,1.601-1.775,3.081-1.775v1.413c-0.347,0-0.709,0.094-1.058,0.237
		c-0.349,0.105-0.659,0.328-0.992,0.592c-0.273,0.254-0.527,0.578-0.7,0.916c-0.175,0.336-0.242,0.708-0.242,1.12v6.499h-1.416
		V327.095z"
      />
      <path
        d="M203.5,319.636c-0.017-0.068-0.025-0.243-0.025-0.435c0-0.585,0.139-1.133,0.479-1.518c0.278-0.448,0.659-0.76,1.144-1.007
		c0.422-0.21,0.892-0.328,1.343-0.328c0.345,0,0.688,0.051,1.029,0.195c0.341,0.09,0.671,0.282,0.962,0.512
		c0.28,0.244,0.51,0.537,0.687,0.89c0.176,0.355,0.308,0.771,0.333,1.25h-1.476c-0.021-0.54-0.186-0.905-0.458-1.14
		c-0.319-0.222-0.691-0.368-1.076-0.368c-0.378,0-0.755,0.155-1.088,0.368c-0.308,0.273-0.475,0.631-0.504,1.14
		c0.004,0.002,0.028,0.042,0.072,0.192c0.009,0.18,0.011,0.235,0.021,0.295c0.128,0.335,0.442,0.641,1.002,0.861
		c0.561,0.229,1.126,0.481,1.696,0.686c0.57,0.233,1.068,0.535,1.446,0.962c0.379,0.399,0.575,0.966,0.575,1.736
		c0,0.173-0.012,0.349-0.012,0.564c-0.087,0.688-0.309,1.19-0.647,1.62c-0.339,0.433-0.759,0.71-1.243,0.914
		c-0.459,0.166-0.941,0.288-1.372,0.288c-0.657,0-1.225-0.187-1.702-0.454c-0.514-0.294-0.892-0.686-1.174-1.207
		c-0.288-0.463-0.427-1.036-0.427-1.645l1.456-0.057c0.009,0.598,0.195,1.103,0.573,1.467c0.384,0.39,0.848,0.581,1.327,0.583
		c0.534-0.004,0.94-0.21,1.317-0.581c0.343-0.334,0.515-0.756,0.515-1.238c0-0.446-0.136-0.836-0.439-1.187
		c-0.26-0.344-0.706-0.593-1.298-0.743c-0.815-0.206-1.476-0.482-2.043-0.872C203.927,320.996,203.605,320.399,203.5,319.636z"
      />
      <path
        d="M217.104,327.095v-9.227h-1.59v-1.267h1.59c0-2.454,1.356-3.68,4.071-3.68v1.319c-0.815,0-1.478,0.136-1.93,0.44
		c-0.515,0.351-0.721,0.917-0.742,1.796c0.033,0.064,0.034,0.066,0.034,0.124h2.637v1.267h-2.637v9.227H217.104L217.104,327.095z"
      />
      <path
        d="M221.432,321.64c0.013-0.685,0.144-1.322,0.455-2.003c0.25-0.63,0.646-1.195,1.186-1.686
		c0.49-0.488,1.098-0.884,1.769-1.183c0.684-0.256,1.44-0.42,2.243-0.42c0.773,0,1.568,0.195,2.389,0.646
		c0.779,0.462,1.469,1.061,2.013,1.816s0.842,1.596,0.922,2.524c0,0.062,0.003,0.129,0.012,0.192
		c0.008,0.111,0.013,0.163,0.013,0.217c0,0.78-0.131,1.497-0.455,2.162c-0.262,0.678-0.675,1.299-1.177,1.771
		c-0.548,0.51-1.118,0.913-1.802,1.205c-0.639,0.249-1.381,0.435-2.108,0.435c-0.867,0-1.744-0.213-2.581-0.678
		c-0.86-0.464-1.54-1.082-2.063-1.976C221.725,323.812,221.432,322.796,221.432,321.64z M222.833,321.719
		c0,0.865,0.186,1.595,0.558,2.19c0.372,0.625,0.853,1.144,1.504,1.495c0.631,0.343,1.315,0.558,2.055,0.596
		c0.639,0,1.223-0.189,1.852-0.529c0.638-0.321,1.132-0.808,1.547-1.46c0.415-0.664,0.623-1.42,0.623-2.292
		c0-0.51-0.142-1.01-0.339-1.479c-0.192-0.48-0.508-0.921-0.881-1.301s-0.822-0.688-1.289-0.884
		c-0.476-0.229-1.01-0.368-1.512-0.368c-0.69,0.014-1.389,0.211-1.987,0.563c-0.635,0.363-1.134,0.827-1.518,1.427
		C223.054,320.313,222.85,320.968,222.833,321.719z"
      />
      <path
        d="M234.281,327.095v-10.551h1.326v1.547c0.589-1.161,1.634-1.775,3.082-1.775v1.413c-0.316,0-0.691,0.094-1.058,0.237
		c-0.348,0.107-0.66,0.328-0.953,0.592c-0.312,0.254-0.564,0.578-0.74,0.916c-0.175,0.336-0.23,0.708-0.23,1.12v6.499h-1.426
		V327.095z"
      />
      <path
        d="M245.208,327.095v-14.222h3.885c1.331,0,2.507,0.207,3.505,0.584c1.046,0.374,1.869,0.863,2.593,1.575
		c0.662,0.648,1.156,1.42,1.522,2.268c0.349,0.885,0.51,1.831,0.51,2.821c-0.013,1.028-0.228,2.016-0.645,2.857
		c-0.417,0.886-0.969,1.621-1.656,2.264c-0.688,0.569-1.49,1.058-2.369,1.35c-0.898,0.337-1.792,0.503-2.722,0.503H245.208z
		 M246.659,314.137v11.638h2.161c1.997,0,3.591-0.491,4.87-1.392c1.298-0.938,1.98-2.352,2.083-4.262
		c0-0.802-0.118-1.553-0.396-2.261c-0.258-0.744-0.682-1.342-1.232-1.914c-0.549-0.536-1.235-0.994-2.117-1.296
		c-0.819-0.374-1.82-0.515-2.935-0.515L246.659,314.137L246.659,314.137z"
      />
      <path d="M259.283,315.321v-2.448h1.388v2.448H259.283z M259.283,327.095v-10.546h1.388v10.546H259.283z" />
      <path
        d="M262.761,319.636c0-0.068,0-0.243,0-0.435c0-0.585,0.158-1.133,0.437-1.518c0.319-0.448,0.697-0.76,1.145-1.007
		c0.48-0.21,0.891-0.328,1.342-0.328c0.345,0,0.689,0.051,1.031,0.195c0.377,0.09,0.701,0.282,0.962,0.512
		c0.322,0.244,0.549,0.537,0.747,0.89c0.177,0.355,0.247,0.771,0.271,1.25h-1.413c-0.033-0.54-0.193-0.905-0.521-1.14
		c-0.271-0.222-0.629-0.368-1.074-0.368c-0.377,0-0.72,0.155-1.038,0.368c-0.358,0.273-0.526,0.631-0.555,1.14
		c0.004,0.002,0.028,0.042,0.072,0.192c0.043,0.18,0.072,0.235,0.084,0.295c0.066,0.335,0.399,0.641,0.952,0.861
		c0.581,0.229,1.134,0.481,1.715,0.686c0.553,0.233,1.057,0.535,1.455,0.962c0.398,0.399,0.598,0.966,0.598,1.736
		c0,0.173-0.019,0.349-0.057,0.564c-0.102,0.688-0.31,1.19-0.663,1.62c-0.338,0.433-0.76,0.71-1.201,0.914
		c-0.502,0.166-0.931,0.288-1.415,0.288c-0.607,0-1.188-0.187-1.701-0.454c-0.478-0.294-0.87-0.686-1.172-1.207
		c-0.245-0.463-0.38-1.036-0.38-1.645l1.407-0.057c0.024,0.598,0.233,1.103,0.626,1.467c0.394,0.39,0.795,0.581,1.332,0.583
		c0.478-0.004,0.918-0.21,1.26-0.581c0.344-0.334,0.514-0.756,0.514-1.238c0-0.446-0.125-0.836-0.428-1.187
		c-0.261-0.344-0.715-0.593-1.307-0.743c-0.76-0.206-1.477-0.482-2.045-0.872C263.172,320.992,262.86,320.399,262.761,319.636z"
      />
      <path
        d="M270.167,321.771c0.028-0.867,0.185-1.623,0.529-2.267c0.287-0.693,0.713-1.254,1.22-1.741
		c0.507-0.464,1.088-0.832,1.746-1.063c0.612-0.247,1.275-0.372,1.949-0.386c0.908,0,1.752,0.229,2.62,0.62
		c0.805,0.455,1.531,1.12,2.057,1.985c0.526,0.868,0.789,1.921,0.789,3.111v0.272h-9.459c0.021,0.783,0.275,1.43,0.704,1.994
		c0.427,0.56,0.91,0.947,1.51,1.244c0.599,0.268,1.186,0.417,1.757,0.417c0.491,0,0.985-0.135,1.534-0.346
		c0.519-0.195,0.941-0.451,1.345-0.797c0.406-0.347,0.716-0.744,0.875-1.192h1.44c-0.268,0.823-0.711,1.479-1.273,2.015
		c-0.563,0.574-1.215,0.997-1.894,1.271c-0.696,0.229-1.428,0.405-2.126,0.405c-0.93-0.049-1.781-0.298-2.613-0.752
		c-0.833-0.478-1.45-1.13-1.979-1.94C270.431,323.768,270.167,322.831,270.167,321.771z M279.705,321.077
		c-0.141-0.636-0.364-1.173-0.744-1.705c-0.381-0.548-0.891-0.931-1.46-1.254c-0.581-0.284-1.168-0.433-1.874-0.433
		c-0.883,0-1.63,0.187-2.235,0.628c-0.605,0.44-1.01,0.886-1.313,1.444c-0.251,0.529-0.451,0.951-0.46,1.319H279.705
		L279.705,321.077z"
      />
      <path
        d="M282.404,321.871c0.007-0.792,0.167-1.554,0.467-2.304c0.301-0.684,0.741-1.265,1.267-1.745
		c0.542-0.52,1.139-0.889,1.842-1.112c0.641-0.264,1.346-0.393,2.026-0.393c1.93,0.031,3.273,0.791,4.116,2.299v-2.067h1.402v10.546
		h-1.402v-2.028c-0.399,0.73-0.968,1.29-1.712,1.684c-0.744,0.376-1.573,0.568-2.532,0.568c-0.734-0.024-1.398-0.192-2.064-0.426
		c-0.65-0.262-1.245-0.623-1.751-1.088c-0.516-0.504-0.921-1.056-1.255-1.702C282.539,323.396,282.404,322.675,282.404,321.871z
		 M283.843,321.56c0,0.853,0.188,1.618,0.57,2.294c0.378,0.637,0.858,1.186,1.497,1.533c0.639,0.349,1.266,0.555,1.969,0.575
		c0.792,0,1.513-0.215,2.178-0.604c0.668-0.387,1.159-0.87,1.523-1.52c0.363-0.626,0.544-1.248,0.544-1.97
		c0-0.74-0.174-1.4-0.522-2.028c-0.349-0.644-0.873-1.129-1.458-1.504c-0.636-0.376-1.306-0.608-2.062-0.649
		c-0.799,0-1.521,0.217-2.195,0.628c-0.653,0.41-1.161,0.884-1.514,1.513C284.019,320.428,283.843,321.031,283.843,321.56z"
      />
      <path
        d="M295.586,319.636c-0.018-0.068-0.024-0.243-0.024-0.435c0-0.585,0.139-1.133,0.457-1.518c0.3-0.448,0.683-0.76,1.134-1.007
		c0.454-0.21,0.923-0.328,1.314-0.328c0.372,0,0.747,0.051,1.089,0.195c0.34,0.09,0.642,0.282,0.964,0.512
		c0.258,0.244,0.507,0.537,0.686,0.89c0.176,0.355,0.279,0.771,0.28,1.25h-1.422c-0.033-0.54-0.186-0.905-0.47-1.14
		c-0.322-0.222-0.681-0.368-1.124-0.368c-0.378,0-0.722,0.155-1.027,0.368c-0.309,0.273-0.493,0.631-0.505,1.14
		c0.005,0.002,0.012,0.042,0.013,0.192c0.04,0.18,0.068,0.235,0.081,0.295c0.121,0.335,0.442,0.641,1.004,0.861
		c0.559,0.229,1.125,0.481,1.696,0.686c0.569,0.233,1.022,0.535,1.422,0.962c0.396,0.399,0.597,0.966,0.597,1.736
		c0,0.173-0.019,0.349-0.056,0.564c-0.042,0.688-0.263,1.19-0.603,1.62c-0.363,0.433-0.759,0.71-1.262,0.914
		c-0.44,0.166-0.932,0.288-1.369,0.288c-0.641,0-1.208-0.187-1.688-0.454c-0.537-0.294-0.929-0.686-1.172-1.207
		c-0.305-0.463-0.428-1.036-0.428-1.645l1.399-0.057c0.02,0.598,0.228,1.103,0.621,1.467c0.393,0.39,0.857,0.581,1.332,0.583
		c0.517-0.004,0.917-0.21,1.274-0.581c0.377-0.334,0.551-0.756,0.551-1.238c0-0.446-0.173-0.836-0.428-1.187
		c-0.313-0.344-0.708-0.593-1.299-0.743c-0.813-0.206-1.476-0.482-2.053-0.872C296.012,320.996,295.643,320.399,295.586,319.636z"
      />
      <path
        d="M302.996,321.771c0-0.867,0.197-1.623,0.483-2.267c0.283-0.693,0.71-1.254,1.232-1.741c0.55-0.464,1.131-0.832,1.731-1.063
		c0.658-0.247,1.292-0.372,1.976-0.386c0.881,0,1.759,0.229,2.591,0.62c0.867,0.455,1.533,1.12,2.058,1.985
		c0.525,0.868,0.819,1.921,0.819,3.111v0.272h-9.49c0.084,0.783,0.315,1.43,0.705,1.994c0.426,0.56,0.919,0.947,1.51,1.244
		c0.599,0.268,1.187,0.417,1.758,0.417c0.501,0,1.046-0.135,1.55-0.346c0.501-0.195,0.987-0.451,1.39-0.797
		c0.381-0.347,0.656-0.744,0.814-1.192h1.456c-0.284,0.823-0.728,1.479-1.291,2.015c-0.563,0.574-1.155,0.997-1.893,1.271
		c-0.678,0.229-1.366,0.405-2.107,0.405c-0.931-0.049-1.799-0.298-2.63-0.752c-0.774-0.478-1.452-1.13-1.92-1.94
		C303.211,323.768,302.996,322.831,302.996,321.771z M312.484,321.077c-0.094-0.636-0.363-1.173-0.743-1.705
		c-0.381-0.548-0.83-0.931-1.407-1.254c-0.579-0.284-1.222-0.433-1.866-0.433c-0.937,0-1.689,0.187-2.246,0.628
		c-0.594,0.44-1.052,0.886-1.337,1.444c-0.276,0.529-0.429,0.951-0.487,1.319H312.484L312.484,321.077z"
      />
      <path
        d="M320.455,318.988c0.13-1.301,0.569-2.406,1.273-3.356c0.746-0.968,1.644-1.72,2.696-2.2
		c1.074-0.521,2.208-0.781,3.366-0.781c0.668,0,1.284,0.104,1.887,0.23c0.629,0.164,1.225,0.396,1.783,0.694
		c0.559,0.331,1.063,0.704,1.581,1.224c0.456,0.476,0.892,1.026,1.252,1.729H332.7c-0.4-0.548-0.857-1.021-1.375-1.389
		c-0.536-0.363-1.117-0.646-1.671-0.834c-0.583-0.15-1.17-0.225-1.76-0.225c-0.789,0-1.541,0.143-2.257,0.395
		c-0.722,0.291-1.391,0.703-1.961,1.237c-0.571,0.493-1.027,1.17-1.357,1.903c-0.314,0.733-0.47,1.562-0.47,2.401
		c0.018,1.132,0.274,2.193,0.837,3.064c0.562,0.908,1.3,1.597,2.23,2.142c0.947,0.465,1.89,0.767,2.922,0.776
		c0.613-0.01,1.225-0.146,1.816-0.356c0.558-0.173,1.135-0.43,1.687-0.811c0.553-0.387,1.004-0.9,1.434-1.486h1.587
		c-0.549,0.907-1.132,1.636-1.835,2.252c-0.669,0.574-1.406,0.985-2.213,1.279c-0.809,0.251-1.619,0.438-2.475,0.438
		c-0.938,0-1.844-0.19-2.722-0.557c-0.931-0.351-1.713-0.81-2.442-1.51c-0.696-0.618-1.253-1.411-1.672-2.296
		c-0.417-0.927-0.622-1.921-0.622-3.001C320.382,319.582,320.401,319.284,320.455,318.988z"
      />
      <path
        d="M335.675,321.64c0.012-0.685,0.139-1.322,0.405-2.003c0.297-0.63,0.695-1.195,1.184-1.686
		c0.501-0.488,1.094-0.884,1.819-1.183c0.671-0.256,1.419-0.42,2.243-0.42c0.773,0,1.556,0.195,2.34,0.646
		c0.819,0.462,1.484,1.061,2.035,1.816c0.571,0.756,0.858,1.596,0.948,2.524c0,0.062,0.004,0.129,0.01,0.192
		c0.002,0.111,0.002,0.163,0.002,0.217c0,0.78-0.179,1.497-0.44,2.162c-0.308,0.678-0.693,1.299-1.238,1.771
		c-0.495,0.51-1.104,0.913-1.739,1.205c-0.688,0.249-1.392,0.435-2.118,0.435c-0.889,0-1.76-0.213-2.604-0.678
		c-0.836-0.464-1.515-1.082-2.064-1.976C335.904,323.812,335.675,322.796,335.675,321.64z M337.014,321.719
		c0,0.865,0.2,1.595,0.577,2.19c0.378,0.625,0.886,1.144,1.512,1.495c0.623,0.343,1.309,0.558,2.064,0.596
		c0.611,0,1.238-0.189,1.864-0.529c0.608-0.321,1.13-0.808,1.536-1.46c0.381-0.664,0.6-1.42,0.6-2.292
		c0-0.51-0.095-1.01-0.314-1.479c-0.218-0.48-0.485-0.921-0.86-1.301c-0.372-0.38-0.823-0.688-1.313-0.884
		c-0.49-0.229-0.985-0.368-1.512-0.368c-0.728,0.014-1.364,0.211-2.005,0.563c-0.641,0.363-1.136,0.827-1.529,1.427
		C337.241,320.313,337.03,320.968,337.014,321.719z"
      />
      <path
        d="M348.513,327.095v-10.546h1.329v1.592c0.336-0.563,0.8-1.028,1.433-1.318c0.588-0.29,1.226-0.474,1.892-0.474
		c0.607,0,1.153,0.117,1.721,0.317c0.549,0.238,1.021,0.524,1.463,0.94c0.453,0.414,0.789,0.882,1.04,1.519
		c0.277,0.602,0.389,1.251,0.389,1.998v5.972h-1.371v-5.972c0-0.714-0.171-1.33-0.495-1.845c-0.288-0.479-0.706-0.865-1.214-1.162
		c-0.479-0.254-1.05-0.387-1.599-0.387c-0.58,0-1.064,0.131-1.544,0.365c-0.519,0.282-0.912,0.645-1.186,1.104
		c-0.307,0.51-0.471,1.118-0.471,1.775v6.12H348.513z"
      />
      <path d="M360.82,327.095v-9.234h-2.189v-1.312h2.189v-3.676h1.416v3.676h2.459v1.312h-2.459v9.234H360.82z" />
      <path
        d="M365.984,327.095v-10.551h1.289v1.547c0.63-1.161,1.639-1.775,3.084-1.775v1.413c-0.316,0-0.648,0.094-1.018,0.237
		c-0.351,0.107-0.664,0.328-1.012,0.592c-0.294,0.254-0.5,0.578-0.707,0.916c-0.152,0.336-0.247,0.708-0.247,1.12v6.499h-1.389
		V327.095z"
      />
      <path
        d="M370.604,321.64c0.012-0.685,0.184-1.322,0.433-2.003c0.27-0.63,0.669-1.195,1.192-1.686
		c0.527-0.488,1.092-0.884,1.78-1.183c0.715-0.256,1.472-0.42,2.242-0.42c0.771,0,1.566,0.195,2.372,0.646
		c0.818,0.462,1.471,1.061,2.064,1.816c0.505,0.756,0.839,1.596,0.882,2.524c0,0.062,0.004,0.129,0.012,0.192
		c0.01,0.111,0.014,0.163,0.014,0.217c0,0.78-0.092,1.497-0.454,2.162c-0.273,0.678-0.674,1.299-1.14,1.771
		c-0.551,0.51-1.14,0.913-1.837,1.205c-0.65,0.249-1.333,0.435-2.059,0.435c-0.925,0-1.776-0.213-2.598-0.678
		c-0.856-0.464-1.527-1.082-2.106-1.976C370.917,323.812,370.604,322.796,370.604,321.64z M372.026,321.719
		c0,0.865,0.143,1.595,0.515,2.19c0.373,0.625,0.914,1.144,1.507,1.495c0.675,0.343,1.358,0.558,2.099,0.596
		c0.591,0,1.206-0.189,1.867-0.529c0.575-0.321,1.133-0.808,1.535-1.46c0.415-0.664,0.574-1.42,0.574-2.292
		c0-0.51-0.107-1.01-0.299-1.479c-0.206-0.48-0.535-0.921-0.91-1.301c-0.372-0.38-0.807-0.688-1.254-0.884
		c-0.513-0.229-1.041-0.368-1.513-0.368c-0.691,0.014-1.418,0.211-2.021,0.563c-0.603,0.363-1.136,0.827-1.528,1.427
		C372.205,320.313,372.043,320.968,372.026,321.719z"
      />
      <path d="M383.463,327.095v-14.222h1.45v14.222H383.463z" />
      <path
        d="M391.787,321.871c0.013-0.792,0.225-1.554,0.526-2.304c0.298-0.684,0.741-1.265,1.306-1.745
		c0.486-0.52,1.12-0.889,1.802-1.112c0.643-0.264,1.296-0.393,1.977-0.393c1.919,0.031,3.373,0.791,4.143,2.299v-2.067h1.424v10.546
		h-1.424v-2.028c-0.363,0.73-0.945,1.29-1.688,1.684c-0.745,0.376-1.617,0.568-2.497,0.568c-0.713-0.024-1.469-0.192-2.123-0.426
		c-0.627-0.262-1.249-0.623-1.729-1.088c-0.565-0.504-0.981-1.056-1.255-1.702C391.98,323.396,391.787,322.675,391.787,321.871z
		 M393.229,321.56c0,0.853,0.245,1.618,0.626,2.294c0.379,0.637,0.885,1.186,1.496,1.533c0.577,0.35,1.292,0.555,2.006,0.575
		c0.798,0,1.529-0.215,2.142-0.604c0.609-0.387,1.158-0.87,1.522-1.52c0.362-0.626,0.521-1.248,0.521-1.97
		c0-0.74-0.15-1.4-0.498-2.028c-0.35-0.644-0.876-1.129-1.455-1.504c-0.678-0.376-1.361-0.608-2.101-0.649
		c-0.826,0-1.546,0.217-2.161,0.628c-0.615,0.41-1.161,0.884-1.513,1.513C393.461,320.428,393.229,321.031,393.229,321.56z"
      />
      <path
        d="M405.32,327.095v-10.546h1.388v1.592c0.337-0.563,0.84-1.028,1.39-1.318c0.639-0.29,1.271-0.474,1.922-0.474
		c0.592,0,1.169,0.117,1.74,0.317c0.515,0.238,1.045,0.524,1.498,0.94c0.377,0.414,0.77,0.882,1.006,1.519
		c0.219,0.602,0.389,1.251,0.389,1.998v5.972h-1.381v-5.972c0-0.714-0.164-1.33-0.452-1.845c-0.304-0.479-0.706-0.865-1.251-1.162
		c-0.547-0.254-1.013-0.387-1.647-0.387c-0.494,0-1.063,0.131-1.577,0.365c-0.399,0.282-0.794,0.645-1.119,1.104
		c-0.343,0.51-0.418,1.118-0.418,1.775v6.12H405.32z"
      />
      <path
        d="M416.408,321.334c0.111-1.004,0.395-1.827,0.974-2.583c0.454-0.755,1.181-1.366,1.937-1.797
		c0.76-0.41,1.596-0.605,2.464-0.639c0.921,0,1.679,0.184,2.435,0.511c0.766,0.329,1.472,0.859,1.904,1.615v-5.568h1.469v14.222
		h-1.412v-2.029c-0.449,0.745-1.087,1.305-1.765,1.69c-0.681,0.37-1.565,0.561-2.532,0.561c-0.896-0.049-1.783-0.304-2.563-0.762
		c-0.847-0.499-1.52-1.136-2.11-1.97c-0.55-0.851-0.824-1.771-0.824-2.783C416.384,321.587,416.392,321.465,416.408,321.334z
		 M417.846,321.229c-0.032,0.127-0.039,0.301-0.039,0.599c0,0.673,0.229,1.324,0.586,1.942c0.357,0.675,0.892,1.151,1.479,1.552
		c0.588,0.398,1.321,0.625,2.077,0.643c0.754,0,1.404-0.188,2.026-0.545c0.634-0.358,1.205-0.855,1.628-1.51
		c0.423-0.63,0.573-1.389,0.573-2.27c0-0.463-0.073-0.977-0.448-1.638c-0.283-0.622-0.756-1.174-1.38-1.574
		c-0.6-0.482-1.412-0.74-2.399-0.74c-0.482,0-1.085,0.149-1.598,0.368c-0.638,0.234-1.072,0.613-1.558,1.142
		C418.311,319.744,418.037,320.391,417.846,321.229z"
      />
      <path
        d="M435.191,327.095v-14.222h3.711c1.112,0,2.019,0.114,2.842,0.347c0.825,0.271,1.439,0.687,1.968,1.252
		c0.529,0.563,0.776,1.383,0.868,2.443c0.017,0.039,0.025,0.189,0.025,0.383c0,0.758-0.277,1.484-0.707,2.074
		c-0.393,0.599-0.954,1.115-1.696,1.446c-0.743,0.332-1.619,0.518-2.504,0.518h-3.112v5.759H435.191L435.191,327.095z
		 M436.586,314.137v5.934h2.907c1.287,0,2.132-0.3,2.783-0.873c0.525-0.579,0.837-1.337,0.837-2.282
		c0-1.049-0.379-1.743-1.119-2.164c-0.756-0.421-1.716-0.614-2.985-0.614H436.586z"
      />
      <path
        d="M446.005,327.095v-10.551h1.28v1.547c0.636-1.161,1.623-1.775,3.066-1.775v1.413c-0.313,0-0.646,0.094-0.997,0.237
		c-0.348,0.107-0.658,0.328-0.95,0.592c-0.363,0.254-0.569,0.578-0.756,0.916c-0.16,0.336-0.278,0.708-0.278,1.12v6.499h-1.365
		V327.095z"
      />
      <path
        d="M450.672,321.771c0-0.867,0.193-1.623,0.478-2.267c0.284-0.693,0.71-1.254,1.279-1.741
		c0.511-0.464,1.088-0.832,1.684-1.063c0.722-0.247,1.337-0.372,1.979-0.386c0.94,0,1.776,0.229,2.59,0.62
		c0.868,0.455,1.532,1.12,2.12,1.985c0.463,0.868,0.817,1.921,0.817,3.111v0.272h-9.488c0.054,0.783,0.275,1.43,0.703,1.994
		c0.427,0.56,0.862,0.947,1.51,1.244c0.538,0.268,1.186,0.417,1.696,0.417c0.552,0,1.072-0.135,1.551-0.346
		c0.563-0.195,1.033-0.451,1.396-0.797c0.393-0.347,0.711-0.744,0.808-1.192h1.46c-0.287,0.823-0.732,1.479-1.232,2.015
		c-0.625,0.574-1.216,0.997-1.894,1.271c-0.679,0.229-1.409,0.405-2.165,0.405c-0.891-0.049-1.803-0.298-2.631-0.752
		c-0.756-0.478-1.455-1.13-1.904-1.94C450.945,323.768,450.672,322.831,450.672,321.771z M460.155,321.077
		c-0.034-0.636-0.363-1.173-0.743-1.705c-0.381-0.548-0.789-0.931-1.406-1.254c-0.531-0.284-1.223-0.433-1.864-0.433
		c-0.935,0-1.69,0.187-2.235,0.628c-0.574,0.44-1.003,0.886-1.33,1.444c-0.296,0.529-0.393,0.951-0.443,1.319H460.155
		L460.155,321.077z"
      />
      <path d="M466.182,327.095l-4.412-10.546h1.538l3.63,8.697l3.674-8.697h1.543l-4.461,10.546H466.182z" />
      <path
        d="M472.34,321.771c0.028-0.867,0.247-1.623,0.532-2.267c0.285-0.693,0.71-1.254,1.231-1.741
		c0.492-0.464,1.135-0.832,1.732-1.063c0.596-0.247,1.291-0.372,1.977-0.386c0.826,0,1.723,0.229,2.59,0.62
		c0.868,0.455,1.533,1.12,2.016,1.985c0.567,0.868,0.799,1.921,0.799,3.111v0.272h-9.477c0.07,0.783,0.363,1.43,0.756,1.994
		c0.363,0.56,0.907,0.947,1.512,1.244c0.595,0.268,1.119,0.417,1.753,0.417c0.515,0,0.986-0.135,1.552-0.346
		c0.475-0.195,0.924-0.451,1.329-0.797c0.404-0.347,0.659-0.744,0.874-1.192h1.44c-0.268,0.823-0.713,1.479-1.294,2.015
		c-0.543,0.574-1.135,0.997-1.875,1.271c-0.739,0.229-1.427,0.405-2.125,0.405c-0.898-0.049-1.779-0.298-2.612-0.752
		c-0.831-0.478-1.451-1.13-1.979-1.94C472.592,323.768,472.34,322.831,472.34,321.771z M481.878,321.077
		c-0.157-0.636-0.364-1.173-0.744-1.705c-0.38-0.548-0.891-0.931-1.407-1.254c-0.64-0.286-1.222-0.433-1.864-0.433
		c-0.945,0-1.691,0.187-2.247,0.628c-0.655,0.44-1.112,0.886-1.362,1.444c-0.251,0.529-0.466,0.951-0.513,1.319H481.878
		L481.878,321.077z"
      />
      <path
        d="M485.078,327.095v-10.546h1.357v1.592c0.338-0.563,0.841-1.028,1.39-1.318c0.64-0.29,1.272-0.474,1.923-0.474
		c0.621,0,1.169,0.117,1.74,0.317c0.515,0.238,1.045,0.524,1.498,0.94c0.406,0.414,0.768,0.882,1.006,1.519
		c0.218,0.602,0.388,1.251,0.388,1.998v5.972H493v-5.972c0-0.714-0.164-1.33-0.453-1.845c-0.305-0.479-0.705-0.865-1.251-1.162
		c-0.546-0.254-1.012-0.387-1.646-0.387c-0.495,0-1.063,0.131-1.548,0.365c-0.429,0.282-0.824,0.645-1.148,1.104
		c-0.343,0.51-0.418,1.118-0.418,1.775v6.12H485.078z"
      />
      <path d="M497.452,327.095v-9.234h-2.189v-1.312h2.189v-3.676h1.389v3.676h2.471v1.312h-2.471v9.234H497.452z" />
      <path d="M502.512,315.321v-2.448h1.465v2.448H502.512z M502.512,327.095v-10.546h1.465v10.546H502.512z" />
      <path
        d="M505.848,321.64c0-0.685,0.129-1.322,0.398-2.003c0.35-0.63,0.748-1.195,1.17-1.686c0.547-0.488,1.103-0.884,1.855-1.183
		c0.703-0.256,1.451-0.42,2.266-0.42c0.758,0,1.514,0.195,2.276,0.646c0.816,0.462,1.505,1.061,2.074,1.816
		c0.541,0.756,0.893,1.596,0.942,2.524c0,0.062,0,0.129,0,0.192c0,0.111,0,0.163,0,0.217c0,0.78-0.127,1.497-0.403,2.162
		c-0.351,0.678-0.754,1.299-1.224,1.771c-0.564,0.51-1.104,0.913-1.798,1.205c-0.703,0.249-1.385,0.435-2.112,0.435
		c-0.911,0-1.707-0.213-2.58-0.678c-0.875-0.464-1.542-1.082-2.11-1.976C506.125,323.812,505.848,322.796,505.848,321.64z
		 M507.233,321.719c0,0.865,0.126,1.595,0.526,2.19c0.354,0.625,0.883,1.144,1.512,1.495c0.675,0.343,1.338,0.558,2.078,0.596
		c0.576,0,1.275-0.189,1.852-0.529c0.605-0.321,1.132-0.808,1.548-1.46c0.415-0.664,0.573-1.42,0.573-2.292
		c0-0.51-0.031-1.01-0.312-1.479c-0.157-0.48-0.448-0.921-0.85-1.301c-0.354-0.38-0.772-0.688-1.3-0.884
		c-0.526-0.229-0.97-0.368-1.512-0.368c-0.69,0.014-1.328,0.211-2.037,0.563c-0.584,0.363-1.136,0.827-1.528,1.427
		C507.393,320.313,507.25,320.968,507.233,321.719z"
      />
      <path
        d="M518.694,327.095v-10.546h1.326v1.592c0.337-0.563,0.841-1.028,1.39-1.318c0.673-0.29,1.271-0.474,1.924-0.474
		c0.651,0,1.169,0.117,1.773,0.317c0.481,0.238,1.011,0.524,1.463,0.94c0.438,0.414,0.789,0.882,1.006,1.519
		c0.219,0.602,0.39,1.251,0.39,1.998v5.972h-1.339v-5.972c0-0.714-0.206-1.33-0.493-1.845c-0.289-0.479-0.707-0.865-1.251-1.162
		c-0.494-0.254-1.015-0.387-1.646-0.387c-0.498,0-1.065,0.131-1.518,0.365c-0.462,0.282-0.857,0.645-1.13,1.104
		c-0.382,0.51-0.47,1.118-0.47,1.775v6.12H518.694z"
      />
    </g>
    <g>
      <path
        d="M142.921,339.253c0.103-0.824,0.391-1.567,0.867-2.199c0.476-0.651,1.057-1.144,1.77-1.472
		c0.709-0.333,1.46-0.52,2.247-0.52c0.421,0,0.835,0.058,1.237,0.168c0.422,0.115,0.805,0.24,1.167,0.472
		c0.384,0.188,0.718,0.476,1.046,0.781c0.313,0.307,0.591,0.721,0.832,1.154h-1.03c-0.274-0.39-0.592-0.675-0.934-0.899
		c-0.363-0.267-0.724-0.444-1.123-0.547c-0.38-0.129-0.76-0.191-1.161-0.191c-0.5,0-1.001,0.104-1.482,0.292
		c-0.484,0.175-0.909,0.457-1.299,0.805c-0.37,0.34-0.673,0.753-0.89,1.248c-0.218,0.491-0.317,1.034-0.317,1.631
		c0.001,0.729,0.198,1.425,0.563,1.989c0.375,0.614,0.857,1.081,1.478,1.402c0.601,0.363,1.24,0.509,1.933,0.521
		c0.4-0.012,0.791-0.087,1.178-0.182c0.413-0.136,0.777-0.343,1.145-0.575c0.346-0.277,0.67-0.582,0.951-0.961h1.033
		c-0.331,0.596-0.745,1.086-1.203,1.469c-0.456,0.381-0.95,0.652-1.461,0.858c-0.53,0.204-1.082,0.264-1.642,0.264
		c-0.629,0-1.225-0.081-1.83-0.334c-0.579-0.209-1.117-0.57-1.587-1c-0.471-0.43-0.833-0.954-1.107-1.528
		c-0.274-0.616-0.4-1.299-0.4-1.994C142.9,339.668,142.913,339.482,142.921,339.253z"
      />
      <path
        d="M153.575,344.639v-9.411h2.556c0.875,0,1.664,0.132,2.336,0.366c0.673,0.231,1.247,0.607,1.702,1.048
		c0.451,0.438,0.78,0.95,1.003,1.526c0.226,0.555,0.345,1.185,0.345,1.86c-0.007,0.677-0.136,1.331-0.41,1.88
		c-0.273,0.575-0.652,1.068-1.119,1.471c-0.466,0.4-0.975,0.735-1.568,0.916c-0.57,0.229-1.184,0.344-1.795,0.344H153.575
		L153.575,344.639z M154.516,336.056v7.721h1.452c1.296,0,2.384-0.333,3.219-0.932c0.836-0.614,1.297-1.554,1.39-2.814
		c0-0.528-0.099-1.031-0.274-1.506c-0.174-0.476-0.45-0.891-0.809-1.293c-0.38-0.362-0.846-0.636-1.396-0.868
		c-0.573-0.19-1.223-0.308-1.967-0.308H154.516L154.516,336.056z"
      />
      <path
        d="M162.662,339.253c0.103-0.824,0.372-1.567,0.845-2.199c0.477-0.651,1.08-1.144,1.792-1.472
		c0.71-0.333,1.46-0.52,2.226-0.52c0.421,0,0.857,0.058,1.259,0.168c0.401,0.115,0.798,0.24,1.168,0.472
		c0.362,0.188,0.717,0.476,1.034,0.781c0.317,0.307,0.605,0.721,0.844,1.154h-1.052c-0.261-0.39-0.571-0.675-0.934-0.899
		c-0.341-0.267-0.724-0.444-1.102-0.547c-0.377-0.129-0.782-0.191-1.159-0.191c-0.523,0-1.023,0.104-1.506,0.292
		c-0.477,0.175-0.909,0.457-1.277,0.805c-0.37,0.34-0.673,0.753-0.889,1.248c-0.219,0.491-0.338,1.034-0.338,1.631
		c0.008,0.729,0.196,1.425,0.583,1.989c0.365,0.614,0.859,1.081,1.457,1.402c0.623,0.363,1.262,0.509,1.94,0.521
		c0.393-0.012,0.807-0.087,1.195-0.182c0.388-0.136,0.776-0.343,1.12-0.575c0.368-0.277,0.669-0.582,0.953-0.961h1.052
		c-0.352,0.596-0.745,1.086-1.202,1.469c-0.456,0.381-0.95,0.652-1.481,0.858c-0.532,0.204-1.063,0.264-1.637,0.264
		c-0.614,0-1.234-0.081-1.815-0.334c-0.599-0.209-1.115-0.57-1.587-1c-0.471-0.43-0.854-0.954-1.118-1.528
		c-0.263-0.617-0.411-1.299-0.411-1.994C162.62,339.668,162.632,339.482,162.662,339.253z"
      />
      <path
        d="M176.132,338.258c0.005-0.684,0.163-1.284,0.452-1.763c0.266-0.48,0.651-0.822,1.111-1.077
		c0.461-0.212,0.961-0.344,1.485-0.356c0.383,0,0.733,0.079,1.091,0.238c0.339,0.114,0.652,0.334,0.926,0.571
		c0.273,0.282,0.485,0.569,0.635,0.908c0.149,0.384,0.236,0.748,0.236,1.144c0,0.942-0.44,1.822-1.314,2.643l-3.375,3.209h4.688
		v0.864h-5.976v-0.86l4.192-4.08c0.574-0.596,0.871-1.159,0.871-1.773c0-0.341-0.096-0.688-0.264-0.965
		c-0.169-0.274-0.398-0.527-0.692-0.676c-0.292-0.188-0.582-0.292-0.913-0.311c-0.604,0-1.13,0.204-1.559,0.563
		c-0.431,0.363-0.659,0.951-0.659,1.721H176.132L176.132,338.258z"
      />
      <path
        d="M187.168,335.228h1.171v6.81h0.853v0.829h-0.853v1.771h-0.941v-1.771h-4.724v-0.829L187.168,335.228z M187.397,336.501
		l-3.667,5.536h3.667V336.501z"
      />
      <path d="M189.634,346.229l3.667-11.001h0.857l-3.723,11.001H189.634z" />
      <path d="M195.92,344.639l3.87-8.583h-4.45v-0.827h5.444v0.827l-3.853,8.583H195.92z" />
      <path d="M201.932,338.162h0.939v1.563h-0.939V338.162z M201.932,343.003h0.939v1.635h-0.939V343.003z" />
      <path
        d="M208.723,341.979c0.021,0.403,0.135,0.778,0.318,1.047c0.184,0.276,0.427,0.492,0.729,0.647
		c0.282,0.109,0.606,0.188,0.944,0.188c0.291,0,0.593-0.083,0.881-0.225c0.289-0.179,0.518-0.377,0.684-0.681
		c0.168-0.259,0.261-0.585,0.261-0.89c0-0.388-0.106-0.723-0.301-1.054c-0.18-0.276-0.541-0.52-1.035-0.686
		c-0.515-0.193-1.02-0.335-1.513-0.517c-0.493-0.18-0.89-0.408-1.21-0.729c-0.313-0.359-0.472-0.848-0.472-1.459
		c0-0.03,0.002-0.071,0.008-0.125c0.006-0.052,0.008-0.103,0.008-0.154c0.029-0.445,0.156-0.839,0.402-1.175
		c0.248-0.354,0.576-0.634,0.962-0.808c0.407-0.194,0.813-0.298,1.242-0.298c0.512,0,0.963,0.135,1.354,0.36
		c0.396,0.271,0.707,0.565,0.919,0.934c0.215,0.392,0.334,0.767,0.334,1.112v0.138h-0.942c-0.076-0.528-0.258-0.908-0.562-1.188
		c-0.29-0.234-0.658-0.399-1.079-0.444c-0.301,0-0.577,0.068-0.831,0.21c-0.25,0.108-0.454,0.286-0.603,0.528
		c-0.15,0.196-0.242,0.454-0.258,0.688c0,0.597,0.146,1.003,0.459,1.217c0.315,0.237,0.805,0.466,1.474,0.636
		c0.667,0.213,1.215,0.431,1.625,0.743c0.409,0.267,0.714,0.748,0.888,1.428c0.039,0.247,0.059,0.465,0.059,0.584
		c0,0.349-0.067,0.656-0.179,0.971c-0.131,0.321-0.303,0.641-0.538,0.89c-0.232,0.294-0.534,0.488-0.881,0.665
		c-0.368,0.178-0.766,0.228-1.213,0.228c-0.437,0-0.847-0.05-1.213-0.19c-0.356-0.158-0.679-0.339-0.945-0.616
		c-0.265-0.236-0.464-0.561-0.591-0.885c-0.15-0.325-0.206-0.706-0.206-1.091h1.021V341.979z"
      />
      <path
        d="M214.404,341.189c0.007-0.562,0.105-1.074,0.314-1.534c0.229-0.459,0.494-0.847,0.86-1.154
		c0.345-0.333,0.76-0.578,1.199-0.768c0.443-0.146,0.891-0.237,1.348-0.237c1.271,0.033,2.191,0.547,2.727,1.538v-1.349h0.913v6.953
		h-0.913V343.3c-0.264,0.477-0.633,0.845-1.13,1.084c-0.497,0.279-1.044,0.376-1.682,0.376c-0.477-0.013-0.931-0.068-1.365-0.257
		c-0.434-0.188-0.817-0.405-1.172-0.725c-0.332-0.326-0.606-0.695-0.8-1.134C214.493,342.205,214.404,341.72,214.404,341.189z
		 M215.371,340.975c0,0.562,0.116,1.07,0.369,1.505c0.253,0.438,0.594,0.809,1,1.021c0.406,0.257,0.839,0.351,1.3,0.361
		c0.537,0,1.018-0.099,1.442-0.377c0.426-0.234,0.77-0.6,1.015-1.012c0.244-0.408,0.355-0.835,0.355-1.282
		c0-0.495-0.107-0.945-0.343-1.357c-0.236-0.408-0.568-0.733-0.976-1.002c-0.409-0.229-0.86-0.378-1.354-0.404
		c-0.542,0-1.027,0.145-1.453,0.392c-0.425,0.281-0.771,0.616-1.009,1.009C215.479,340.223,215.371,340.597,215.371,340.975z"
      />
      <path d="M225.622,344.639l-2.935-6.953h1.021l2.414,5.733l2.4-5.733h1.021l-2.956,6.953H225.622z" />
      <path d="M230.473,336.816v-1.588h0.939v1.588H230.473z M230.473,344.639v-6.953h0.939v6.953H230.473z" />
      <path
        d="M233.107,344.639v-6.953h0.888v1.015c0.229-0.376,0.549-0.655,0.939-0.882c0.39-0.181,0.832-0.295,1.277-0.295
		c0.385,0,0.754,0.075,1.131,0.188c0.354,0.151,0.688,0.377,0.979,0.628c0.272,0.28,0.5,0.617,0.688,1.01
		c0.163,0.398,0.257,0.843,0.257,1.341v3.949h-0.921v-3.949c0-0.478-0.104-0.883-0.309-1.209c-0.204-0.374-0.478-0.61-0.819-0.793
		c-0.324-0.144-0.691-0.234-1.06-0.234c-0.381,0-0.718,0.087-1.045,0.225c-0.308,0.175-0.57,0.424-0.762,0.753
		c-0.214,0.309-0.303,0.687-0.303,1.137v4.073h-0.939V344.639z"
      />
      <path
        d="M240.58,341.122c0-0.709,0.164-1.36,0.514-1.916c0.328-0.555,0.762-0.975,1.298-1.252c0.537-0.278,1.085-0.445,1.627-0.458
		c0.42,0,0.837,0.068,1.211,0.189c0.375,0.112,0.722,0.31,1.02,0.527c0.278,0.259,0.516,0.514,0.661,0.85v-1.398h0.886v6.361
		c0,0.539-0.095,1.014-0.281,1.42c-0.182,0.401-0.436,0.733-0.75,1.029c-0.322,0.254-0.698,0.479-1.108,0.581
		c-0.407,0.152-0.829,0.226-1.271,0.226c-0.412,0-0.794-0.059-1.167-0.179c-0.394-0.071-0.732-0.249-1.062-0.479
		c-0.307-0.184-0.585-0.472-0.816-0.776c-0.232-0.3-0.415-0.678-0.504-1.087h0.913c0.228,0.54,0.568,0.943,1.037,1.247
		c0.472,0.262,0.986,0.416,1.544,0.416c0.456,0,0.874-0.106,1.264-0.314c0.407-0.16,0.708-0.457,0.948-0.824
		c0.24-0.356,0.37-0.785,0.37-1.287v-0.742c-0.557,0.989-1.457,1.504-2.72,1.504c-0.43,0-0.858-0.047-1.284-0.219
		c-0.424-0.171-0.819-0.387-1.167-0.729c-0.358-0.303-0.622-0.678-0.827-1.139C240.686,342.215,240.586,341.698,240.58,341.122z
		 M241.52,340.971c0,0.552,0.108,1.066,0.35,1.501c0.24,0.441,0.568,0.81,0.965,1.024c0.418,0.261,0.863,0.353,1.352,0.363
		c0.375,0,0.773-0.081,1.193-0.282c0.446-0.217,0.792-0.516,1.095-0.941c0.292-0.428,0.438-0.968,0.438-1.625
		c0-0.316-0.081-0.619-0.216-0.963c-0.158-0.301-0.354-0.565-0.612-0.835c-0.252-0.226-0.545-0.441-0.898-0.592
		c-0.334-0.12-0.708-0.196-1.081-0.196c-0.66,0.019-1.189,0.194-1.566,0.527c-0.397,0.314-0.663,0.665-0.8,1.084
		C241.599,340.428,241.52,340.726,241.52,340.971z"
      />
      <path d="M253.779,343.778l3.9-0.002v0.862h-4.839v-9.411h0.939V343.778L253.779,343.778z" />
      <path d="M258.617,336.816v-1.588h0.939v1.588H258.617z M258.617,344.639v-6.953h0.939v6.953H258.617z" />
      <path d="M263.379,344.639l-2.913-6.953h1.018l2.394,5.733l2.421-5.733h1.025l-2.98,6.953H263.379z" />
      <path
        d="M267.591,341.093c0.017-0.564,0.136-1.055,0.334-1.512c0.203-0.445,0.481-0.813,0.832-1.111
		c0.338-0.304,0.713-0.559,1.149-0.74c0.42-0.137,0.847-0.228,1.289-0.233c0.585,0,1.168,0.158,1.733,0.431
		c0.542,0.271,0.998,0.7,1.364,1.291c0.343,0.587,0.539,1.275,0.539,2.087v0.146h-6.299c0.043,0.521,0.216,0.956,0.474,1.356
		c0.28,0.353,0.622,0.605,1.007,0.802c0.385,0.169,0.784,0.251,1.155,0.251c0.347,0,0.675-0.075,1.028-0.178
		c0.333-0.15,0.643-0.347,0.908-0.56c0.266-0.259,0.451-0.5,0.551-0.769h0.965c-0.188,0.497-0.481,0.949-0.843,1.35
		c-0.366,0.362-0.802,0.617-1.263,0.808c-0.463,0.193-0.93,0.247-1.401,0.247c-0.611-0.026-1.2-0.162-1.722-0.498
		c-0.548-0.29-0.975-0.71-1.312-1.271C267.769,342.432,267.591,341.831,267.591,341.093z M273.902,340.622
		c-0.072-0.392-0.249-0.756-0.484-1.084c-0.252-0.372-0.577-0.625-0.952-0.849c-0.396-0.181-0.803-0.29-1.244-0.29
		c-0.595,0-1.085,0.153-1.496,0.417c-0.388,0.285-0.682,0.612-0.866,0.948c-0.18,0.337-0.298,0.652-0.329,0.859h5.372V340.622z"
      />
      <path
        d="M275.851,339.698c-0.009-0.077-0.014-0.143-0.014-0.263c0-0.399,0.107-0.775,0.307-1.047
		c0.197-0.277,0.449-0.511,0.761-0.671c0.286-0.117,0.587-0.194,0.882-0.194c0.231,0,0.465,0.042,0.697,0.131
		c0.233,0.041,0.442,0.167,0.63,0.338c0.186,0.17,0.358,0.341,0.466,0.602c0.13,0.215,0.18,0.506,0.193,0.797h-0.953
		c-0.02-0.319-0.113-0.586-0.309-0.758c-0.193-0.124-0.435-0.208-0.722-0.208c-0.251,0-0.485,0.09-0.7,0.226
		c-0.212,0.176-0.337,0.437-0.355,0.74c0.003,0.008,0.017,0.068,0.041,0.165c0.024,0.067,0.039,0.141,0.045,0.17
		c0.072,0.251,0.287,0.414,0.65,0.585c0.385,0.169,0.752,0.285,1.122,0.443c0.391,0.151,0.695,0.337,0.958,0.639
		c0.262,0.257,0.407,0.642,0.407,1.156c0,0.093-0.013,0.234-0.033,0.37c-0.056,0.427-0.193,0.812-0.419,1.07
		c-0.241,0.26-0.506,0.479-0.825,0.616c-0.321,0.109-0.613,0.155-0.922,0.155c-0.415,0-0.804-0.062-1.122-0.272
		c-0.341-0.211-0.601-0.455-0.779-0.774c-0.18-0.363-0.278-0.725-0.278-1.122l0.943-0.03c0.013,0.393,0.161,0.738,0.404,0.972
		c0.255,0.245,0.552,0.372,0.892,0.386c0.316-0.031,0.604-0.143,0.835-0.374c0.233-0.243,0.361-0.503,0.361-0.816
		c0-0.311-0.102-0.551-0.284-0.805c-0.183-0.212-0.477-0.389-0.869-0.496c-0.525-0.111-0.975-0.331-1.365-0.568
		C276.129,340.577,275.914,340.208,275.851,339.698z"
      />
      <path d="M282.233,343.003h0.942l-1.14,2.463h-0.916L282.233,343.003z" />
      <path
        d="M287.69,344.639v-9.411h2.479c0.743,0,1.373,0.087,1.916,0.213c0.529,0.173,0.967,0.428,1.281,0.852
		c0.333,0.39,0.515,0.93,0.564,1.579c0.009,0.065,0.013,0.157,0.013,0.279c0,0.479-0.15,0.951-0.409,1.393
		c-0.28,0.408-0.679,0.71-1.17,0.95c-0.481,0.212-1.048,0.337-1.67,0.337h-2.063v3.808L287.69,344.639L287.69,344.639z
		 M288.632,336.056v3.948h1.95c0.837,0,1.438-0.209,1.817-0.58c0.4-0.375,0.591-0.893,0.591-1.553
		c-0.041-0.644-0.298-1.122-0.771-1.412c-0.475-0.254-1.118-0.403-1.943-0.403H288.632L288.632,336.056z"
      />
      <path
        d="M294.998,344.639v-6.975h0.889v1.01c0.385-0.782,1.061-1.178,2.033-1.178v0.956c-0.219,0-0.446,0.051-0.68,0.149
		c-0.237,0.063-0.453,0.203-0.667,0.394c-0.197,0.141-0.347,0.359-0.467,0.57c-0.116,0.25-0.181,0.478-0.181,0.77v4.303
		L294.998,344.639L294.998,344.639z"
      />
      <path
        d="M298.225,341.011c0.001-0.455,0.096-0.896,0.276-1.313c0.193-0.422,0.445-0.786,0.79-1.104
		c0.337-0.357,0.731-0.597,1.19-0.804c0.456-0.161,0.955-0.268,1.486-0.268c0.501,0,1.027,0.16,1.566,0.436
		c0.523,0.275,0.986,0.667,1.342,1.175c0.367,0.511,0.576,1.079,0.606,1.697c0,0.033,0.003,0.082,0.008,0.143
		c0.004,0.03,0.006,0.077,0.006,0.105c0,0.519-0.084,1-0.272,1.446c-0.21,0.443-0.46,0.831-0.814,1.201
		c-0.35,0.323-0.737,0.567-1.177,0.772c-0.44,0.203-0.904,0.262-1.391,0.262c-0.588,0-1.161-0.115-1.728-0.437
		c-0.546-0.28-1.003-0.708-1.369-1.285C298.403,342.456,298.225,341.814,298.225,341.011z M299.142,341.064
		c0,0.564,0.114,1.053,0.364,1.466c0.248,0.411,0.589,0.768,1.003,0.976c0.412,0.255,0.867,0.354,1.361,0.382
		c0.406,0,0.813-0.109,1.239-0.318c0.405-0.241,0.735-0.557,1.006-0.979c0.27-0.427,0.409-0.937,0.409-1.526
		c0-0.323-0.067-0.642-0.217-0.99c-0.137-0.306-0.326-0.573-0.576-0.849c-0.25-0.23-0.541-0.447-0.854-0.604
		c-0.332-0.119-0.675-0.196-1.005-0.196c-0.467,0.012-0.91,0.141-1.321,0.347c-0.431,0.252-0.755,0.552-1.017,0.95
		C299.275,340.115,299.151,340.563,299.142,341.064z"
      />
      <path d="M307.281,344.639v-6.107h-1.469v-0.846h1.469v-2.458h0.938v2.458h1.611v0.846h-1.611v6.107H307.281z" />
      <path
        d="M310.171,341.093c0.017-0.564,0.135-1.055,0.336-1.512c0.201-0.445,0.479-0.813,0.819-1.111
		c0.35-0.304,0.725-0.559,1.152-0.74c0.427-0.137,0.854-0.228,1.297-0.233c0.583,0,1.168,0.158,1.733,0.431
		c0.544,0.271,0.998,0.7,1.365,1.291c0.344,0.587,0.531,1.275,0.531,2.087v0.146h-6.293c0.047,0.521,0.212,0.956,0.477,1.356
		c0.278,0.353,0.623,0.605,1.007,0.802c0.385,0.169,0.783,0.251,1.155,0.251c0.344,0,0.675-0.075,1.028-0.178
		c0.331-0.15,0.642-0.347,0.909-0.56c0.266-0.259,0.448-0.5,0.547-0.769h0.967c-0.189,0.497-0.479,0.949-0.844,1.35
		c-0.365,0.362-0.8,0.617-1.261,0.808c-0.46,0.193-0.928,0.247-1.401,0.247c-0.609-0.026-1.198-0.162-1.723-0.498
		c-0.544-0.29-0.975-0.71-1.308-1.271C310.347,342.432,310.171,341.831,310.171,341.093z M316.475,340.622
		c-0.065-0.392-0.244-0.756-0.477-1.084c-0.253-0.372-0.574-0.625-0.95-0.849c-0.396-0.181-0.805-0.29-1.246-0.29
		c-0.606,0-1.084,0.153-1.494,0.417c-0.388,0.285-0.686,0.612-0.868,0.948c-0.184,0.337-0.298,0.652-0.331,0.859h5.365V340.622z"
      />
      <path
        d="M318.399,341.122c0.008-0.558,0.122-1.058,0.321-1.505c0.203-0.44,0.481-0.814,0.814-1.11
		c0.353-0.338,0.727-0.563,1.148-0.746c0.434-0.14,0.86-0.243,1.294-0.265c0.792,0,1.511,0.189,2.184,0.569
		c0.655,0.367,1.136,0.941,1.426,1.688h-1.061c-0.255-0.431-0.586-0.745-1.039-0.996c-0.432-0.21-0.936-0.333-1.51-0.333
		c-0.531,0.037-0.992,0.192-1.38,0.43c-0.389,0.247-0.703,0.597-0.91,0.99c-0.213,0.398-0.335,0.822-0.346,1.278
		c0,0.498,0.129,0.953,0.367,1.358c0.237,0.41,0.551,0.771,0.973,0.996c0.433,0.27,0.896,0.369,1.399,0.385
		c0.529,0,1.018-0.09,1.458-0.354c0.436-0.215,0.783-0.554,1.023-0.915h1.027c-0.743,1.462-1.896,2.169-3.465,2.169
		c-0.469,0-0.924-0.053-1.369-0.242c-0.443-0.189-0.839-0.42-1.209-0.743c-0.348-0.35-0.635-0.726-0.842-1.167
		c-0.207-0.441-0.324-0.917-0.331-1.431c0.011-0.012,0.019-0.021,0.022-0.031C318.397,341.134,318.399,341.128,318.399,341.122z"
      />
      <path d="M327.499,344.639v-6.107h-1.456v-0.846h1.456v-2.458h0.942v2.458h1.61v0.846h-1.61v6.107H327.499z" />
      <path d="M331.004,336.816v-1.588h0.94v1.588H331.004z M331.004,344.639v-6.953h0.94v6.953H331.004z" />
      <path
        d="M333.641,344.639v-6.953h0.886v1.015c0.229-0.376,0.549-0.655,0.94-0.882c0.399-0.181,0.833-0.295,1.278-0.295
		c0.385,0,0.772,0.075,1.13,0.188c0.356,0.151,0.688,0.377,0.982,0.628c0.285,0.28,0.522,0.617,0.685,1.01
		c0.166,0.398,0.26,0.843,0.26,1.341v3.949h-0.914v-3.949c0-0.478-0.112-0.883-0.318-1.209c-0.201-0.374-0.473-0.61-0.818-0.793
		c-0.319-0.144-0.688-0.234-1.058-0.234c-0.364,0-0.719,0.087-1.042,0.225c-0.312,0.175-0.573,0.424-0.768,0.753
		c-0.194,0.309-0.302,0.687-0.302,1.137v4.073h-0.94V344.639z"
      />
      <path
        d="M341.111,341.122c0-0.709,0.165-1.36,0.515-1.916c0.33-0.555,0.782-0.975,1.297-1.252c0.536-0.278,1.083-0.445,1.627-0.458
		c0.44,0,0.836,0.068,1.211,0.189c0.392,0.112,0.72,0.31,1.019,0.527c0.298,0.259,0.519,0.514,0.663,0.85v-1.398h0.886v6.361
		c0,0.539-0.082,1.014-0.281,1.42c-0.182,0.401-0.438,0.733-0.742,1.029c-0.329,0.254-0.686,0.479-1.117,0.581
		c-0.407,0.152-0.831,0.226-1.27,0.226c-0.394,0-0.798-0.059-1.168-0.179c-0.393-0.071-0.733-0.249-1.063-0.479
		c-0.308-0.184-0.586-0.472-0.819-0.776c-0.231-0.3-0.394-0.678-0.501-1.087h0.912c0.229,0.54,0.588,0.943,1.058,1.247
		c0.472,0.262,0.964,0.416,1.521,0.416c0.456,0,0.875-0.106,1.284-0.314c0.387-0.16,0.714-0.457,0.947-0.824
		c0.244-0.356,0.353-0.785,0.353-1.287v-0.742c-0.558,0.989-1.458,1.504-2.7,1.504c-0.449,0-0.879-0.047-1.303-0.219
		c-0.425-0.171-0.808-0.387-1.168-0.729c-0.34-0.303-0.624-0.678-0.827-1.139C341.236,342.215,341.119,341.698,341.111,341.122z
		 M342.051,340.971c0,0.552,0.13,1.066,0.364,1.501c0.246,0.441,0.553,0.81,0.952,1.024c0.42,0.261,0.861,0.353,1.35,0.363
		c0.375,0,0.773-0.081,1.217-0.282c0.421-0.217,0.769-0.516,1.071-0.941c0.298-0.428,0.439-0.968,0.439-1.625
		c0-0.316-0.08-0.619-0.215-0.963c-0.142-0.301-0.351-0.565-0.604-0.835c-0.248-0.226-0.556-0.441-0.909-0.592
		c-0.335-0.12-0.71-0.196-1.082-0.196c-0.661,0.019-1.189,0.194-1.566,0.527c-0.396,0.314-0.654,0.665-0.8,1.084
		C342.131,340.428,342.051,340.726,342.051,340.971z"
      />
      <path
        d="M353.372,344.639v-9.411h2.456c0.745,0,1.376,0.087,1.917,0.213c0.542,0.173,0.97,0.428,1.293,0.852
		c0.323,0.39,0.505,0.93,0.554,1.579c0.01,0.065,0.014,0.157,0.014,0.279c0,0.479-0.128,0.951-0.411,1.393
		c-0.279,0.408-0.657,0.71-1.153,0.95c-0.496,0.212-1.066,0.337-1.686,0.337h-2.042v3.808L353.372,344.639L353.372,344.639z
		 M354.313,336.056v3.948h1.931c0.84,0,1.438-0.209,1.839-0.58c0.379-0.375,0.568-0.893,0.568-1.553
		c-0.038-0.644-0.296-1.122-0.77-1.412c-0.478-0.254-1.117-0.403-1.927-0.403H354.313L354.313,336.056z"
      />
      <path
        d="M360.221,341.093c0.017-0.564,0.136-1.055,0.338-1.512c0.199-0.445,0.479-0.813,0.818-1.111
		c0.351-0.304,0.727-0.559,1.151-0.74c0.431-0.137,0.858-0.228,1.299-0.233c0.584,0,1.168,0.158,1.736,0.431
		c0.542,0.271,0.997,0.7,1.361,1.291c0.344,0.587,0.532,1.275,0.532,2.087v0.146h-6.291c0.045,0.521,0.209,0.956,0.474,1.356
		c0.28,0.353,0.622,0.605,1.007,0.802c0.384,0.169,0.783,0.251,1.153,0.251c0.349,0,0.677-0.075,1.03-0.178
		c0.333-0.15,0.641-0.347,0.908-0.56c0.267-0.259,0.451-0.5,0.548-0.769h0.968c-0.192,0.497-0.48,0.949-0.845,1.35
		c-0.364,0.362-0.8,0.617-1.261,0.808c-0.462,0.193-0.929,0.247-1.399,0.247c-0.613-0.026-1.202-0.162-1.726-0.498
		c-0.543-0.29-0.971-0.71-1.309-1.271C360.401,342.432,360.221,341.831,360.221,341.093z M366.526,340.622
		c-0.064-0.392-0.244-0.756-0.475-1.084c-0.252-0.372-0.576-0.625-0.952-0.849c-0.396-0.181-0.804-0.29-1.246-0.29
		c-0.604,0-1.083,0.153-1.494,0.417c-0.388,0.285-0.683,0.612-0.867,0.948c-0.181,0.337-0.299,0.652-0.329,0.859h5.363V340.622z"
      />
      <path
        d="M368.444,341.011c0.008-0.455,0.113-0.896,0.294-1.313c0.183-0.422,0.445-0.786,0.788-1.104
		c0.321-0.357,0.733-0.597,1.189-0.804c0.457-0.161,0.952-0.268,1.478-0.268c0.514,0,1.039,0.16,1.562,0.436
		c0.535,0.275,0.98,0.667,1.357,1.175c0.354,0.511,0.553,1.079,0.604,1.697c0,0.033,0.003,0.082,0.008,0.143
		c0.004,0.03,0.007,0.077,0.007,0.105c0,0.519-0.104,1-0.295,1.446c-0.188,0.444-0.458,0.831-0.791,1.201
		c-0.353,0.323-0.74,0.567-1.179,0.772c-0.438,0.203-0.904,0.262-1.391,0.262c-0.598,0-1.161-0.115-1.73-0.437
		c-0.549-0.28-1.017-0.708-1.366-1.285C368.618,342.456,368.444,341.814,368.444,341.011z M369.358,341.064
		c0,0.564,0.135,1.053,0.383,1.466c0.25,0.411,0.568,0.768,0.992,0.976c0.424,0.255,0.881,0.354,1.372,0.382
		c0.408,0,0.814-0.109,1.218-0.318c0.428-0.241,0.756-0.557,1.028-0.979c0.271-0.427,0.399-0.937,0.399-1.526
		c0-0.323-0.059-0.642-0.211-0.99c-0.13-0.306-0.32-0.573-0.571-0.849c-0.25-0.23-0.541-0.447-0.874-0.604
		c-0.311-0.119-0.656-0.196-0.988-0.196c-0.465,0.012-0.907,0.141-1.339,0.347c-0.41,0.252-0.752,0.552-0.995,0.95
		C369.512,340.115,369.367,340.563,369.358,341.064z"
      />
      <path
        d="M377.078,347.04v-9.378h0.897v1.231c0.229-0.288,0.459-0.567,0.735-0.743c0.281-0.226,0.587-0.396,0.912-0.467
		c0.325-0.113,0.672-0.176,1.021-0.188c0.471,0,0.933,0.092,1.383,0.232c0.45,0.187,0.865,0.438,1.214,0.739
		c0.351,0.294,0.647,0.652,0.855,1.09c0.217,0.467,0.33,0.934,0.343,1.417c0,0.544-0.089,1.056-0.279,1.513
		c-0.189,0.459-0.453,0.854-0.797,1.225c-0.349,0.326-0.737,0.569-1.186,0.771c-0.457,0.201-0.931,0.265-1.426,0.276
		c-0.618,0-1.151-0.098-1.641-0.384c-0.489-0.242-0.856-0.601-1.078-1.075v3.74H377.078L377.078,347.04z M377.965,341.189
		c0.001,0.407,0.108,0.813,0.302,1.208c0.194,0.443,0.51,0.771,0.928,1.029c0.425,0.302,0.939,0.418,1.568,0.434
		c0.47,0,0.914-0.086,1.327-0.341c0.421-0.22,0.759-0.567,1.005-0.978c0.255-0.413,0.374-0.877,0.374-1.393
		c0-0.486-0.116-0.933-0.367-1.35c-0.241-0.414-0.581-0.737-1.014-1.009c-0.424-0.23-0.9-0.366-1.42-0.366
		c-0.546,0.021-1.024,0.173-1.432,0.414c-0.405,0.263-0.73,0.615-0.952,1.027C378.069,340.286,377.965,340.728,377.965,341.189z"
      />
      <path d="M385.776,344.639v-9.411h0.94v9.411H385.776L385.776,344.639z" />
      <path
        d="M388.092,341.093c0.018-0.564,0.118-1.055,0.328-1.512c0.192-0.445,0.478-0.813,0.813-1.111
		c0.343-0.304,0.742-0.559,1.16-0.74c0.425-0.137,0.854-0.228,1.295-0.233c0.597,0,1.156,0.158,1.714,0.431
		c0.563,0.271,1.019,0.7,1.367,1.291c0.376,0.587,0.531,1.275,0.531,2.087v0.146h-6.265c0.045,0.521,0.184,0.956,0.478,1.356
		c0.267,0.353,0.597,0.605,0.981,0.802c0.409,0.169,0.796,0.251,1.166,0.251c0.333,0,0.689-0.075,1.029-0.178
		c0.333-0.15,0.627-0.347,0.909-0.56c0.251-0.259,0.451-0.5,0.564-0.769h0.967c-0.208,0.497-0.478,0.949-0.877,1.35
		c-0.368,0.362-0.784,0.617-1.244,0.808c-0.462,0.193-0.928,0.247-1.4,0.247c-0.625-0.026-1.188-0.162-1.725-0.498
		c-0.545-0.29-0.974-0.71-1.298-1.271C388.25,342.432,388.092,341.831,388.092,341.093z M394.387,340.622
		c-0.08-0.392-0.228-0.756-0.497-1.084c-0.235-0.372-0.572-0.625-0.952-0.849c-0.374-0.181-0.783-0.29-1.223-0.29
		c-0.61,0-1.107,0.153-1.496,0.417c-0.388,0.285-0.677,0.612-0.867,0.948c-0.192,0.337-0.288,0.652-0.319,0.859h5.354V340.622z"
      />
      <path
        d="M397.508,335.974h-1.729v-0.746h4.346v0.746h-1.677v4.593h-0.94V335.974L397.508,335.974z M401.082,340.566v-5.339h1.354
		l1.55,3.776l1.563-3.776h1.354v5.339h-0.827v-4.593l-1.853,4.593h-0.499l-1.812-4.593v4.593H401.082z"
      />
    </g>
  </svg>
)
