// COMPONENTS
import React, { useEffect, useState } from 'react'

// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
// STYLE
import styled from '@emotion/styled'

/* TYPES */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = RouteComponentProps & DispatchProps & StateProps

/* STYLES */
const StyledHome = styled('div')`
  display: flex;
  justify-content: center;
  .population-dashboard-link {
    margin: 20px auto;
    display: block;
    border: 1px solid #d0d0d0;
    border-radius: 0.45rem;
    padding: 1rem 8rem;
    background-color: #26b;
    color: #f0f0f0;
    width: fit-content;
    font-weight: bold;
  }
`

const NotificationItem = styled('div')`
  opacity: 1;
  max-height: 600px;
  transition: max-height 1s, opacity 0.4s;
  overflow: hidden;
  &.removed {
    max-height: 0;
  }
  .usa-alert--info {
    background-color: #e7f6f8;
    &:before {
      background-color: #00bde3;
    }
  }
  .usa-alert--slim {
    padding: 10px 30px;
    p {
      margin: 0;
    }
  }
  .trashcan {
    width: 20px;
    height: 20px;
    cursor: pointer;
    .trashcan-open {
      display: none;
    }

    &:hover {
      .trashcan-open {
        display: inline;
      }
      .trashcan-closed {
        display: none;
      }
    }
  }
`

export type NotificationProps = {
  item: {
    notification: type.Notification
    dataset: type.PopulationDatasetMetadata
  }
  deleteNotification: (uuid: string) => void
}

const Notification: React.SFC<NotificationProps> = ({
  item,
  deleteNotification,
}) => {
  const [clicked, setClicked] = React.useState(false)
  return (
    <NotificationItem className={clicked ? 'removed' : ''}>
      <div className="usa-alert usa-alert--info usa-alert--slim">
        <div className="usa-alert__body">
          <p
            className="usa-alert__text"
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              <b>{item.dataset ? item.dataset.filename : ''}</b> has been shared with you
            </span>
            <span
              onClick={() => {
                deleteNotification(item.notification.uuid)
                setClicked(!clicked)
              }}
              className="usa-link">
              <svg
                style={{ width: '20px', height: '20px' }}
                className="trashcan icon-trashcan ct-delete"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 25 24.8"
                xmlSpace="preserve"
                data-ember-action-1015="1015">
                <g className="trashcan-open">
                  <path d="M18.7,24.4H5.9L4.9,7h14.9L18.7,24.4z M7.6,22.6H17l0.8-13.7h-11L7.6,22.6z"></path>
                  <polygon points="13.6,10.3 13.1,21.2 14.9,21.2 15.4,10.3 "></polygon>
                  <polygon points="11.5,21.2 11,10.3 9.2,10.3 9.7,21.2 "></polygon>
                  <path d="M19.1,0.7l-4.7,0.9l-0.8-1.4L8.2,1.3L8,3l-4.7,1l0.2,4.7l17.3-3.5L19.1,0.7z M8.8,1.9l4.4 -1.0 l0.5,0.8L8.7,2.8z M5.2,6.4l0-1L18,2.8l0.3,0.9L5.2,6.4z"></path>
                </g>
                <g className="trashcan-closed">
                  <path d="M6.8,8.8h11L17,22.6H7.6L6.8,8.8z M4.9,7l1,17.4h12.8l1-17.4H4.9z"></path>
                  <polygon points="13.6,10.3 13.1,21.2 14.9,21.2 15.4,10.3 "></polygon>
                  <polygon points="11.5,21.2 11,10.3 9.2,10.3 9.7,21.2 "></polygon>
                  <path d="M20.4,4h-4.8l-0.5-1.6H9.5L9,4H4.2L3.5,8.6h17.6L20.4,4z M9.9,3.2h4.8L14.9,3.9h-5.2zM5.6,6.7l0.2-1 h13l0.2,1H5.6z"></path>
                </g>
              </svg>
            </span>
          </p>
        </div>
      </div>
    </NotificationItem>
  )
}

const NotificationsDashboard: React.SFC<Props> = ({
  notifications,
  populationDatasets,
  configFiles,
  loadData,
  deleteNotification,
}) => {

  const [augmentedNotifications, setAugmentedNotifications] = useState<any[]>([])

  useEffect(()=>{
    loadData()
  }, [])

  useEffect(()=>{
    setAugmentedNotifications(createAugmentedNotifications())
  }, [configFiles, populationDatasets])

  function createAugmentedNotifications(){
    const dataset = (type) => type === "DATASET_SHARED" ? populationDatasets : configFiles
    const augNotifications = notifications.map(notification => ({
        notification,
        dataset: dataset(notification.type).find(
          dataset => notification.data.uuid === dataset.uuid,
        ),
      }))
    return augNotifications
  }

  return (
    <StyledHome>
      <section
        className="usa-site-alert usa-site-alert--info"
        style={{ width: '800px', maxWidth: '90%' }}>
        {augmentedNotifications.map((notification, index) => (
          <Notification
            item={notification}
            deleteNotification={deleteNotification}
            aria-label={notification.dataset ? notification.dataset.filename : ''}
            key={index}
          />
        ))}
        <Link className="population-dashboard-link" to="population-dashboard">
          View your Population Datasets Dashboard
        </Link>
      </section>
    </StyledHome>
  )
}

const mapStateToProps = store => {
  const { populationDatasets, configFiles, notifications } = store
  return {
    populationDatasets,
    configFiles,
    notifications,
  }
}

const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  loadData: () => {
    dispatch(actions.loadNotifications())
    dispatch(actions.loadPopulationDatasets())
    dispatch(actions.loadConfigFiles())
  },
  deleteNotification: uuid => {
    dispatch(actions.deleteNotification(uuid))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsDashboard)
