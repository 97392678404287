import React from 'react'
import ReactTable from 'react-table'
//import FoldableTableHOC from 'react-table/lib/hoc/foldableTable'
import { handleCSVDownload } from '../utils/csv'
import styled from '@emotion/styled'
import styles from '../styles'
import 'react-table/react-table.css'
import * as type from '../type'

const Button = styled.button({
  display: 'block',
  margin: '0.75rem 0 0 auto',
  padding: '0',
  textAlign: 'right',
  ...styles.components.linkStyles,
  fontSize: '1.5rem',
})

type Props = {
  csvData: type.CSV
  foldable: boolean
  columns: Array<object>
  tableProps: object
  csvFilename: string
}

export const generateHeader = key => {
  switch (key) {
    case 'qaly':
      return 'QALY'
    default:
      return key
        .split('_')
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(' ')
  }
}

const SimpleTableOutput: React.SFC<Props> = ({
  csvData,
  foldable,
  columns,
  tableProps,
  csvFilename,
}) => {
  let Table = ReactTable
  //if (foldable) Table = FoldableTableHOC(Table)
  return (
    <div id="simple-table">
      <Table
        className="-striped -highlight"
        pageSize={csvData.length}
        showPagination={false}
        data={csvData}
        columns={columns}
        sortable={false}
        {...tableProps}
      />
      <Button onClick={handleCSVDownload(csvData, csvFilename)}>
        Download Table as CSV
        <styles.components.LinkDownloadIcon />
      </Button>
    </div>
  )
}

export default SimpleTableOutput
