// COMPONENTS
import React from 'react'
import SimpleTableOutput from './SimpleTableOutput'
import { generateColumns } from './IncidenceOutput'
import { simpleReformatForCSV } from '../utils/csv'
// UTILS
import utils from '../utils'
// TYPES
import * as type from '../type'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'

/* TYPES */
type Props = ContainerProps & {
  core: any
  scenarioName: string
}
type ContainerProps = {
  positionStyles: type.CSSObject
}

/* STYLES */
const StyledCoreOutputsContainer = styled('section')<ContainerProps>(
  {},
  props => props.positionStyles,
)
const StyledCoreOutputTable = styled(styles.components.DevBlob)({
  margin: '0 auto',
  width: 'calc(100% - 3rem)',
  height: '18rem',
})

const CoreOutputs: React.SFC<Props> = ({
  positionStyles,
  core,
  scenarioName,
}) => {
  const csvData = utils.results.simpleInvert(simpleReformatForCSV(core.cost))
  return (
    <StyledCoreOutputsContainer positionStyles={positionStyles}>
      <styles.components.OrangeH2>Core Outputs</styles.components.OrangeH2>
      {core.cost ? (
        <SimpleTableOutput
          csvData={csvData}
          foldable={false}
          columns={generateColumns(csvData[0])}
          tableProps={{}}
          csvFilename={`${scenarioName}_core.csv`}
        />
      ) : (
        <StyledCoreOutputTable />
      )}
    </StyledCoreOutputsContainer>
  )
}

export default CoreOutputs
