import * as type from '../type'

export const saveAccessToken = (token: string) =>
  localStorage.setItem('accessToken', token)
export const saveRefreshToken = (token: string) =>
  localStorage.setItem('refreshToken', token)

export const loadAccessToken = () => localStorage.getItem('accessToken')
export const loadRefreshToken = () => localStorage.getItem('refreshToken')

export const clearAccessToken = () => localStorage.removeItem('accessToken')
export const clearRefreshToken = () => localStorage.removeItem('refreshToken')

export const saveAuthState = (state: type.AuthState) => {
  if (state.access) {
    saveAccessToken(state.access)
  } else {
    clearAccessToken()
  }
  if (state.refresh) {
    saveRefreshToken(state.refresh)
  } else {
    clearRefreshToken()
  }
}

export const loadAuthState = () => {
  return {
    access: loadAccessToken(),
    refresh: loadRefreshToken(),
  }
}
