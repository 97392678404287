import * as components from './components'
import * as misc from './misc'
import * as colors from './colors'
import * as constants from './constants'

// modules here reference eachother, but only those below themselves in this order
export default {
  components,
  misc,
  colors,
  constants,
}
