// COMPONENT
import React from 'react'
// UTILS
import {
  getFieldType,
  getColumnCount,
  structureTable,
  getGridTemplateColumns,
} from './utils'
// TYPES
import * as type from '../../type'
// STYLE
import styled from '@emotion/styled'

type ObjectFieldTemplateProps = {
  TitleField: (props: type.RJSFTitleFieldProps) => React.ReactElement
  DescriptionField: (props: DescriptionFieldProps) => React.ReactElement
  title: string
  description: string
  disabled: boolean
  properties: Array<ObjectFieldTemplateProperties>
  readonly: boolean
  required: boolean
  schema: type.Subschema
  uiSchema: type.UISchema
  formData: type.OutputData
  formContext: undefined
}
type DescriptionFieldProps = {
  description: string
}

type TemplateRenderDict = {
  [key: string]: (props: ObjectFieldTemplateProps) => React.ReactElement
}
type ObjectFieldTemplateProperties = {
  content: Content
  name: string
  disabled: boolean
  readonly: boolean
}
interface Content extends React.ReactElement {
  key: string | number
}
type GridProps = {
  columnCount?: number
  gridTemplateColumns?: string
}
type StyledProps<T = {}> = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement> & Partial<T>,
  HTMLDivElement
>
type TableItemProps = {
  theme: {
    backgroundColor: string
  }
}

const DefaultPropertyContainer = styled('div')({
  paddingBottom: '6rem',
})
const ColumnContainer = styled('div')({})
const ColumnItem = styled('div')({
  paddingBottom: '6rem',
})

const Grid = styled('div')<GridProps>(
  {
    paddingBottom: '2rem',
    display: 'grid',
    gridGap: '3rem',
    overflowX: 'scroll',
  },
  ({ columnCount }) => ({
    gridTemplateColumns: `repeat(${columnCount ||
      'auto-fit'}, minmax(25rem, 1fr))`,
  }),
)
const GridItem = styled('div')`
  .form-group.field {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
`
const Table = styled('div')<GridProps>(
  {
    paddingBottom: '2rem',
    display: 'grid',
    overflowX: 'scroll',
  },
  ({ columnCount, gridTemplateColumns }) => ({
    gridTemplateColumns:
      gridTemplateColumns ||
      `repeat(${columnCount || 'auto-fit'}, minmax(25rem, 1fr))`,
  }),
)
const TableHeading = styled('div')`
  background-color: #ddd;
  border-bottom: solid 1px #999;
  padding: 1rem;

  .form-group.field {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    label {
      margin: 0;
    }
  }
`

const TableItem = styled('div')<StyledProps<TableItemProps>>`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.backgroundColor || 'inherit'};

  .form-group.field {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    label {
      font-weight: 400;
      margin: 0;
    }
  }
`

const objectTemplateDict: TemplateRenderDict = {
  default: ({
    TitleField,
    DescriptionField,
    properties,
    title,
    description,
  }: ObjectFieldTemplateProps) => (
    <>
      <TitleField title={title} />
      <DescriptionField description={description} />
      <DefaultPropertyContainer>
        {properties.map(prop => (
          <div key={prop.content.key}>{prop.content}</div>
        ))}
      </DefaultPropertyContainer>
    </>
  ),
  column: ({
    TitleField,
    DescriptionField,
    properties,
    title,
    description,
  }: ObjectFieldTemplateProps) => (
    <ColumnContainer>
      <TitleField title={title} />
      <DescriptionField description={description} />
      <div>
        {properties.map(prop => (
          <ColumnItem key={prop.content.key}>{prop.content}</ColumnItem>
        ))}
      </div>
    </ColumnContainer>
  ),
  grid: ({
    TitleField,
    DescriptionField,
    properties,
    title,
    description,
    uiSchema,
  }: ObjectFieldTemplateProps) => (
    <>
      <TitleField title={title} />
      <DescriptionField description={description} />
      <Grid columnCount={getColumnCount(uiSchema)}>
        {properties.map(prop => (
          <GridItem key={prop.content.key}>{prop.content}</GridItem>
        ))}
      </Grid>
    </>
  ),
  table: ({
    TitleField,
    DescriptionField,
    properties,
    title,
    description,
    uiSchema,
  }: ObjectFieldTemplateProps) => {
    const numColumns = getColumnCount(uiSchema)
    const { headings, rows } = structureTable(properties, numColumns)
    const gridTemplateColumns = getGridTemplateColumns(uiSchema)
    return (
      <>
        <TitleField title={title} />
        <DescriptionField description={description} />
        <Table
          columnCount={numColumns}
          gridTemplateColumns={gridTemplateColumns}>
          {headings.map(prop => (
            <TableHeading key={prop.content.key}>{prop.content}</TableHeading>
          ))}
          {rows.map((row, r) =>
            row.map(prop => {
              const theme = {
                backgroundColor: r % 2 ? '#f6f6f6' : 'white',
              }
              return (
                <TableItem key={prop.content.key} theme={theme}>
                  {prop.content}
                </TableItem>
              )
            }),
          )}
        </Table>
      </>
    )
  },
}

function CustomObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  const { uiSchema } = props
  const FieldTemplate = objectTemplateDict[getFieldType(uiSchema)]
  return FieldTemplate(props)
}

export default CustomObjectFieldTemplate
