// REACT
import React, { SFC, useEffect } from 'react'
import { CdcLogo } from '../common/CdcLogo'
import { Switch, Route, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
// UTILS
import { loadNotifications, logout } from '../store/actions'
import utils from '../utils'
// TYPES
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styles from '../styles'

/* TYPES */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps<any>

const LogoPositioner = styled(styles.components.WidthSetter)({
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  display: 'flex',
  justifyContent: 'flex-start',
})

/* STYLES */
const subheaderSectionStyles = {
  ...styles.colors.lightGrayBackground,
  display: 'flex',
}
const centerHeaderStyles: type.CSSObject = {
  display: 'inline-block',
  boxSizing: 'border-box',
  margin: '0 auto',
  padding: '1.5rem 0',
}

const StyledSigninSubheader = styled('section')({
  ...subheaderSectionStyles,
})
const StyledH1Center = styled('h1')(centerHeaderStyles)

const StyledPageSubheader = styled('section')({
  ...subheaderSectionStyles,
  textAlign: 'left',
})
const FlexedWidthSetter = styled(styles.components.WidthSetter)({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
})
const StyledH2Center = styled('h2')(centerHeaderStyles)
const StyledH1Minimized = styled('h1')({
  flex: 1,
  alignSelf: 'flex-end',
  margin: '0 0 0.3rem',
  fontSize: '1.7rem',
})
const StyledDivMinimized = styled('div')({
  flex: 1,
  alignSelf: 'flex-end',
  margin: '0 0 0.3rem',
  fontSize: '1.7rem',
})
const StyledHeaderNav = styled('nav')({
  flex: 1,
  margin: '0.5rem 0 0',
  display: 'flex',
  justifyContent: 'flex-end',
})
const StyledHeaderNavLink = styled(Link)({
  margin: '0 0 0 2.2rem',
  textDecoration: 'none',
  ...styles.colors.blackText,
  '&:hover': {
    color: '#000',
  },
})
const StyledHeaderNavLinkButton = styled('p')({
  margin: '0 0 0 2.2rem',
  textDecoration: 'none',
  ...styles.colors.blackText,
  '&:hover': {
    color: '#000',
    textDecoration: 'underline',
  },
})

const Badge = styled('span')({
  backgroundColor: '#115eab',
  fontSize: '10px',
  borderRadius: '50%',
  color: '#fff',
  position: 'absolute',
  top: ' -14px',
  right: '-15px',
  lineHeight: '1',
  width: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '20px',
  boxShadow: '1px 1px 4px -1px rgba(0,0,0,0.28)',
  fontFamily: 'Arial',
  fontWeight: 'bold',
})

/* SUB-COMPONENTS */
export const SigninSubheader: SFC<object> = () => {
  return (
    <StyledSigninSubheader>
      <StyledH1Center>Diabetes Simulation Models</StyledH1Center>
    </StyledSigninSubheader>
  )
}

export const PageSubheader: SFC<Props> = props => {
  const { location } = props
  const title = location && utils.misc.parseLocationToHeading(location)

  useEffect(props.loadData, [])
  return (
    <StyledPageSubheader>
      <FlexedWidthSetter>
        {title === 'Population Dashboard' ? (
          <StyledDivMinimized />
        ) : (
          <StyledH1Minimized className="small-h1">
            Diabetes Simulation Models
          </StyledH1Minimized>
        )}
        <StyledH2Center>{title}</StyledH2Center>
        <StyledHeaderNav>
          <StyledHeaderNavLink to="/home">Home</StyledHeaderNavLink>
          <StyledHeaderNavLink to="/config-file-dashboard">
            Scenario Files
          </StyledHeaderNavLink>
          <StyledHeaderNavLink to="/population-dashboard">
            Population Datasets
          </StyledHeaderNavLink>
          <div style={{ position: 'relative' }}>
            <StyledHeaderNavLink to="/notifications">
              Notifications
            </StyledHeaderNavLink>
            <Badge className="usa-tag">{props.notificationsCount}</Badge>
          </div>
          <StyledHeaderNavLinkButton
            onClick={async () => {
              await props.handleLogout()
              props.history.push('/login')
            }}>
            <StyledHeaderNavLink to="#">Sign out</StyledHeaderNavLink>
          </StyledHeaderNavLinkButton>
        </StyledHeaderNav>
      </FlexedWidthSetter>
    </StyledPageSubheader>
  )
}

/* COMPONENT */
const Header: SFC<Props> = props => {
  return (
    <header>
      <LogoPositioner className="positioner">
        <a href="https://www.cdc.gov/" title="CDC Logo">
          <CdcLogo />
        </a>
      </LogoPositioner>
      <Switch>
        <Route exact path="/login" component={SigninSubheader} />
        <Route path="/" render={() => <PageSubheader {...props} />} />
      </Switch>
    </header>
  )
}

const mapStateToProps = store => {
  const { notifications } = store
  const notificationsCount = notifications.length
  return { notificationsCount }
}

const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  loadData: () => {
    dispatch(loadNotifications())
  },
  handleLogout: () => dispatch(logout()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
