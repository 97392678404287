// COMPONENT
import React, { FC, useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import UploadConfigFile from './ConfigFileUpload'
import { ActionButton, DoubleCheckButton } from './ScenariosPortfolio'
// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// UTILS
import utils from '../utils'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'
// OTHER
import { saveAs } from 'file-saver'
import FinishedSetupScreen from './form/FinishedSetupScreen'
import ScrollToTop from './ScrollToTop'
import urljoin from 'url-join'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/* STYLES */
const StyledHome = styled('section')({
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
})

const StyledScenariosPortfolio = styled('section')({
  borderBottom: 'none',
  marginBottom: '40px',
})

const PortfolioGridContainer = styled('div')(
  ({ theme: computedGridStyles }) => ({
    display: 'grid',
    fontSize: '14px',
    width: '100%',
    maxHeight: '80vh',
    overflow: 'scroll',
    ...computedGridStyles,
  }),
)

const gridItemStyles = {
  padding: '0 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const TableHeaderCel = styled('div')(({ theme }) => ({
  backgroundColor: '#b6b6b677',
  borderTop: '3px solid #00000000',
  borderBottom: '3px solid #00000000',
  boxSizing: 'border-box',
  fontWeight: 'bold',
  ...gridItemStyles,
  ...theme,
}))

const TableCell = styled('div')(({ theme }) => ({
  ...gridItemStyles,
  ...theme,
}))

const EmptyPortfolio = styled('div')(({ theme }) => ({
  ...gridItemStyles,
  ...theme,
  fontWeight: 'bold',
  padding: '1rem',
  backgroundColor: '#f6f6f6',
}))

const TableContent = styled.p({
  margin: '0.5rem',
  maxWidth: '180px',
  overflowWrap: 'break-word',
})
const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

let headings = {
  uploaded_at: 'Uploaded at',
  filename: 'Filename',
  scenario_name: 'Scenario Name',
  description: 'Description',
  type_of_run: 'Type of Run',
  custom_population: 'Custom Population',
  population_size: 'Population Size',
  iterations: '# of Iterations',
  type: 'Diabetes Type',
  uuid: 'Actions',
}

let populationDatasetTypes = {
  t1d: 'Type 1',
  t2d: 'Type 2',
  pre: 'Prediabetes',
  screen: 'Screening',
}

const MODAL_ENDPOINT = 'run-simulation'

/* COMPONENTS */
let uniqueKey = 0
const PortfolioTable: FC<Props> = ({
  configFiles,
  shipData,
  shareConfigFile,
  deleteConfigFile,
  reloadSchema,
  history,
}) => {
  const downloadConfig = (config, filename) => {
    let fileToSave = new Blob([JSON.stringify(config)], {
      type: 'application/json',
    })
    saveAs(fileToSave, filename)
  }
  const sortConfigByDate = configFiles => {
    return configFiles.sort((a, b) =>
      new Date(a.uploaded_at) < new Date(b.uploaded_at) ? 1 : -1,
    )
  }
  const columnCount = Object.keys(headings).length
  const rowCount = configFiles.length + 1
  return (
    <PortfolioGridContainer
      className="scroll-box"
      theme={{
        gridTemplateColumns: `repeat(${columnCount}, auto)`,
        gridTemplateRows: `repeat(${rowCount}, auto)`,
      }}>
      {Object.entries(headings).map(([key, heading]) => {
        return (
          <TableHeaderCel
            key={uniqueKey++}
            theme={{}}
            onClick={() => console.log('sort')}>
            <TableContent>{heading}</TableContent>
          </TableHeaderCel>
        )
      })}

      {configFiles.length ? (
        sortConfigByDate(configFiles).map((configFile, i) =>
          Object.keys(headings).map(key => {
            let value
            if (key === 'uuid') {
              value = (
                <ButtonContainer>
                  <ActionButton
                    disabled={false}
                    onClick={() => {
                      downloadConfig(configFile.data, configFile.filename)
                    }}
                    theme={{}}>
                    Download
                  </ActionButton>
                  <ActionButton
                    disabled={false}
                    onClick={() => shareConfigFile(configFile.uuid)}
                    theme={{}}>
                    Share
                  </ActionButton>
                  <ActionButton
                    disabled={false}
                    onClick={() => {
                      shipData(configFile.data)
                      const path = urljoin(location.pathname, MODAL_ENDPOINT)
                      history.push(path)
                    }}
                    theme={{}}>
                    Run
                  </ActionButton>
                  <ActionButton
                    disabled={false}
                    onClick={async () => {
                      await reloadSchema(configFile.data.config)
                      if (history) history.push(`/new-scenario`)
                      else throw 'unexpected history is undefined'
                    }}
                    theme={{}}>
                    Edit &amp; Run
                  </ActionButton>
                  <DoubleCheckButton
                    onClick={() => deleteConfigFile(configFile.uuid)}
                    theme={{ color: '#d60000' }}>
                    Delete
                  </DoubleCheckButton>
                </ButtonContainer>
              )
            } else if (key === 'uploaded_at') {
              value = (
                <TableContent>
                  {utils.portfolio.abbreviateDate(configFile[key])}
                  <br />
                  {utils.portfolio.abbreviateTime(configFile[key])}
                </TableContent>
              )
            } else if (key === 'type') {
              value = (
                <TableContent>
                  {populationDatasetTypes[configFile.data.config.diabetes_type]}
                </TableContent>
              )
            } else if (key === 'scenario_name') {
              value = (
                <TableContent>
                  {
                    configFile.data.summary['Basic Configurations'][
                      'Name of the scenario'
                    ]
                  }
                </TableContent>
              )
            } else if (key === 'type_of_run') {
              value = (
                <TableContent>
                  {
                    configFile.data.summary['Basic Configurations'][
                      'Type of simulation to run'
                    ]
                  }
                </TableContent>
              )
            } else if (key === 'custom_population') {
              value = (
                <TableContent>
                  {configFile.data.config.population_dataset_uuid === 'none'
                    ? 'No'
                    : 'Yes'}
                </TableContent>
              )
            } else if (key === 'population_size') {
              value = (
                <TableContent>
                  {
                    configFile.data.summary['Basic Configurations'][
                      'Number of individuals in the population'
                    ]
                  }
                </TableContent>
              )
            } else if (key === 'iterations') {
              value = (
                <TableContent>
                  {
                    configFile.data.summary['Basic Configurations'][
                      'Number of iterations'
                    ]
                  }
                </TableContent>
              )
            } else {
              value = <TableContent>{configFile[key]}</TableContent>
            }
            return (
              <TableCell
                key={uniqueKey++}
                theme={{
                  backgroundColor: i % 2 ? '#ebebeb77' : '#ffffff77',
                }}>
                {value}
              </TableCell>
            )
          }),
        )
      ) : (
        <EmptyPortfolio
          theme={{
            gridColumnStart: 1,
            gridColumnEnd: Object.keys(headings).length + 1,
          }}>
          <TableContent>---None---</TableContent>
        </EmptyPortfolio>
      )}
    </PortfolioGridContainer>
  )
}

const ConfigFileDashboard: FC<Props> = props => {
  useEffect(() => {
    props.loadConfigFiles()
  }, [])
  const pathToOpenModal = `/config-file-dashboard/${MODAL_ENDPOINT}`
  return (
    <ScrollToTop>
      <Switch>
        <StyledHome>
          <StyledScenariosPortfolio>
            <styles.components.OrangeH2>
              Scenario Files
            </styles.components.OrangeH2>
            <PortfolioTable {...props} />
            <UploadConfigFile />
          </StyledScenariosPortfolio>
        </StyledHome>
      </Switch>
      <Route path={pathToOpenModal} component={FinishedSetupScreen} />
    </ScrollToTop>
  )
}

const mapStateToProps = store => ({
  configFiles: store.configFiles,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  loadConfigFiles: async () => dispatch(await actions.loadConfigFiles()),
  shareConfigFile: async (uuid: string, cb = response => {}) =>
    dispatch(await actions.shareConfigFile(uuid, cb)),
  deleteConfigFile: async (uuid: string, cb = response => {}) =>
    dispatch(await actions.deleteConfigFile(uuid, cb)),
  reloadSchema: (loadedData: type.OutputData) =>
    dispatch(actions.initSchema(loadedData)),
  shipData: async data => dispatch(await actions.shipData(data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfigFileDashboard),
)
