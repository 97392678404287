import React from 'react'
import Chart from 'chart.js'
import SimpleTableOutput from './SimpleTableOutput'
import { Bar } from 'react-chartjs-2'
import styled from '@emotion/styled'
import styles from '../styles'
import utils from '../utils'
import * as type from '../type'

/* ChartJS */

Chart.defaults.global.defaultFontSize = 16
Chart.pluginService.register({
  beforeDraw: function(chartInstance) {
    var ctx = chartInstance.chart.ctx
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height)
  },
})

const Button = styled.button({
  display: 'block',
  margin: '0.75rem 0 0 auto',
  padding: '0',
  textAlign: 'right',
  ...styles.components.linkStyles,
  fontSize: '1.5rem',
})

type Props = {
  csvData: type.CSV
  foldable: boolean
  doNotFold?: Array<string>
  tableProps: object
  csvFilename: string
}

export const generateHeader = key => {
  switch (key) {
    case 'qaly':
      return 'QALY'
    default:
      return key
        .split('_')
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(' ')
  }
}

export const generateColumns = (csvDatapoint, doNotFold = [] as Array<any>) =>
  Object.keys(csvDatapoint).map(key => ({
    Header: generateHeader(key),
    foldable: !doNotFold.includes(key),
    accessor: key,
    minWidth: key === ' ' ? 100 : key.length * 8.5 + 40,
    resizable: true,
    style: { textAlign: 'center' },
  }))

const PrevalenceOutput: React.SFC<Props> = ({
  csvData,
  foldable,
  doNotFold,
  tableProps,
  csvFilename,
}) => {
  const filteredColumns = generateColumns(csvData[0], doNotFold)
    // remove Person Years from chart as requested by RTI 04/02/2020
    .filter(utils.csv.removeFields(['Person Years']))
  // the table uses Header(display)/accessor(property key) pairs to control display text
  // but chartjs uses the property key as the display label
  // keysToHeaders is used to map the property keys to the chartjs labels
  const keysToHeaders = filteredColumns.reduce((dict, col) => {
    dict[col.accessor] = col.Header
    return dict
  }, {})

  const numInterventions = csvData.length - 1
  let colorCounter = 0
  const baseHue = 45
  const chartData = {
    labels: Object.values(keysToHeaders).filter(label => label), // always population size * time.limit; dominates chart
    xAxisID: 'Risk Factors',
    yAxisID: 'Prevalence',
    datasets: csvData.map(intervention => {
      const label = intervention[' ']
      // generating non-repeating colors
      const hue =
        label === 'baseline'
          ? baseHue
          : baseHue + (++colorCounter * 360) / (1 + numInterventions)
      return {
        label,
        data: Object.keys(keysToHeaders).map(key => intervention[key]),
        backgroundColor: `hsla(${hue}, 100%, 80%)`,
        borderColor: `hsla(${hue}, 100%, 50%)`,
        borderWidth: 1,
      }
    }),
  }
  const options = {
    title: {
      display: true,
      text: 'Complication Prevalence',
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Counts',
        }
      }]
    },
  }

  const invertedCsvData = utils.results.simpleInvert(csvData)
  const invertedColumns = generateColumns(invertedCsvData[0], doNotFold)
  return (
    <div id="prevalence-output-container">
      <Bar data={chartData} options={options} height={200} />
      <Button
        onClick={utils.csv.handleChartDownload(
          '#prevalence-output-container canvas',
          csvFilename,
        )}>
        Download Chart as PDF
        <styles.components.LinkDownloadIcon />
      </Button>
      <hr />
      <SimpleTableOutput
        {...{
          csvData: invertedCsvData,
          foldable,
          columns: invertedColumns,
          tableProps,
          csvFilename,
        }}
      />
    </div>
  )
}

export default PrevalenceOutput
