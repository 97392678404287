import { ACTION } from '../actions'
import * as type from '../../type'

const initialState = {
  properties: {},
  'ui:layout': {
    pages: [],
  },
}

export default function schema(
  state: type.ConfigSchema = initialState,
  action: type.Action,
) {
  switch (action.type) {
    case ACTION.SET_FORM_STORE:
      return action.schema
    case ACTION.SET_POPULATION_DATASETS:
      return action.schema
    default:
      return state
  }
}
