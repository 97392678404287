import { ACTION } from './store/actions'
import * as JSONSchema from 'json-schema'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { CSSObject as EmotionCSSObject } from '@emotion/styled'
export type CSSObject = EmotionCSSObject

/* refactor types */

export function isObject(thing: any): thing is Object {
  return typeof thing === 'object'
}

export function isFunction(thing: any): thing is CallableFunction {
  return typeof thing === 'function'
}

export type OutputData = {
  [key: string]: OutputData | InputValue
}
export function isOutputData(thing: any): thing is OutputData {
  return thing !== undefined && thing !== null
}

export type ConfigPackage = OutputData & {
  config: OutputData
  summary: ConfigSummary
  created_at: string
}
export const isConfigPackage = (thing: any): thing is ConfigPackage => {
  return (
    typeof thing === 'object' &&
    ['config', 'summary'].every(key => thing.hasOwnProperty(key))
  )
}

export type ConfigSummary = {
  'Basic Configurations': {
    [key: string]: any
  }
  'Modified Sections': {
    [key: string]: any
  }
}

export type Store = {
  schema: ConfigSchema
  form: FormConfig
  results: Results
  auth: AuthState
  portfolio: Portfolio
  populationDatasets: Array<PopulationDatasetMetadata>
}
export type SavedStoreItem = ConfigSchema | FormConfig | JSONSchema
export function isSavedStoreItem(thing: any): thing is SavedStoreItem {
  return (
    typeof thing === 'object' &&
    (thing.hasOwnProperty('pages') ||
      (thing.hasOwnProperty('properties') && thing.hasOwnProperty('ui:layout')))
  )
}

export type StringMap = {
  [key: string]: string
}

export type JSONSchema = JSONSchema.JSONSchema4

export type ConfigSchema = {
  properties: Properties
  'ui:layout': FormConfig
  [key: string]: any
}
export function isConfigSchema(thing: any): thing is ConfigSchema {
  if (typeof thing !== 'object') return false
  const keys = Object.keys(thing)
  return keys.includes('properties') && keys.includes('ui:layout')
}

export type Properties = {
  [key: string]: Subschema | ReferencingSubschema
}

export type Subschema = {
  type: string
  'ui:schema'?: UISchema

  title?: string
  description?: string

  default?: any

  _basic_config?: boolean
  _watch_for_changes?: boolean | string
} & (ObjectSubschema | ArraySubschema | ValueSubschema)
export function isSubschema(thing: any): thing is Subschema {
  if (typeof thing !== 'object') return false
  const keys = Object.keys(thing)
  return keys.includes('type')
}
export type ObjectSubschema = {
  properties: Properties
  [key: string]: any
}
export type ArraySubschema = {
  items: Array<Subschema> | Subschema
  additionalItems?: Subschema
  enum?: Array<string>
  [key: string]: any
}
export function isArraySubschema(thing: any): thing is Subschema {
  if (typeof thing !== 'object') return false
  const keys = Object.keys(thing)
  return (
    thing.type === 'array' && keys.includes('type') && keys.includes('items')
  )
}
export function isDynamicArraySubschema(thing: any): thing is ArraySubschema {
  return (
    typeof thing === 'object' && thing.max_items_length && thing.items_length
  )
}
export type ValueSubschema = {
  [key: string]: any
}

export type ReferencingSubschema = Partial<Subschema> & {
  $ref: string
}

export type UISchema = {
  'ui:order'?: Array<string>
  'ui:options'?: UIOptions
  'ui:field_type'?: FieldType
  [key: string]: UISchema | any
}

export type UIOptions = {
  [key: string]: any
}

export type FieldType =
  | string
  | {
      type: string
      columns: number
      gridTemplateColumns?: string
    }

export type Notification = {
  uuid: string
  timestamp: string
  type: string
  data: SharedDatasetNotificationData | ExampleOtherNotificationData
}

export type SharedDatasetNotificationData = {
  uuid: string
}

export type ExampleOtherNotificationData = {
  sender: string
  message: string
}

export type PopulationDatasetMetadata = {
  uuid: string
  uploaded_at: string
  filename: string
  description: string
  creator: string
  type: string
}

export type FormConfig = {
  pages: Array<Page>
  [key: string]: any
}

export type Page = BuiltinPage | FormPage

export type CommonPageProperties = {
  url_path: string
  title?: string
  expand_if?: DynamicCondition
}

export type FormPage = CommonPageProperties & {
  fieldsets: Fieldsets
  group_options?: GroupOptions
  form_submit_button?: boolean | string
}
export function isFormPage(thing: any): thing is FormPage {
  return thing.hasOwnProperty('fieldsets')
}
export type GroupOptions = {
  group_type: 'tabs' | 'accordion' | 'default'
  header_buttons?: StaticHeaders | DynamicHeaders
  default_collapsed?: boolean
}
export type StaticHeaders = Array<string>
export type DynamicHeaders = {
  common_text: string
  numbered?: boolean
}

export type BuiltinPage = CommonPageProperties & {
  built_in_page: BuiltinPageConfig
  fieldsets?: Fieldsets
}
export function isBuiltinPage(thing: any): thing is BuiltinPage {
  return typeof thing === 'object' && isBuiltinPageConfig(thing.built_in_page)
}
export type BuiltinPageConfig = CommonPageProperties & {
  type: BuiltinPageTypes
  title?: string
  message?: string
  button_text?: string
}
export function isBuiltinPageConfig(thing: any): thing is BuiltinPageConfig {
  return typeof thing === 'object' && isBuiltinPageTypes(thing.type)
}
export type BuiltinPageTypes = 'introduction' | 'summary'
export function isBuiltinPageTypes(thing: any): thing is BuiltinPageTypes {
  return (
    typeof thing === 'string' &&
    ['introduction', 'dataset-picker', 'summary'].includes(thing)
  )
}

export type Fieldsets = Array<Group> | Array<Fieldset>
export function isGroups(thing: any): thing is Array<Array<Fieldset>> {
  return Array.isArray(thing) && thing.every(isGroup)
}

export type Group = Array<Fieldset>
export function isGroup(thing: any): thing is Group {
  return Array.isArray(thing) && thing.every(isFieldset)
}

export type Fieldset = Partial<Subschema> & {
  $ref?: string
  expand_if?: DynamicCondition
  update_on_change?: boolean
  items_length?: string
  max_items_length?: number
  layout_key?: string
  schema_key?: string
  validation?: Array<ValidationCondition>
}
export function isFieldset(thing: any): thing is Fieldset {
  return typeof thing === 'object' && thing.hasOwnProperty('type')
}
export function isExtractableFieldset(thing: any): thing is Fieldset {
  return typeof thing === 'object' && thing.hasOwnProperty('schema_key')
}

export type ValidationCondition =
  | string
  | {
      condition: string
      error_message: string
    }

export type DynamicCondition = BooleanOperation | ObjectPointer

export type BooleanOperation = {
  $and?: OperandSet
  $or?: OperandSet
}

const boolOpKeyPattern = /^\$(and|or)$/
const isBoolOpKey = (str: string) => boolOpKeyPattern.test(str)
export function isBooleanOperation(thing: any): thing is BooleanOperation {
  if (typeof thing === 'object') {
    let expressions = Object.entries(thing)
    if (expressions.length === 1) {
      let [operator, operands] = expressions[0]
      if (isBoolOpKey(operator)) {
        if (isOperandSet(operands)) return true
      }
    }
  }
  return false
}

export type OperandSet = {
  [key: string]: DynamicCondition
}

export function isOperandSet(thing: any): thing is OperandSet {
  if (typeof thing === 'object') {
    const operands = Object.entries(thing).map(([key, value]) => ({
      [key]: value,
    }))
    return operands.every(
      operand => isBooleanOperation(operand) || isObjectPointer(operand),
    )
  }
  return false
}

export type ObjectPointer = {
  [key: string]: ObjectPointer | InputValue
}
export function isObjectPointer(thing: any): thing is ObjectPointer {
  return (
    typeof thing === 'string' ||
    (typeof thing === 'object' && Object.keys(thing).length === 1)
  )
}

export type NestedObj = {
  [key: string]: any
}

export type EmptyObj = {}
export function isEmptyObject(thing: any): thing is EmptyObj {
  if (typeof thing !== 'object') return false
  for (var key in thing) {
    if (thing.hasOwnProperty(key)) return false
  }
  return true
}

export type InputValue = string | number | boolean | undefined | null

type ExtraArgs = undefined

export type ThunkResult<R> = ThunkAction<R, Store, ExtraArgs, AnyAction>

export type TDispatch = ThunkDispatch<Store, ExtraArgs, AnyAction>

export type Action = {
  type: ACTION
  [extraProps: string]: any
}

/* common ui types */

export type PageButtonPaths = {
  prevPagePath: string
  nextPagePath: string
}

export type RJSFTitleFieldProps = {
  title: string
}
export type ArrayFieldTemplateItem = {
  children: React.ReactElement
  className: string
  disabled: boolean
  hasMoveDown: boolean
  hasMoveUp: boolean
  hasRemove: boolean
  hasToolbar: boolean
  index: number
  onDropIndexClick: (index: number) => (e: React.MouseEvent) => void
  onReorderClick: (
    index: number,
    newIndex: number,
  ) => (e: React.MouseEvent) => void
  readonly: boolean
}

/* V0 types */
export type ScenariosPortfolioSection = {
  title: string
  scenarios: Array<ScenarioPortfolioItem>
}

export type ScenarioPortfolioItem = {
  title: string
}

export type OutputScenarioDescription = {
  name: string
  type: string
  inputData: string
  population: number
  timeline: string
  [key: string]: any
}

export type CoreOutput = {
  [key: string]: any
}

export type AdditionalOutput = {
  survival: {
    [key: string]: any
  }
  complications: {
    [key: string]: any
  }
  riskFactors: {
    [key: string]: any
  }
  sensitivityResults: {
    [key: string]: any
  }
  [key: string]: any
}

export type Output = {
  description: OutputScenarioDescription
  results: Results
}

export type Results = {
  configJSON: ConfigPackage
  status: string
  errorMessage: string
  cost: Cost | EmptyObj
  prevalence: Prevalence | EmptyObj
  mean_prevalence: Mprevalence | EmptyObj
  incidence: Incidence | EmptyObj
  cea: Cea | []
  mean_cea: Mcea | []
  ppc: Ppc | EmptyObj
  cost_raw: RawFile | EmptyObj
  prevalence_raw: RawFile | EmptyObj
  mean_prevalence_raw: RawFile | EmptyObj
  incidence_raw: RawFile | EmptyObj
  cea_raw: RawFile | EmptyObj
  mean_cea_raw: RawFile | EmptyObj
  ppc_raw: RawFile | EmptyObj
}

export type ConfigJSON =
  | {
      scenario_name: string
      equation_set: string
      simulation_type: string
      interventions: number
      seed: number
      population: {
        size: number
      }
      time_horizon: {
        strategy: string
        limit: number
      }
      [key: string]:
        | SensitivityAnalysisConfig
        | Array<InterventionSetConfig>
        | any
    }
  | EmptyObj

export type SensitivityAnalysisConfig = {
  risk_reductions: Array<string>
  factor_changes: Array<string>
}

export type InterventionSetConfig = {
  risk_reductions: {
    [key: string]: any
  }
  factor_changes: {
    [key: string]: any
  }
}

export type SimpleResults = {
  [key: string]: ResultSet
}

export type Cost = SimpleResults

export type Prevalence = SimpleResults

export type Mprevalence = SimpleResults

export type Ppc = SimpleResults

export type Mcea = SimpleResults

export type RawFile = SimpleResults

export type Incidence = {
  [key: string]: ResultSetsOverTime
}

export type Cea = Array<CeaDatapoint>

export type CeaDatapoint = {
  [key: string]: number
  baseline: number
  cratio: number
}

export type ICERCsv = Array<ICERPoint>

export type ICERPoint = {
  intervention: string
  cost_increment: number
  qaly_increment: number
}

export const exists = thing => thing !== null && thing !== undefined

export const hasICERData = (thing: any): thing is Partial<ICERPoint> => {
  return exists(thing.cost_increment) && exists(thing.qaly_increment)
}

export type CSV = Array<CSVRow>

export type CSVRow = {
  [key: string]: string | number
}

export type ParsedCSVRow = {
  Header: string
  [key: string]: any
}

export type ResultSetsOverTime = {
  [key: string]: ResultSet
}

// output per intervention/time-step for cost/prevalence/incidence
// not rigidly defined for schema flexibility
export type ResultSet = {
  [key: string]: number
}

export type CSVIncidenceRow = {
  time: string
  intervention: string
  [key: string]: number | string
}

/* API SPECIFIC */
export type StatusResponse = {
  message: string
}
export const isStatusResponse = (thing: any): thing is StatusResponse =>
  typeof thing === 'object' && thing.hasOwnProperty('message')

export type AuthState = {
  access: string | null
  refresh: string | null
}
export const isAuthState = (thing: any): thing is AuthState =>
  typeof thing === 'object' &&
  ['access', 'refresh'].every(key => thing.hasOwnProperty(key))

export type LoginCredentials = {
  username: string
  password: string
}

export type RefreshCredentials = {
  refresh: string
}

export type JwtAccessToken = {
  access: string
}
export const isJwtAccessToken = (thing: any): thing is JwtAccessToken =>
  typeof thing === 'object' && thing.hasOwnProperty('access')

export type RegistrationCredentials = {
  email: string
  username: string
  password: string
  password_confirm: string
}

export type RegistrationSuccessResults = {
  response: string
  email: string
  username: string
}
export type RegistrationFailureResults = {
  username: Array<string>
}

export const isRegistrationSuccessResults = (
  thing: any,
): thing is RegistrationSuccessResults =>
  typeof thing === 'object' &&
  ['response', 'email', 'username'].every(key => thing.hasOwnProperty(key))

export type TokenFailure = {
  detail: string
}
export const isTokenFailure = (thing: any): thing is TokenFailure =>
  typeof thing === 'object' && thing.hasOwnProperty('detail')

export type LoginResponse = AuthState | TokenFailure

export type RefreshResponse = JwtAccessToken | TokenFailure

export type RegistrationResponse =
  | RegistrationSuccessResults
  | RegistrationFailureResults

export type SaveConfigResults =
  | AuthorizedSaveConfigResults
  | UnauthorizedFetchResults
export type AuthorizedSaveConfigResults = {
  response: string
  input_name: string
  data: ConfigPackage
}

export const isAuthorizedSaveConfigResults = (
  thing: any,
): thing is AuthorizedSaveConfigResults =>
  typeof thing === 'object' &&
  ['response', 'input_name', 'data'].every(key => thing.hasOwnProperty(key))

export type LoadConfigResults = ConfigPackage | UnauthorizedFetchResults

export type UnauthorizedFetchResults = {
  detail: string
  code: string
  messages: Array<any>
}

export const isUnauthorizedFetchResults = (
  thing: any,
): thing is UnauthorizedFetchResults =>
  typeof thing === 'object' &&
  ['detail', 'code', 'messages'].every(key => thing.hasOwnProperty(key))

export type ListSchemaResults = ConfigIds | UnauthorizedFetchResults

export type ConfigIds = Array<string>

export const isConfigIds = (thing: any): thing is ConfigIds =>
  Array.isArray(thing) && thing.every(key => typeof key === 'string')

export type ScenarioDashboardColumnHeadings = {
  created_at: string
  diabetes_type: string
  scenario_name: string
  simulation_type: string
  custom_population: string
  size: string
  intervention_count: string
  uuid: string
  simulation_status: string
}

export type ScenarioDashboardSummary = {
  uuid: string
  created_at: string
  diabetes_type: string
  scenario_name: string
  simulation_type: string
  changes: string
  size: number
  intervention_count: number | string
  simulation_status: string
}

export type DashboardSummaries = Array<ScenarioDashboardSummary>

export type Portfolio = {
  headings: ScenarioDashboardColumnHeadings
  rows: DashboardSummaries
}
