// COMPONENTS
import React from 'react'
import PagingButtons from './PagingButtons'
import { JsonTable } from 'react-json-to-html'
// STATE
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { shipData } from '../../store/actions'
// UTILS
import utils from '../../utils'
import urljoin from 'url-join'
// TYPES
import * as type from '../../type'
import { RouteComponentProps } from 'react-router'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = type.BuiltinPageConfig &
  type.PageButtonPaths &
  StateProps &
  DispatchProps &
  RouteComponentProps

const FormSummaryPage: React.SFC<Props> = props => {
  const packagedData = utils.form.packageBasicConfigData(props.store)
  return (
    <div className="summary-display">
      <h1>{props.title || 'New Form'}</h1>
      <p>{props.message || ''}</p>
      {packagedData && (
        <>
          <JsonTable
            json={{
              'Basic Configurations': packagedData['Basic Configurations'],
            }}
          />
          <JsonTable
            json={{ 'Modified Sections': packagedData['Modified Sections'] }}
          />
        </>
      )}
      <PagingButtons
        prevPagePath={props.prevPagePath}
        handleSubmit={e => {
          props.handleSubmit()
          const path = urljoin(props.location.pathname, 'form-complete')
          props.history.push(path)
        }}
        contextualButtons={true}
      />
    </div>
  )
}

const mapStateToProps = (store: type.Store) => ({
  store,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  handleSubmit: () => dispatch(shipData()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormSummaryPage),
)
