// COMPONENTS
import React, { ReactElement } from 'react'
import SubForm from 'react-jsonschema-form'
import TabbedViews from '../TabbedViews'
import CustomObjectFieldTemplate from './CustomObjectFieldTemplate'
import CustomArrayFieldTemplate from './CustomArrayFieldTemplate'
import AccordionArrayFieldTemplate from './AccordionArrayFieldTemplate'
import PagingButtons from './PagingButtons'
// STATE
import { connect } from 'react-redux'
import { updateData, shipData } from '../../store/actions'
import selectors from '../../store/selectors'
import { withRouter } from 'react-router-dom'
// UTILS
import { trimArrays } from '../../utils/form'
import { handleMultiVariableValidation, modifyAccordionFieldset } from './utils'
import urljoin from 'url-join'
import shortid from 'shortid'
// TYPES
import * as type from '../../type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styled from '@emotion/styled'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps &
  DispatchProps &
  type.FormPage &
  type.PageButtonPaths &
  RouteComponentProps

type RefDict = {
  [key: string]: FormRef
}
type FormRef = React.RefObject<ReactElement> & {
  submit: () => void
  state: {
    errors: Array<any>
  }
}

export type RJSFSubmitData = {
  formData: type.OutputData
  schema: type.Subschema
}

type GroupRenderDict = {
  [key: string]: GroupRenderer
}
type GroupRenderer = (
  groups: Array<type.Group>,
  group_options: type.GroupOptions,
) => ReactElement

const FormPageContainer = styled('div')({
  marginTop: '4rem',
})
const PageHeader = styled('h2')({
  borderBottom: '3px double #eaeaea',
})
const ColumnContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

class FormPage extends React.Component<Props> {
  subFormRefs: RefDict = {}
  componentMounted = false

  handleChange = (key: number) => () => {
    if (this.componentMounted) {
      const subFormRef: FormRef = this.subFormRefs[key]
      subFormRef.submit()
    }
  }

  handleValidation = schema => (formData, errors) => {
    if (schema.validation) {
      errors = handleMultiVariableValidation(
        formData,
        schema.validation,
        errors,
      )
    }
    return errors
  }

  handleData = ({ formData, schema }: RJSFSubmitData) => {
    this.props.updateData(schema.layout_key, formData)
  }

  handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.handleSubmit()
    const path = urljoin(this.props.location.pathname, 'form-complete')
    this.props.history.push(path)
  }

  runValidation = async (): Promise<boolean> => {
    const forms = Object.values(this.subFormRefs)
    forms.forEach(form => form && form.submit())
    return await new Promise(resolve =>
      // submit event triggers validation handlers in form
      // tally of validation errors must be added to event queue after validate handlers
      // setTimeout 0ms hack to force error tally to wait just long enough
      setTimeout(() => {
        const errors = forms.reduce(
          (errorcount, form) =>
            errorcount + (form ? form.state.errors.length : 0),
          0,
        )
        resolve(errors === 0)
      }, 0),
    )
  }

  componentDidMount = () => (this.componentMounted = true)
  componentWillUnmount = () => (this.componentMounted = false)

  renderSubForm = (schema: type.Fieldset, i: number) => {
    const { state } = this.props
    const key = shortid.generate()
    const expanded = schema.expand_if
      ? selectors.evaluateConditionsFromState(schema.expand_if)
      : true
    schema = trimArrays(schema, state)
    schema = modifyAccordionFieldset(schema)
    return expanded ? (
      <SubForm
        schema={schema}
        uiSchema={schema['ui:schema']}
        ArrayFieldTemplate={CustomArrayFieldTemplate}
        ObjectFieldTemplate={CustomObjectFieldTemplate}
        onBlur={this.handleChange(key)}
        onChange={schema.update_on_change ? this.handleChange(key) : undefined}
        onSubmit={this.handleData}
        showErrorList={false}
        idPrefix={schema.schema_key}
        transformErrors={transformErrors}
        validate={this.handleValidation(schema)}
        ref={(element: FormRef) => (this.subFormRefs[key] = element)}
        key={i}>
        <div style={{}} />
      </SubForm>
    ) : null
  }

  renderGroupsAs: GroupRenderDict = {
    default: groups => (
      <ColumnContainer className="column-container">
        {groups.map((group: type.Group, i: number) => {
          const widthFraction = 1 / groups.length
          return (
            <div
              className="subform-group"
              key={i}
              style={{ width: `${widthFraction * 100 - 2.5}%` }}>
              {group.map(this.renderSubForm)}
            </div>
          )
        })}
      </ColumnContainer>
    ),
    tabs: (groups, group_options) => (
      <TabbedViews
        views={groups.map((group: type.Group, i: number) => ({
          name: `tabbed-views-group-${shortid.generate()}`,
          activated: i === 0,
          tabText: getHeaderButtonText(group_options, i),
          renderPanel: () => <>{group.map(this.renderSubForm)}</>,
        }))}
      />
    ),
    accordion: (groups, group_options) => (
      <AccordionArrayFieldTemplate
        {...{
          items: groups.map((group: type.Group) => ({
            children: <>{group.map(this.renderSubForm)}</>,
          })),
          uiOptions: group_options,
        }}
      />
    ),
  }

  renderLayout(
    layout: type.Fieldsets,
    group_options: type.GroupOptions = { group_type: 'default' },
  ) {
    if (type.isGroups(layout)) {
      const renderGroups =
        this.renderGroupsAs[group_options.group_type] ||
        this.renderGroupsAs['default']
      return renderGroups(layout, group_options)
    } else if (type.isGroup(layout)) {
      return layout.map(this.renderSubForm)
    } else {
      throw 'Error: page fieldsets may contain either only groups or only fieldsets.'
    }
  }

  render() {
    const {
      title,
      fieldsets,
      group_options,
      nextPagePath,
      prevPagePath,
      form_submit_button,
    } = this.props
    return (
      <FormPageContainer>
        {title && <PageHeader>{title}</PageHeader>}
        {this.renderLayout(fieldsets, group_options)}
        <PagingButtons
          {...{
            nextPagePath,
            prevPagePath,
            handleSubmit: this.handleSubmit,
            contextualButtons: true,
            preNavigationCB: this.runValidation,
            form_submit_button,
          }}
        />
      </FormPageContainer>
    )
  }
}

function getHeaderButtonText(groupOptions: type.GroupOptions, i: number) {
  const headerButtons = groupOptions.header_buttons
  if (Array.isArray(headerButtons)) return headerButtons[i]
  else if (typeof headerButtons === 'object')
    return `${headerButtons.common_text}${headerButtons.numbered &&
      ` ${i + 1}`}`
  else return ''
}

const prettifyLabel = (property?: string) => {
  if (property)
    return property
      .split('_')
      .map(word => word[0].toUpperCase() + word.slice(1))
      .join(' ')
  else return null
}

function transformErrors(errors: Array<any>) {
  const arrayErrorPattern = /^(\[\d+\]\.)/
  return errors.map(error => {
    if (error.property[0] === '.') {
      // handle errors in object fields
      // changes ".standard error message" to "Standard error message"
      error.stack = error.stack[1].toUpperCase() + error.stack.slice(2)
    } else if (error.property === '') {
      // handle errors in top-level schema properties
      error.stack = 'This field ' + error.stack
    } else if (arrayErrorPattern.test(error.property)) {
      // handle errors in array fields
      const propVector = error.property.split('.')
      const targetProp = prettifyLabel(propVector[propVector.length - 1])
      const parentProp = prettifyLabel(propVector[propVector.length - 2])
      error.stack = `${targetProp}${
        parentProp ? ` (under ${parentProp})` : ''
      } ${error.message}`
    }
    error.message = error.stack + '.'
    return error
  })
}

const mapStateToProps = (state: type.Store) => ({
  state,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  updateData: (storeKey: string, form: type.OutputData) =>
    dispatch(updateData(storeKey, form)),
  handleSubmit: () => dispatch(shipData()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormPage),
)
