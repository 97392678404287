import { ACTION } from '../actions'
import * as type from '../../type'

export const initialState = []

export default function configFiles(
  state: Array<Object> = initialState,
  action: type.Action,
) {
  switch (action.type) {
    case ACTION.GET_CONFIG_FILES:
      return action.payload
    default:
      return state
  }
}
