import extractData from 'json-schema-defaults'
import deepClone from 'object-assign-deep'
import * as type from '../type'

const intPattern = /\d+/

export function getFieldsetState(
  state: type.FormConfig,
  layoutKey: Array<string>,
) {
  let schema = state
  layoutKey.forEach((key: string) => {
    if (Array.isArray(schema) && key.match(intPattern)) {
      schema = schema[parseInt(key)]
    } else {
      schema = schema[key]
    }
  })
  return schema
}

export function loadDefaults(
  form: type.FormConfig,
  keyMap: type.StringMap,
  loadedData: type.OutputData,
) {
  // DEV: possible that this may be needed to clear defaults
  //      but currently validation fails without them
  //      discuss solutions w/ RTI
  //      david - 2/24/20
  // form = deepSelectiveMap(form, (key, value) => {
  //   if (key === 'default') return { key: null }
  //   else return { key, value }
  // })
  Object.keys(loadedData).forEach(schemaKey => {
    const state = getFieldsetState(form, keyMap[schemaKey].split('.'))
    const data = loadedData[schemaKey]
    if (type.isOutputData(data)) {
      dirtyDeepSetDefaults(state, data)
    }
  })
  return form
}

// takes newform and recursively sets its values to the default property of its fieldset schema in state
export function dirtyDeepSetDefaults(
  fieldsetState: type.Fieldset | type.JSONSchema,
  newFormData: type.OutputData,
) {
  if (fieldsetState.type === 'object' && typeof newFormData === 'object') {
    parallelizeObjectToSchema(fieldsetState, newFormData)
  } else if (fieldsetState.type === 'array' && Array.isArray(newFormData)) {
    parallelizeArrayToSchema(fieldsetState, newFormData)
  } else {
    fieldsetState.default = newFormData
  }
}
// recursively MUTATES schema based on the values of an object of like structure
function parallelizeObjectToSchema(
  schema: type.Fieldset | type.JSONSchema,
  object: type.OutputData,
) {
  const schemaProps = schema.properties
  const objEntries = Object.entries(object)
  if (schemaProps && Object.entries.length > 0) {
    const schemaPropKeys = Object.keys(schemaProps)
    objEntries.forEach(
      ([key, objValue]: [string, type.OutputData | type.InputValue]) => {
        if (type.isOutputData(objValue) && schemaPropKeys.includes(key)) {
          dirtyDeepSetDefaults(schemaProps[key], objValue)
        }
      },
    )
  }
}
function parallelizeArrayToSchema(
  schema: type.Fieldset | type.JSONSchema,
  array: Array<type.OutputData>,
) {
  // if (Array.isArray(schema))
  if (schema.items.type === 'string' && schema.items.hasOwnProperty('enum')) {
    schema.default = array
  } else {
    if (schema.items && array.length > 0) {
      if (array.length > schema.items.length)
        throw 'Error: mixup in array data-binding'
      for (let i = 0; i < array.length; i++) {
        dirtyDeepSetDefaults(schema.items[i], array[i])
      }
    }
  }
}

export const compileform = (state: type.Store) => {
  const compiledData: type.OutputData = {}
  state.form.pages.forEach(page => pullFieldsets(page, compiledData))
  return compiledData
}
function pullFieldsets(page: type.Page, compiledData: type.OutputData) {
  if (type.isFormPage(page)) {
    page.fieldsets.forEach((schemaItem: type.Fieldset | type.Group) => {
      const compileFieldsetDataCurry = (fieldset: type.Fieldset) =>
        compileFieldsetData(fieldset, compiledData)
      if (Array.isArray(schemaItem))
        schemaItem.forEach(compileFieldsetDataCurry)
      else compileFieldsetDataCurry(schemaItem)
    })
  }
}
function compileFieldsetData(
  fieldset: type.Fieldset,
  compiledData: type.OutputData,
) {
  if (fieldset.schema_key) {
    compiledData[fieldset.schema_key] = extractData(fieldset)
  }
}

export const packageFormData = (state: type.Store): type.OutputData =>
  compileFormData(state, false).data

export const packageBasicConfigData = (
  state: type.Store,
): type.ConfigSummary => {
  const { data, changes } = compileFormData(state, true)
  return {
    'Basic Configurations': data,
    'Modified Sections': changes,
  }
}

export const packageConfig = (state: type.Store): type.ConfigPackage => ({
  config: compileFormData(state, false).data,
  summary: packageBasicConfigData(state),
  created_at: Date(),
})

export const compileFormData = (
  state: type.Store,
  forSummary: boolean = false,
) => {
  // trim arrays in schema to specified lengths before compiling data
  const trimmedform = trimArrays(state.form, state)
  const compiledData = compileform({
    ...state,
    form: trimmedform,
  })

  // extract unexpanded conditional pages & fieldsets
  let strippedData: type.OutputData = {}
  let changes: Array<string> = []
  trimmedform.pages
    .filter((page: type.Page) => page.hasOwnProperty('fieldsets'))
    .forEach((page: type.FormPage) => {
      const { fieldsets } = page
      if (isExpanded(page, compiledData)) {
        const fieldsetData = reduceFieldsetData(
          state.schema,
          fieldsets,
          compiledData,
          strippedData,
          changes,
          forSummary,
        )
        strippedData = fieldsetData.strippedData
        changes = fieldsetData.changes
      }
    })
  return { data: strippedData, changes }
}

const trimmed = str => typeof str === 'string' && str.trim()

function reduceFieldsetData(
  schema: type.ConfigSchema,
  fieldsets: type.Fieldsets,
  compiledData: type.OutputData,
  strippedData: type.OutputData,
  changes: Array<string>,
  forSummary: boolean,
) {
  fieldsets.forEach((fieldSchema: type.Group | type.Fieldset) => {
    if (Array.isArray(fieldSchema)) {
      const fieldsetData = reduceFieldsetData(
        schema,
        fieldSchema,
        compiledData,
        strippedData,
        changes,
        forSummary,
      )
      strippedData = fieldsetData.strippedData
      changes = fieldsetData.changes
    } else {
      if (fieldSchema.schema_key && isExpanded(fieldSchema, compiledData)) {
        const data = compiledData[fieldSchema.schema_key]
        if (!forSummary || (forSummary && fieldSchema._basic_config)) {
          const key = forSummary
            ? fieldSchema.title ||
              fieldSchema.description ||
              fieldSchema.schema_key
            : fieldSchema.schema_key
          strippedData[key] = data
        }
        if (
          forSummary &&
          fieldSchema._watch_for_changes &&
          type.isOutputData(data)
        ) {
          if (changesIn(fieldSchema, data)) {
            changes.push(
              typeof fieldSchema._watch_for_changes === 'string'
                ? fieldSchema._watch_for_changes
                : trimmed(fieldSchema.title) ||
                    trimmed(fieldSchema.description) ||
                    fieldSchema.schema_key,
            )
          }
        }
      }
    }
  })
  return { strippedData, changes }
}

function changesIn(schema: type.Fieldset, data: type.OutputData) {
  if (type.isArraySubschema(schema)) {
    for (let i = 0; i < schema.items.length; i++) {
      const dataElement = data[i]
      for (let [key, subSchema] of Object.entries(schema.items[i].properties)) {
        if (type.isSubschema(subSchema) && dataElement) {
          if (changesIn(subSchema, dataElement[key])) return true
        }
      }
    }
  } else if (schema.type === 'object') {
    for (let [key, subSchema] of Object.entries(schema.properties)) {
      const subPropData = data[key]
      if (type.isSubschema(subSchema) && type.isOutputData(subPropData)) {
        if (changesIn(subSchema, subPropData)) return true
      }
    }
  } else {
    return schema.__initial_default !== data
  }
  return false
}

function isExpanded(
  schema: type.FormPage | type.Fieldset,
  data: type.OutputData,
) {
  return schema.expand_if ? evaluateConditions(schema.expand_if, data) : true
}

export function evaluateConditions(
  expandIf: type.DynamicCondition,
  compiledData: type.OutputData,
) {
  const verdict = handleBoolOps(expandIf, condition =>
    checkCondition(condition, compiledData),
  )
  return verdict
}

const isTrue = x => x
const boolOpMap = {
  $and: operands => operands.every(isTrue),
  $or: operands => operands.some(isTrue),
}

function handleBoolOps(expand_if, evaluateCondition) {
  if (type.isBooleanOperation(expand_if)) {
    // need to cast to Operand pair to separate operands
    //   type safety is justified in isBooleanOperation > isOperandSet
    const [operator, operandSet] = Object.entries(expand_if)[0] as [
      string,
      type.OperandSet,
    ]
    const operands = Object.entries(operandSet)
      .map(([key, value]) => ({ [key]: value }))
      .map(operand => handleBoolOps(operand, evaluateCondition))
    return boolOpMap[operator](operands)
  } else {
    return evaluateCondition(expand_if)
  }
}

function checkCondition(condition, compiledData) {
  let nestedCondition = condition
  let subSchema = compiledData
  let matches = true
  let furtherNesting = true
  while (matches && furtherNesting) {
    if (typeof nestedCondition === 'object') {
      const properties = Object.entries(nestedCondition)
      if (properties.length !== 1)
        throw `Error (expand_if) - expand_if may only contain one property per nested level. (invalid value = ${JSON.stringify(
          condition,
        )})`
      const [key, value] = properties[0]
      if (type.isObjectPointer(value) && Object.keys(subSchema).includes(key)) {
        nestedCondition = value
        subSchema = subSchema[key] as type.OutputData
      } else matches = false
    } else {
      furtherNesting = false
      if (nestedCondition !== subSchema) matches = false
    }
  }
  return matches
}

export const expandArrays = (schema: type.JSONSchema): type.FormConfig => {
  const expandedSchema = deepClone({}, schema)
  recurseThrough(expandedSchema, (arraySchema: type.JSONSchema) => {
    arraySchema.items = Array.from(
      { length: arraySchema.max_items_length },
      () => deepClone({}, arraySchema.items),
    )
  })
  return expandedSchema
}
export const trimArrays = (
  schema: type.FormConfig | type.Fieldset,
  state: type.Store,
) => {
  const compiledData = compileform(state)
  const trimmedSchema = deepClone({}, schema)
  recurseThrough(trimmedSchema, (arraySchema: type.ArraySubschema) => {
    if (arraySchema.items) {
      arraySchema.items.splice(
        typeof arraySchema.items_length === 'number'
          ? arraySchema.items_length
          : extractValue(compiledData, arraySchema.items_length),
      )
    }
  })
  return trimmedSchema
}

export function extractValue(form: type.OutputData, propertyChain: string) {
  const keys = propertyChain.split('.')
  let data = form
  for (let i = 0; i < keys.length; i++) {
    if (typeof data === 'object' && data.hasOwnProperty(keys[i])) {
      const nestedData = data[keys[i]]
      if (type.isOutputData(nestedData)) data = nestedData
    } else return
  }
  return data
}
function recurseThrough(
  schema: type.FormConfig,
  cb: (arraySchema: type.ArraySubschema) => void,
) {
  const curryRecurseThrough = (schema: any) => recurseThrough(schema, cb)
  if (schema === undefined || schema === null) {
    /* do nothing */
  } else if (schema.type === 'array') {
    if (type.isDynamicArraySubschema(schema)) {
      cb(schema)
    } else if (schema.items && schema.items.properties) {
      forPropsIn(schema.items.properties, curryRecurseThrough)
    }
  } else if (schema.type === 'object') {
    forPropsIn(schema.properties, curryRecurseThrough)
  } else if (Array.isArray(schema)) {
    schema.forEach(curryRecurseThrough)
  } else if (typeof schema === 'object') {
    forPropsIn(schema, curryRecurseThrough)
  }
}
function forPropsIn(object: type.NestedObj, cb: (key: string) => void) {
  const propKeys = Object.keys(object)
  propKeys.forEach((key: any) => cb(object[key]))
}
