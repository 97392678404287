// COMPONENTS
import React from 'react'
// TYPES
import * as type from '../type'
// STYLES
import styled from '@emotion/styled'
import * as colors from './colors'
import * as misc from './misc'

type StrikethruHeadingProps = {
  title: string
  style?: type.CSSObject
}
type StrikethruHeaderContainerProps = {
  positionStyles?: type.CSSObject
}

export const WidthSetter = styled('div')({
  // minWidth: '750px', // might bring in later
  margin: '0 4.5rem',
})

export const primaryButtonStyles = {
  border: `1px solid ${colors.grayBackground.backgroundColor}`,
  borderRadius: '0.45rem',
  padding: '0.3rem 1.5rem',
  backgroundColor: colors.blue,
  ...colors.whiteText,
}

export const linkStyles = {
  color: colors.blue,
  textDecoration: 'underline',
  '&:focus': {
    outline: '1px solid black',
  },
  border: '0',
  background: 'inherit',
  fontSize: '1.5rem',
}

const StrikethruHeaderContainer = styled('div')<StrikethruHeaderContainerProps>(
  {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  props => props.positionStyles,
)
export const HeaderLine = styled('div')({
  // creates strike-thru lines in header
  boxSizing: 'border-box',
  height: '1.2px',
  ...misc.spaceRight,
  ...colors.grayBackground,
})
export const HeaderLineLeft = styled(HeaderLine)({
  width: '1.5rem',
})
const StyledTitle = styled('h3')({
  ...misc.spaceRight,
  fontSize: '2.4rem',
  fontWeight: 'normal',
})
export const HeaderLineRight = styled(HeaderLine)({
  flexGrow: 1,
})
export const StrikethruHeading: React.SFC<StrikethruHeadingProps> = props => {
  const { title, style } = props
  return (
    <StrikethruHeaderContainer positionStyles={style}>
      <HeaderLineLeft />
      <StyledTitle>{title}</StyledTitle>
      <HeaderLineRight />
    </StrikethruHeaderContainer>
  )
}

const UnstyledCheckbox = () => <input type="checkbox" />
export const Checkbox = styled(UnstyledCheckbox)({})

export const RedText = styled('span')({
  color: 'red',
})

export const LinkIcon = styled('span')({
  margin: '0 0.38rem',
  display: 'inline-block',
  textDecoration: 'none',
})
export const LinkDownloadIcon = () => <LinkIcon>{'\u2193'}</LinkIcon>

export const DevBlob = styled('div')({
  minHeight: '3rem',
  minWidth: '3rem',
  background: '#999',
})

export const OrangeH2 = styled('h2')({
  border: 'none',
  borderBottom: '4px solid orangered',
  padding: '4px',
  fontFamily: 'Helvetica Neue',
  fontWeight: 'bold',
})

export const OrangeH3 = styled('h3')({
  border: 'none',
  borderBottom: '3px solid orangered',
  padding: '0 4px',
  fontFamily: 'Helvetica Neue',
  fontWeight: 'bold',
})
