// COMPONENTS
import React, { useRef, useEffect, ReactElement } from 'react'
import SubForm from 'react-jsonschema-form'
import CustomObjectFieldTemplate from './CustomObjectFieldTemplate'
import CustomArrayFieldTemplate from './CustomArrayFieldTemplate'
import PagingButtons from './PagingButtons'
// STATE
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  setPopulationDatasetsInSchema,
  updateData,
  shipData,
} from '../../store/actions'
import selectors from '../../store/selectors'
// UTILS
import { trimArrays } from '../../utils/form'
// TYPES
import * as type from '../../type'
import { RouteComponentProps } from 'react-router'
import { RJSFSubmitData } from './FormPage'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = type.BuiltinPageConfig &
  type.PageButtonPaths &
  StateProps &
  DispatchProps &
  RouteComponentProps & { schema: type.Fieldset }

type FormRef = React.MutableRefObject<ReactElement> & {
  submit: () => void
  state: {
    errors: Array<any>
  }
}

const FormDatasetPicker: React.SFC<Props> = ({
  title,
  message,
  schema, // contains array of fieldsets
  populationDatasets,
  state,
  prevPagePath,
  nextPagePath,
  setPopulationDatasetsInSchema,
  updateData,
  handleSubmit,
}) => {
  let subFormRefs = {}
  schema.forEach(fieldset => {
    subFormRefs[fieldset.layout_key] = useRef()
  })
  useEffect(() => {
    const datasetPickerField = schema.filter(
      fieldset => fieldset.schema_key === 'population_dataset_uuid',
    )
    setPopulationDatasetsInSchema(datasetPickerField[0].layout_key)
  }, [])

  const handleChange = layout_key => {
    if (subFormRefs[layout_key].current) {
      ;((subFormRefs[layout_key].current as unknown) as FormRef).submit()
    }
  }
  const handleData = ({ formData, schema }: RJSFSubmitData) => {
    updateData(schema.layout_key, formData)
  }
  const renderSubForm = (schema: type.Fieldset, i: number) => {
    const expanded = schema.expand_if
      ? selectors.evaluateConditionsFromState(schema.expand_if)
      : true
    schema = trimArrays(schema, state)
    const layout_key = schema.layout_key
    return expanded && layout_key ? (
      <SubForm
        schema={schema}
        uiSchema={{}}
        ArrayFieldTemplate={CustomArrayFieldTemplate}
        ObjectFieldTemplate={CustomObjectFieldTemplate}
        onChange={() => handleChange(schema.layout_key)}
        onSubmit={handleData}
        idPrefix={schema.schema_key}
        showErrorList={false}
        ref={subFormRefs[layout_key]}
        key={i}>
        <div style={{}} />
      </SubForm>
    ) : null
  }

  return (
    <div className="summary-display">
      <h1>{title || 'New Form'}</h1>
      <p>{message || ''}</p>
      {schema.map((fieldset, i) => renderSubForm(fieldset, i))}
      <PagingButtons
        {...{
          nextPagePath,
          prevPagePath,
          handleSubmit,
          contextualButtons: true,
          form_submit_button: false,
        }}
      />
    </div>
  )
}

const mapStateToProps = (store: type.Store) => ({
  populationDatasets: store.populationDatasets,
  state: store,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  updateData: (storeKey: string, form: type.OutputData) =>
    dispatch(updateData(storeKey, form)),
  handleSubmit: () => dispatch(shipData()),
  setPopulationDatasetsInSchema: layout_key => {
    dispatch(setPopulationDatasetsInSchema(layout_key))
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormDatasetPicker),
)
