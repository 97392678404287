// USWDS compliant accordion of items with variable Components

import React from 'react'
import shortid from 'shortid'

type Props = {
  items: Array<Item>
}
type Item = {
  headingText: string
  contentProps: any
  Component: React.ComponentType<any>
}

const USWDSVariableAccordion: React.SFC<Props> = ({ items }) => (
  <ul className="usa-accordion">
    {items.map(({ headingText, contentProps, Component }, i: number) => {
      const contentId = `usa-accordion-item-${shortid.generate()}`
      return (
        <li key={i}>
          <button
            className="usa-accordion-button"
            aria-expanded={false}
            aria-controls={contentId}>
            {headingText}
          </button>
          <div
            id={contentId}
            className="usa-accordion-content"
            aria-hidden={false}>
            <Component {...contentProps} />
          </div>
        </li>
      )
    })}
  </ul>
)

export default USWDSVariableAccordion
