import * as type from '../type'

export const summaryHeadingsMap = (): type.ScenarioDashboardColumnHeadings => ({
  created_at: 'Created',
  diabetes_type: 'Diabetes Type',
  scenario_name: 'Scenario Name',
  simulation_type: 'Type of Run',
  custom_population: 'Custom Population',
  size: 'Population Size',
  intervention_count: '# of Iterations',
  simulation_status: 'Status',
  uuid: 'Actions',
})

export const diabetesTypeMap = (): type.StringMap => ({
  t1d: 'Type 1',
  t2d: 'Type 2',
  pre: 'Prediabetes',
  screen: 'Screening',
})

const numSort = (key, descending) => (a, b) =>
  descending ? b[key] - a[key] : a[key] - b[key]
const strSort = (key, descending) => (a, b) => {
  let output = 0
  a = a[key].toUpperCase()
  b = b[key].toUpperCase()
  if (a < b) output = 1
  else if (a > b) output = -1
  if (descending) output = -output
  return output
}
const dateSort = (key, descending) => (a, b) => {
  a = new Date(a[key])
  b = new Date(b[key])
  return descending ? b - a : a - b
}

export const compareFunctions = () => ({
  created_at: dateSort,
  diabetes_type: strSort,
  scenario_name: strSort,
  simulation_type: strSort,
  custom_population: strSort,
  size: numSort,
  intervention_count: numSort,
  simulation_status: strSort,
  uuid: strSort,
})

export const getMonth = (i: number): string =>
  [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ][i]

export const abbreviateDate = (date: string): string => {
  const d = new Date(date)
  return `${d.getDate()} ${getMonth(d.getMonth())} ${d.getFullYear()}`
}

export const abbreviateTime = (date: string): string => {
  const d = new Date(date)
  let h = d.getHours()
  let xm = 'AM'
  if (h >= 12) {
    h -= 12
    xm = 'PM'
  }
  return `${h || '12'}:${String(d.getMinutes()).padStart(2, '0')} ${xm}`
}

export const computedGridStyles = (
  headings: type.ScenarioDashboardColumnHeadings,
  rows: type.DashboardSummaries,
) => {
  const columnCount = Object.keys(headings).length
  const rowCount = rows.length + 1
  return {
    gridTemplateColumns: `repeat(${columnCount}, auto)`,
    gridTemplateRows: `repeat(${rowCount}, auto)`,
  }
}
