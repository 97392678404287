import * as api from '../api'
import {
  clearDataState,
  saveDataState,
  clearResultsState,
  clearSchemaState,
} from '../utils/sessionStorage'
import { getSchema } from '../utils/schema'
import { loadDefaults } from '../utils/form'
import utils from '../utils'
import deepClone from 'object-assign-deep'
import * as type from '../type'
import { saveAs } from 'file-saver'
// import mockSchema from './mock-data/datasetPicker_schema.json'

export enum ACTION {
  SET_FORM_STORE,
  SET_FORM_CONFIG,
  SET_FORM_DATA,
  RESET_FORM_DATA,
  UPDATE_FORM_DATA,
  SET_FORM_DEFAULTS,

  GET_NOTIFICATIONS,

  GET_POPULATION_DATASETS,
  SET_POPULATION_DATASETS,

  GET_CONFIG_FILES,

  SET_SCENARIO_PORTFOLIO,
  UPDATE_PORTFOLIO,
  UPDATE_PORTFOLIO_ITEM,
  UPDATE_PORTFOLIO_ITEM_STATUS,

  SET_RESULTS,
  RESET_RESULTS,
  REGISTER_USER,

  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_USER_ACCESS,
}

/* SCHEMA */

const fetchSchema = async (schema, form, loadedData?: type.OutputData) => {
  const response: type.JSONSchema = await getSchema()
  // const response = mockSchema as type.JSONSchema

  // add data paths to fieldsets & deref schema
  const { schema: layoutAugmentedSchema, keyMap } = utils.schema.augmentLayout(
    response,
  )
  const augmentedSchema = utils.schema.augmentProperties(layoutAugmentedSchema)

  schema = utils.schema.derefSchema(augmentedSchema)
  form = getformFromSchema(schema)
  if (loadedData) form = loadDefaults(form, keyMap, loadedData)

  if (type.isConfigSchema(schema)) utils.sessionStorage.saveSchemaState(schema)
  utils.sessionStorage.saveDataState(form)
  return { form, schema }
}

/**
 *
 * @param loadedData calling with loaded data will completely reset schema and form data
 */
export const initSchema = (
  loadedData: type.OutputData | undefined,
): type.ThunkResult<Promise<void>> => async dispatch => {
  let schema, form

  if (loadedData) {
    const fetched = await fetchSchema(schema, form, loadedData)
    schema = fetched.schema
    form = fetched.form
  } else {
    const savedSchema: type.ConfigSchema = utils.sessionStorage.loadSchemaState()
    const savedForm: type.FormConfig = utils.sessionStorage.loadDataState()
    if (savedSchema) {
      schema = savedSchema
      form = savedForm
    } else {
      const fetched = await fetchSchema(schema, form)
      schema = fetched.schema
      form = fetched.form
    }
  }

  dispatch({
    type: ACTION.SET_FORM_STORE,
    form,
    schema,
  })
}

export const setPopulationDatasetsInSchema = (
  layout_key: string,
): type.ThunkResult<void> => async (dispatch, getState) => {
  await dispatch(await loadPopulationDatasets())
  const { schema, form, populationDatasets } = getState()
  const { nullValue } = schema.properties.population_dataset_uuid

  let enumValues: Array<string> = [nullValue]
  let enumNames: Array<string> = [nullValue]
  populationDatasets.forEach(dataset => {
    enumValues.push(dataset.uuid)
    enumNames.push(
      `${dataset.filename} (${dataset.type}) -- ${dataset.description}`,
    )
  })

  enumValues = enumValues.map(String)
  enumNames = enumNames.map(String)

  /* population dataset enums must be set in three locations:
       schema props, schema pages, and form pages */
  const newSchema = deepClone({}, schema)
  newSchema.properties.population_dataset_uuid.enum = enumValues
  newSchema.properties.population_dataset_uuid.enumNames = enumNames

  const schemaFieldset = utils.form.getFieldsetState(
    newSchema['ui:layout'],
    layout_key.split('.'),
  )
  schemaFieldset.enum = enumValues
  schemaFieldset.enumNames = enumNames

  const newForm = deepClone({}, form)
  const formFieldset = utils.form.getFieldsetState(
    newForm,
    layout_key.split('.'),
  )
  formFieldset.enum = enumValues
  formFieldset.enumNames = enumNames
  dispatch({
    type: ACTION.SET_POPULATION_DATASETS,
    schema: newSchema,
    form: newForm,
  })
}

/* FORM */

const getformFromSchema = (schema: any): type.FormConfig => {
  const form = deepClone({}, schema['ui:layout'])
  return utils.form.expandArrays(form)
}

export const resetData = (): type.ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
) => {
  await Promise.all([() => clearDataState(), () => clearResultsState()])
  const form = getformFromSchema(getState().schema)
  dispatch({
    type: ACTION.SET_FORM_DATA,
    payload: form,
  })
  saveDataState(form)
}

export const updateData = (
  layoutKey: string,
  newFormData: type.OutputData,
): type.ThunkResult<void> => (dispatch, getState) => {
  dispatch({
    type: ACTION.UPDATE_FORM_DATA,
    payload: {
      layoutKey: layoutKey.split('.'),
      newFormData,
    },
  })
  saveDataState(getState().form)
}

export const shipData = (
  savedConfig?: type.ConfigJSON,
): type.ThunkResult<void> => (dispatch, getState) => {
  // if running a saved config file, the savedConfig parameter will be present
  const store = getState()
  let data
  if (savedConfig) {
    data = savedConfig
  } else {
    data = utils.form.packageConfig(store)
  }
  api.sendSimulationConfig(data.config).then(({ content, ok }) => {
    if (ok) {
      dispatch({
        type: ACTION.SET_RESULTS,
        payload: {
          status: 'Sending Configuration',
          errorMessage: '',
          configJSON: data,
        },
      })
      dispatch(saveConfig(content, data))
      dispatch(saveConfigOnRun(data))
      const shippedData = true
      dispatch(monitorStatus(content, shippedData))
    } else
      dispatch({
        type: ACTION.SET_RESULTS,
        payload: {
          status: 'Error',
          errorMessage: content,
          configJSON: data,
        },
      })
  })
}

/* RESULTS */

export const monitorStatus = (
  uuid: string,
  shippedData: boolean = false,
  forPortfolio: boolean = false,
): type.ThunkResult<Promise<Function>> => async (dispatch, getState) => {
  const interval = shippedData ? 500 : 2500
  const monitorRemover = api.setStatusMonitor(
    checkStatus,
    interval,
    shippedData,
  )
  const removeMonitor = () => {
    shippedData ? api.clearStatusMonitor() : monitorRemover()
  }
  return removeMonitor

  async function checkStatus() {
    const response = await api.fetchStatus(uuid)
    if (response) {
      if (response.message === api.successMessage) {
        removeMonitor()
        if (forPortfolio) {
          const portfolioItem = await loadPortfolioItem(
            dispatch,
            getState,
            uuid,
            response,
          )
          dispatch({
            type: ACTION.UPDATE_PORTFOLIO_ITEM,
            payload: {
              uuid,
              portfolioItem,
            },
          })
        } else {
          dispatch(fetchResults(uuid, response))
        }
      } else if (response.message === api.failureMessage) {
        removeMonitor()
        dispatch({
          type: ACTION.SET_RESULTS,
          payload: {
            status: response.message,
            errorMessage: 'An unknown problem has occurred in the simulation.',
          },
        })
        dispatch({
          type: ACTION.UPDATE_PORTFOLIO_ITEM_STATUS,
          payload: {
            uuid,
            simulation_status: response.message,
          },
        })
      } else if (response.message !== getState().results.status) {
        dispatch({
          type: ACTION.SET_RESULTS,
          payload: {
            status: response.message,
            errorMessage: '',
          },
        })
        dispatch({
          type: ACTION.UPDATE_PORTFOLIO_ITEM_STATUS,
          payload: {
            uuid,
            simulation_status: response.message,
          },
        })
      }
    }
  }
}

export const fetchResults = (
  uuid: string,
  status?: string,
): type.ThunkResult<Promise<void>> => async dispatch => {
  if (!status) {
    status = await api.fetchStatus(uuid)
  }
  dispatch(savePopulationOnRun(uuid))
  const [
    cost,
    prevalence,
    mean_prevalence,
    incidence,
    cea,
    mean_cea,
    ppc,
    cost_raw,
    prevalence_raw,
    mean_prevalence_raw,
    incidence_raw,
    cea_raw,
    mean_cea_raw,
    ppc_raw,
    configPackage,
  ] = await Promise.all([
    api.fetchCost(uuid),
    api.fetchPrevalence(uuid),
    api.fetchMeanPrevalence(uuid),
    api.fetchIncidence(uuid),
    api.fetchCEA(uuid),
    api.fetchMeanCEA(uuid),
    api.fetchPerPersonCost(uuid),
    api.fetchCostRaw(uuid),
    api.fetchPrevalenceRaw(uuid),
    api.fetchMeanPrevalenceRaw(uuid),
    api.fetchIncidenceRaw(uuid),
    api.fetchCEARaw(uuid),
    api.fetchMeanCEA(uuid),
    api.fetchPerPersonCost(uuid),
    dispatch(loadConfig(uuid)),
  ])

  if (type.isStatusResponse(status) && status.message === api.successMessage) {
    const results = {
      status: status.message,
      cost,
      prevalence,
      mean_prevalence,
      incidence,
      cea,
      mean_cea,
      ppc,
      cost_raw,
      prevalence_raw,
      mean_prevalence_raw,
      incidence_raw,
      cea_raw,
      mean_cea_raw,
      ppc_raw,
      errorMessage: '',
      configJSON: configPackage,
    }
    dispatch({
      type: ACTION.SET_RESULTS,
      payload: results,
    })
  }
}

export const savePopulationOnRun = (
  uuid: string,
): type.ThunkResult<Promise<void>> => async (dispatch, getState) =>
  withAuth(dispatch, getState, auth => api.fetchPopulation(uuid, auth.access))

export const endWait = (): type.ThunkResult<void> => (dispatch, getState) => {
  if (getState().results.status !== 'Run Complete') {
    api.clearStatusMonitor()
    dispatch({
      type: ACTION.RESET_RESULTS,
    })
  }
}

/* Config actions for Scenario runs/saves - not config file dashboard */

export const saveConfig = (
  uuid: string,
  configPackage: type.ConfigPackage,
): type.ThunkResult<Promise<void>> => async (dispatch, getState) =>
  withAuth(dispatch, getState, auth =>
    api.saveConfigPackage(uuid, configPackage, auth.access),
  ).then(response => {
    if (
      type.isAuthorizedSaveConfigResults(response) &&
      response.response !== 'New Schema Saved'
    )
      throw 'Simulation config save unsuccessful.'
  })

export const loadConfig = (
  uuid: string,
): type.ThunkResult<Promise<type.ConfigPackage>> => async (
  dispatch,
  getState,
) =>
  withAuth(dispatch, getState, auth =>
    api.loadConfigPackage(uuid, auth.access),
  ).then(response => {
    if (type.isConfigPackage(response)) {
      return response
    } else throw 'Simulation config load unsuccessful.'
  })

export const getConfigIds = (): type.ThunkResult<Promise<
  type.ConfigIds
>> => async (dispatch, getState) =>
  withAuth(dispatch, getState, auth => api.getConfigIds(auth.access)).then(
    response => {
      if (type.isConfigIds(response)) {
        return response
      } else throw 'Simulation config load unsuccessful.'
    },
  )

export const loadPortfolioItem = async (
  dispatch,
  getState,
  uuid: string,
  status?: type.StatusResponse,
) => {
  const { config, summary, created_at } = await withAuth(
    dispatch,
    getState,
    auth => api.loadConfigPackage(uuid, auth.access),
  )
  status = status || (await api.fetchStatus(uuid))
  return {
    uuid,
    created_at,
    diabetes_type: utils.portfolio.diabetesTypeMap()[config.diabetes_type],
    scenario_name: summary['Basic Configurations']['Name of the scenario'],
    simulation_type:
      summary['Basic Configurations']['Type of simulation to run'],
    custom_population: summary['Modified Sections'].includes(
      'population_composition',
    )
      ? 'Yes'
      : 'No',
    size:
      summary['Basic Configurations'][
        'Number of individuals in the population'
      ],
    intervention_count: summary['Basic Configurations']['Number of iterations'],
    simulation_status: type.isStatusResponse(status) ? status.message : 'Error',
  }
}

export const sortPortfolio = (
  key: string,
  descending: boolean,
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const { rows } = getState().portfolio
  const compareFunc = utils.portfolio.compareFunctions()
  const sortedRows = deepClone([], rows).sort(compareFunc[key](key, descending))
  dispatch({
    type: ACTION.UPDATE_PORTFOLIO,
    payload: { rows: sortedRows },
  })
}

export const loadConfigList = (): type.ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const configIds = await dispatch(getConfigIds())
  if (configIds.length !== getState().portfolio.rows.length) {
    const scenarios = await Promise.all(
      configIds.map(uuid => loadPortfolioItem(dispatch, getState, uuid)),
    )
    dispatch({
      type: ACTION.SET_SCENARIO_PORTFOLIO,
      payload: {
        headings: utils.portfolio.summaryHeadingsMap(),
        rows: scenarios,
      },
    })
  }
}

export const deleteScenario = (
  uuid: string,
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const result = await withAuth(dispatch, getState, async auth => {
    try {
      await api.deleteConfig(auth.access, uuid)
      return true
    } catch (err) {
      console.log({ err })
      return { code: 'token_not_valid', detail: '', messages: '' }
    }
  })
  if (result) {
    dispatch({
      type: ACTION.UPDATE_PORTFOLIO,
      payload: {
        rows: getState().portfolio.rows.filter(
          scenario => scenario.uuid !== uuid,
        ),
      },
    })
  } else throw 'failed to delete'
}
export const mockNotifications = [
  {
    uuid: 'dfjslkjfkwejovij',
    timestamp: 'Mon Nov 1 14:54:16 2021 -0700',
    type: 'DATASET_SHARED', // actual format for shared dataset notifications
    data: {
      uuid: 'oneqwertyuiop', // (pop dataset id) <string>
    },
  },
  {
    uuid: 'twordtyfgvhj', // (notification id)
    timestamp: 'Mon Nov 1 14:54:16 2021 -0700',
    type: 'DATASET_SHARED', // actual format for shared dataset notifications
    data: {
      uuid: 'twoqwertyuiop', // (pop dataset id) <string>
    },
  },
  {
    uuid: 'threerdtyfgvhj', // (notification id)
    timestamp: 'Mon Nov 1 14:54:16 2021 -0700',
    type: 'DATASET_SHARED', // actual format for shared dataset notifications
    data: {
      uuid: 'threeqwertyuiop', // (pop dataset id) <string>
    },
  },
]
export const loadNotifications = (): type.ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const response = await withAuth(dispatch, getState, auth =>
    api.getNotifications(auth.access),
  )
  dispatch({
    type: ACTION.GET_NOTIFICATIONS,
    payload: response,
  })
}

export const deleteNotification = (
  uuid,
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const response = await withAuth(dispatch, getState, auth =>
    api.deleteNotification(uuid, auth.access),
  )
  if (response.ok) {
    dispatch(loadNotifications())
  }
}

/* Population Datasets */

export const loadPopulationDatasets = (): type.ThunkResult<Promise<
  void
>> => async (dispatch, getState) => {
  const response = await withAuth(dispatch, getState, auth =>
    api.getPopulationDatasets(auth.access),
  )
  // const response = mockPopulationDatasets
  dispatch({
    type: ACTION.GET_POPULATION_DATASETS,
    payload: response,
  })
}

export const uploadPopulationDataset = (
  file: File,
  description: string,
  type: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('description', description)
  formData.append('type', type)
  await withAuth(dispatch, getState, async auth => {
    const response = await api.uploadPopulationDataset(formData, auth.access)
    cb(response)
    return response
  })
}

export const downloadPopulationDataset = (
  uuid: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await withAuth(dispatch, getState, async auth => {
    // response is unhandled
    const response = await api.downloadPopulationDataset(uuid, auth.access)
    if (response.ok) {
      // download file
      const dispositionHeader = response.headers.get('Content-Disposition')
      const dispositionValue =
        dispositionHeader &&
        // @ts-ignore: value is not undefined
        dispositionHeader
          .split(';')
          .find(n => n.includes('filename='))
          .replace('filename=', '')
          .replace(/"/g, '')
          .trim()

      const filename = dispositionHeader
        ? dispositionValue
        : 'population_dataset.csv'

      const blob = await response.blob()
      saveAs(blob, filename)
      return
    }
    const text = await response.text()
    if (text.includes('FileNotFoundError')) {
      alert('Error: file no longer exists on server. Please delete.')
      return
    }
    const json = JSON.parse(text)
    cb(json)
    return json
  })
}

export const editPopulationDataset = (
  uuid: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await withAuth(dispatch, getState, async auth => {
    await dispatch(await loadPopulationDatasets()) // work around for refreshing auth
    auth = getState().auth
    const description = prompt(
      'Please enter the updated description of this population dataset.',
    )
    if (description) {
      const response = await api.editPopulationDataset(
        uuid,
        description,
        auth.access,
      )
      dispatch(loadPopulationDatasets())
      cb(response)
      // no need to return response since work around above ensures token is valid
    }
  })
}

export const sharePopulationDataset = (
  uuid: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await withAuth(dispatch, getState, async auth => {
    await dispatch(await loadPopulationDatasets()) // work around for refreshing auth
    auth = getState().auth
    const receiver = prompt(
      'Please enter the email address of the user you would like to share this to.',
    )
    if (receiver) {
      const response = await api.sharePopulationDataset(
        uuid,
        receiver,
        auth.access,
      )
      cb(response)
      // no need to return response since work around above ensures token is valid
    }
  })
}

export const deletePopulationDataset = (
  uuid: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await withAuth(dispatch, getState, async auth => {
    // response is unhandled
    const response = await api.deletePopulationDataset(uuid, auth.access)
    if (response.ok) {
      dispatch(loadPopulationDatasets())
    }
    const text = await response.text()
    return text ? JSON.parse(text) : {}
  })
}

/* CONFIG FILES */

export const loadConfigFiles = (): type.ThunkResult<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const response = await withAuth(dispatch, getState, auth =>
    api.getConfigFiles(auth.access),
  )
  dispatch({
    type: ACTION.GET_CONFIG_FILES,
    payload: response,
  })
}

export const uploadConfigFile = (
  description: string,
  configJSON: string,
  filename: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const formData = new FormData()
  formData.append('configJSON', configJSON)
  formData.append('description', description)
  formData.append('filename', filename)
  await withAuth(dispatch, getState, async auth => {
    const response = await api.uploadConfigFile(formData, auth.access)
    cb(response)
  })
}

export const saveConfigOnRun = (
  configJSON: string,
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const formData = new FormData()
  formData.append('configJSON', JSON.stringify(configJSON))
  await withAuth(dispatch, getState, async auth => {
    await api.saveConfigOnRun(formData, auth.access)
  })
}

export const shareConfigFile = (
  uuid: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await withAuth(dispatch, getState, async auth => {
    await dispatch(await loadConfigFiles()) // work around for refreshing auth
    auth = getState().auth
    const receiver = prompt(
      'Please enter the email address of the user you would like to share this to.',
    )
    if (receiver) {
      const response = await api.shareConfigFile(uuid, receiver, auth.access)
      cb(response)
    }
  })
}

export const deleteConfigFile = (
  uuid: string,
  cb = response => {},
): type.ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await withAuth(dispatch, getState, async auth => {
    // response is unhandled
    const response = await api.deleteConfigFile(uuid, auth.access)
    if (response.ok) {
      dispatch(loadConfigFiles())
    }
    const text = await response.text()
    return text ? JSON.parse(text) : {}
  })
}

/* AUTH */

export const withAuth = async (dispatch, getState, cb) => {
  const { auth } = getState()
  if (!auth.access || !auth.refresh) dispatch(logout())
  else {
    let response = await cb(auth)
    if (
      type.isUnauthorizedFetchResults(response) &&
      response.code === 'token_not_valid'
    ) {
      const success = await dispatch(refreshUser())
      if (!success) dispatch(logout())
      else {
        const { access: refreshed_token } = getState().auth
        if (refreshed_token) {
          return cb(getState().auth)
        }
      }
    } else return response
  }
}

export const logout = (): type.ThunkResult<void> => dispatch => {
  dispatch({ type: ACTION.LOGOUT_USER })
}

export const login = (
  credentials: type.LoginCredentials,
): type.ThunkResult<Promise<{
  status: boolean
  message?: String
}>> => async dispatch => {
  const response = await api.loginUser(credentials)
  if (type.isAuthState(response)) {
    dispatch({
      type: ACTION.LOGIN_USER,
      payload: response,
    })
    dispatch(loadConfigList())
    dispatch(loadPopulationDatasets())
    dispatch(loadConfigFiles())
    clearSchemaState() // remove schema from sessionsStorage
    dispatch(initSchema(undefined))
    return { status: true }
  } else {
    return { status: false, message: response.detail }
  }
}

export const refreshUser = (): type.ThunkResult<Promise<boolean>> => async (
  dispatch,
  getState,
) => {
  const { refresh } = getState().auth
  if (refresh) {
    const response = await api.refreshUser({ refresh })

    if (type.isJwtAccessToken(response)) {
      const { access } = response
      dispatch({
        type: ACTION.REFRESH_USER_ACCESS,
        payload: { access },
      })
      return true
    }
  }
  dispatch({ type: ACTION.LOGOUT_USER })
  return false
}

export const registerUser = (
  credentials: type.RegistrationCredentials,
): type.ThunkResult<Promise<{
  status: boolean
  message?: String
}>> => async dispatch => {
  const response = await api.registerUser(credentials)
  if (type.isRegistrationSuccessResults(response)) {
    const { username, password } = credentials
    const loginAttempt = await dispatch(
      login({
        username,
        password,
      }),
    )
    return { status: loginAttempt.status }
  }
  return { status: false, message: Object.values(response)[0].toString() }
}
