import * as type from '../../type'
import { extractValue } from '../../utils/form'

export const getFieldType = (uiSchema: type.UISchema) => {
  switch (typeof uiSchema['ui:field_type']) {
    case 'object':
      return uiSchema['ui:field_type'].type
    case 'string':
      return uiSchema['ui:field_type']
    default:
      return 'default'
  }
}

/**
 * If field_type is contains a fixed column configuration, return that value
 * @param uiSchema
 */
export const getColumnCount = (uiSchema: type.UISchema): number | undefined => {
  if (typeof uiSchema['ui:field_type'] === 'object') {
    return uiSchema['ui:field_type'].columns
  }
  return
}

export const getGridTemplateColumns = (
  uiSchema: type.UISchema,
): string | undefined => {
  if (typeof uiSchema['ui:field_type'] === 'object') {
    return uiSchema['ui:field_type'].gridTemplateColumns
  }
  return
}

export const structureTable = (
  properties: Array<any>,
  columnCount: number = properties.length,
) => {
  const headings = properties.slice(0, columnCount)
  const rows: Array<any> = []
  for (
    let cursor = columnCount;
    cursor < properties.length;
    cursor += columnCount
  ) {
    rows.push(properties.slice(cursor, cursor + columnCount))
  }

  return { headings, rows }
}

const comparisonOperatorPattern = /[=<>]/
const arithmeticOperatorPattern = /[\/\*+-]/
const variablePattern = /^\$\./
const getPropChainFrom = variable => variable.replace(variablePattern, '')
const isNumeric = value => !isNaN(value)

const latestIn = (list: Array<any>) => list[list.length - 1]

const evaluateArithmetic = (a, b, operator) => {
  if (a === null) return b
  switch (operator) {
    case '/':
      return a / b
    case '*':
      return a * b
    case '+':
      return a + b
    case '-':
      return a - b
  }
}
const evaluateComparison = (a, b, operator) => {
  switch (operator) {
    case '>':
      return a > b
    case '<':
      return b > a
    case '=':
      return a == b
    default:
      return false
  }
}

const parseExpression = (
  expression,
  formData,
): [number | null, Array<string>] => {
  expression = expression.split(' ')
  const variables: Array<string> = []
  let operators: Array<string> = []
  let value: number | null = null
  expression.forEach(word => {
    if (arithmeticOperatorPattern.test(word)) {
      operators.push(word)
    } else if (variablePattern.test(word)) {
      variables.push(word)
      const propertyChain = getPropChainFrom(word)
      const termValue = extractValue(formData, propertyChain)
      value = evaluateArithmetic(value, termValue, latestIn(operators))
    } else if (isNumeric(word)) {
      const termValue = parseFloat(word)
      value = evaluateArithmetic(value, termValue, latestIn(operators))
    } else
      throw `Error: invalid "validation" field:\n  ${word}\n  in\n  ${expression}\n  is not a valid operator, variable, or number.`
  })
  return [value, variables]
}

const addError = (errorMessage, errors) => propertyChain => {
  const keys = propertyChain.split('.')
  let property = errors
  for (let i = 0; i < keys.length; i++) {
    property = property[keys[i]]
  }
  property.addError(errorMessage)
}

const extendErrors = (errors, invalidVariables, errorMessage) => {
  invalidVariables.map(getPropChainFrom).forEach(addError(errorMessage, errors))
  return errors
}

export const handleMultiVariableValidation = (formData, conditions, errors) => {
  conditions.forEach(condition => {
    let errorMessage
    if (typeof condition === 'string')
      errorMessage = `Failed condition: ${condition}`
    else {
      errorMessage = condition.error_message
      condition = condition.condition
    }
    let comparisonOperator = condition.match(comparisonOperatorPattern)[0]
    const [leftExpression, rightExpression] = condition
      .split(comparisonOperator)
      .map(str => str.trim())
    const [leftValue, leftVariables] = parseExpression(leftExpression, formData)
    const [rightValue, rightVariables] = parseExpression(
      rightExpression,
      formData,
    )
    const isValid = evaluateComparison(
      leftValue,
      rightValue,
      comparisonOperator,
    )
    if (!isValid) {
      const invalidVariables = [...leftVariables, ...rightVariables]
      errors = extendErrors(errors, invalidVariables, errorMessage)
    }
  })
  return errors
}

const FIELDSETS_TO_MODIFY = {
  // these field sets contain non-508-compliant fields

  // Economic Factors page
  baseline_economic_factors_t1d: { hideLabels: false },
  disutilities_t1d: { hideLabels: false },
  costs_t1d: { hideLabels: false },
  costs_medicare_t1d: { hideLabels: false },
  mortality_multipliers_t1d: { hideLabels: false },
  baseline_economic_factors_t2d: { hideLabels: false },
  disutilities_t2d: { hideLabels: false },
  costs_t2d: { hideLabels: false },
  costs_medicare_t2d: { hideLabels: false },
  mortality_multipliers_t2d: { hideLabels: false },
  baseline_economic_factors_pre: { hideLabels: false },
  disutilities_pre: { hideLabels: false },
  costs_pre: { hideLabels: false },
  costs_medicare_pre: { hideLabels: false },
  mortality_multipliers_pre: { hideLabels: false },
  baseline_economic_factors_screen: { hideLabels: false },
  disutilities_screen: { hideLabels: false },
  costs_screen: { hideLabels: false },
  costs_medicare_screen: { hideLabels: false },
  mortality_multipliers_screen: { hideLabels: false },

  // Population Generation
  SEARCH_risk_factors: { hideLabels: false },
  EXCHANGE_risk_factors: { hideLabels: false },
  risk_factors: { hideLabels: false },
  risk_factors_pre: { hideLabels: false },
  risk_factors_screen: { hideLabels: false },

  // New Scenario Setup
  save_items_on_run: { hideLabels: true },
  intervention_types_t1d: { hideLabels: true },
  intervention_types_t2d: { hideLabels: true },
  intervention_types_pre: { hideLabels: true },
  intervention_types_screen: { hideLabels: true },

  // Glycemic intervention
  glycemic_control_trajectory_advanced_t1d: { hideLabels: false },
  glycemic_control_intensification_advanced_t1d: { hideLabels: false },
  glycemic_control_targets_advanced_t1d: { hideLabels: false },
  glycemic_control_costs_advanced_t1d: { hideLabels: false },
  glycemic_control_noncompliers_t1d: { hideLabels: false },
  glycemic_control_trajectory_advanced_t2d: { hideLabels: false },
  glycemic_control_intensification_advanced_t2d: { hideLabels: false },
  glycemic_control_targets_advanced_t2d: { hideLabels: false },
  glycemic_control_costs_advanced_t2d: { hideLabels: false },
  glycemic_control_noncompliers_t2d: { hideLabels: false },
  glycemic_control_trajectory_advanced_screen: { hideLabels: false },
  glycemic_control_intensification_advanced_screen: { hideLabels: false },
  glycemic_control_targets_advanced_screen: { hideLabels: false },
  glycemic_control_costs_advanced_screen: { hideLabels: false },
  glycemic_control_noncompliers_screen: { hideLabels: false },

  // Blood pressure intervention
  bp_control_trajectory_advanced_t1d: { hideLabels: false },
  bp_control_intensification_advanced_t1d: { hideLabels: false },
  bp_control_targets_advanced_t1d: { hideLabels: false },
  bp_control_costs_advanced_t1d: { hideLabels: false },
  bp_control_noncompliers_t1d: { hideLabels: false },
  bp_control_trajectory_advanced_t2d: { hideLabels: false },
  bp_control_intensification_advanced_t2d: { hideLabels: false },
  bp_control_targets_advanced_t2d: { hideLabels: false },
  bp_control_costs_advanced_t2d: { hideLabels: false },
  bp_control_noncompliers_t2d: { hideLabels: false },
  bp_control_trajectory_advanced_screen: { hideLabels: false },
  bp_control_intensification_advanced_screen: { hideLabels: false },
  bp_control_targets_advanced_screen: { hideLabels: false },
  bp_control_costs_advanced_screen: { hideLabels: false },
  bp_control_noncompliers_screen: { hideLabels: false },

  // Cholesterol intervention
  cholesterol_control_trajectory_t1d: { hideLabels: false },
  cholesterol_control_advanced_t1d: { hideLabels: false },
  cholesterol_control_statin_t1d: { hideLabels: false },
  cholesterol_control_costs_advanced_t1d: { hideLabels: false },
  cholesterol_control_noncompliers_t1d: { hideLabels: false },
  cholesterol_control_trajectory_t2d: { hideLabels: false },
  cholesterol_control_advanced_t2d: { hideLabels: false },
  cholesterol_control_statin_t2d: { hideLabels: false },
  cholesterol_control_costs_advanced_t2d: { hideLabels: false },
  cholesterol_control_noncompliers_t2d: { hideLabels: false },
  cholesterol_control_trajectory_screen: { hideLabels: false },
  cholesterol_control_advanced_screen: { hideLabels: false },
  cholesterol_control_statin_screen: { hideLabels: false },
  cholesterol_control_costs_advanced_screen: { hideLabels: false },
  cholesterol_control_noncompliers_screen: { hideLabels: false },

  // Lifestyle intervention
  lifestyle_control_intervention_pre: { hideLabels: false },
  lifestyle_control_advanced_pre: { hideLabels: false },
  lifestyle_control_effects_pre: { hideLabels: false },
  lifestyle_control_costs_pre: { hideLabels: false },
  lifestyle_control_noncompliers_pre: { hideLabels: false },
  lifestyle_control_intervention_screen: { hideLabels: false },
  lifestyle_control_advanced_screen: { hideLabels: false },
  lifestyle_control_effects_screen: { hideLabels: false },
  lifestyle_control_costs_screen: { hideLabels: false },
  lifestyle_control_noncompliers_screen: { hideLabels: false },
}

const hideExecptions = [
  'intervention_set_name_t1d',
  'intervention_set_name_t2d',
  'intervention_set_name_pre',
  'intervention_set_name_screen',
]

export function modifyAccordionFieldset(schema) {
  // workaround to make certain field sets 508-compliant
  const schemaKey = String(schema.schema_key)
  if (FIELDSETS_TO_MODIFY[schemaKey]) {
    Object.keys(schema.items[0].properties).map(key => {
      const field = schema.items[0].properties[key]
      if (!schema['ui:schema']) schema['ui:schema'] = {}
      if (!field.readOnly) {
        // if input field, add text to label by adding title
        let currentTitle = schema.items[0].properties[key].title
        if (currentTitle === ' ') currentTitle = ''
        schema.items[0].properties[key].title = currentTitle
          ? currentTitle
          : key[0].toUpperCase() + key.replace(/_/g, ' ').substring(1)
        const hideLabels = FIELDSETS_TO_MODIFY[schemaKey]['hideLabels']
        if (hideLabels && !hideExecptions.includes(key)) {
          const currentOptions = schema['ui:schema'].items[key]
          schema['ui:schema'].items[key] = currentOptions ? currentOptions : {}
          const currentClassNames = schema['ui:schema'].items[key].classNames
            ? schema['ui:schema'].items[key].classNames + ' '
            : ''
          schema['ui:schema'].items[key][
            'classNames'
          ] = currentClassNames.concat('hide-label')
        }
      } else if (key.includes('placeholder')) {
        // if placeholder field, remove label
        const currentOptions = schema['ui:schema'].items[key]
        schema['ui:schema'].items[key] = currentOptions ? currentOptions : {}
        schema['ui:schema'].items[key]['ui:options'] = { label: false }
      }
    })
  }
  return schema
}
