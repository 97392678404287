// COMPONENT
import React, { FC, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'
import { OrangeH2 } from '../styles/components'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

type UploadData = {
  description: string
  type: string
  file: File | null
}

/* STYLES */

const ButtonContainer = styled('button')({
  ...styles.components.primaryButtonStyles,
  border: 'none !important',
  padding: '1rem 8rem',
  width: '100%',
  margin: 'auto',
  fontWeight: 'bold',
  '&:hover': {
    color: 'white',
    backgroundColor: '#205493',
  },
})

const FileInput = styled('input')({
  textAlignLast: 'center',
})

const Form = styled.form`
  margin: auto;
  width: fit-content;
  & label {
    margin-top: 0;
    width: 100%;
  }
  & p.error {
    color: red;
  }
`

const FormFieldset = styled('fieldset')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const initialUploadData = {
  description: '',
  type: 't1d',
  file: null,
}

const UploadPopulationDataset: FC<Props> = props => {
  const formRef = useRef<HTMLFormElement>(null)
  const inputEl = useRef<HTMLInputElement>(null)
  const [uploadData, setUploadData] = useState<UploadData>(initialUploadData)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const frontendValidation = file => {
    let valid = true
    if (props.populationDatasets.some(d => d.filename === file.name)) {
      alert(`Error: a file with the name ${file.name} already exists`)
      valid = false
    }
    return valid
  }
  return (
    <>
      <OrangeH2>Upload a Population Dataset</OrangeH2>
      <Form
        ref={formRef}
        onSubmit={async e => {
          e.preventDefault()
          const { file, description, type } = uploadData
          if (file && frontendValidation(file)) {
            setLoading(true)
            props.uploadPopulationDataset(
              file,
              description,
              type,
              async ({ response }) => {
                if (response === 'New dataset registered.') {
                  setUploadData(initialUploadData)
                  await props.loadPopulationDatasets()
                }
                if (response === 'Dataset does not have the correct columns') {
                  setErrorMessage(
                    'Error: Dataset does not have the correct columns',
                  )
                  setTimeout(setErrorMessage, 3000)
                }
                setLoading(false)
                if (formRef && formRef.current) formRef.current.reset()
              },
            )
          }
        }}>
        <FormFieldset>
          <label htmlFor="description">Description:</label>
          <input
            name="description"
            id="description"
            type="text"
            maxLength={80}
            value={uploadData.description}
            onChange={e => {
              const description = e.target.value
              setUploadData(prevState => ({
                ...prevState,
                description,
              }))
            }}
          />
          <label htmlFor="type">Type:</label>
          <select
            name="description"
            id="type"
            value={uploadData.type}
            onChange={e => {
              const type = e.target.value
              setUploadData(prevState => ({
                ...prevState,
                type,
              }))
            }}>
            <option value="t1d">Type 1</option>
            <option value="t2d">Type 2</option>
            <option value="pre">Prediabetes</option>
          </select>
          <FileInput
            ref={inputEl}
            type="file"
            title="file"
            onChange={e => {
              if (
                inputEl.current &&
                inputEl.current.files &&
                inputEl.current.files.length
              ) {
                const file = inputEl.current.files[0]
                setUploadData(prevState => ({
                  ...prevState,
                  file,
                }))
              }
            }}
          />
          <ButtonContainer type="submit">
            {loading ? 'Uploading...' : 'Upload'}
          </ButtonContainer>
          {errorMessage && <p className="error"> {errorMessage} </p>}
        </FormFieldset>
      </Form>
    </>
  )
}

const mapStateToProps = store => ({
  populationDatasets: store.populationDatasets,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  uploadPopulationDataset: async (
    file: File,
    description: string,
    type: string,
    cb,
  ) =>
    dispatch(
      await actions.uploadPopulationDataset(file, description, type, cb),
    ),
  loadPopulationDatasets: async () =>
    dispatch(await actions.loadPopulationDatasets()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadPopulationDataset),
)
