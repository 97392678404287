export const parse = (token: string) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const isExpired = (token: string | null) => {
  if (!token) return true
  const { expiry } = parse(token)
  const expiryInMilliseconds = expiry * 1000
  return expiryInMilliseconds < Date.now()
}
