// COMPONENTS
import React, { ComponentClass } from 'react'
// STATE
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { logout, refreshUser } from '../store/actions'
// UTILS
import * as jwt from '../utils/jwt'
// TYPES
import * as type from '../type'
import { RouteComponentProps, Route } from 'react-router'

/* COMPONENT SPECIFIC PROPS */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = RouteComponentProps<any> &
  StateProps &
  DispatchProps & {
    path: string
    render?: CallableFunction
    component?: ComponentClass<any, any>
  }

class PrivateRoute extends React.Component<Props> {
  state = { authenticated: false, loading: true }

  checkAuthStatus = async () => {
    const { access, refresh } = this.props.state.auth
    if (refresh) {
      if (access && !jwt.isExpired(access)) {
        return true
      } else if (!jwt.isExpired(refresh)) {
        const refreshed = await this.props.handleRefreshUser()
        if (refreshed) {
          return true
        }
      }
    }
    await this.props.handleLogout()
    return false
  }

  componentDidMount = async () => {
    const authenticated = await this.checkAuthStatus()
    this.setState({ authenticated, loading: false })
  }

  render() {
    const { path, render, component: Component } = this.props
    if (type.isFunction(render)) {
      return (
        <Route
          path={path}
          render={props =>
            this.state.loading ? (
              <div />
            ) : this.state.authenticated ? (
              render(props)
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      )
    } else if (Component) {
      return (
        <Route
          path={path}
          render={props =>
            this.state.loading ? (
              <div />
            ) : this.state.authenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
      )
    } else {
      throw 'PrivateRoute must be given a render or component prop.'
    }
  }
}

const mapStateToProps = (state: type.Store) => ({
  state,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  handleLogout: () => dispatch(logout()),
  handleRefreshUser: () => dispatch(refreshUser()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute),
)
