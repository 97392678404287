import * as type from './type'

let statusMonitor

export const setStatusMonitor = (
  cb,
  interval,
  finishedSetupScreenListener = false,
) => {
  const listener = setInterval(cb, interval)
  if (finishedSetupScreenListener) statusMonitor = listener
  return () => clearInterval(listener)
}
export const getStatusMonitor = () => statusMonitor
export const clearStatusMonitor = () => clearInterval(statusMonitor)

// local dev
//const apiUrl = 'http://0.0.0.0:8888/api/'

//cdc deployment
  const apiUrl = '/api/'

// aws deployment
// const apiUrl = 'https://api.comprehensivediabetes.org/api/'

export const successMessage = 'Run Complete'
export const failureMessage = 'Simulation Failed'

/* SUBMIT CONFIG */
export const sendSimulationConfig = (config: type.OutputData) =>
  fetch(`${apiUrl}scenario/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(config),
  }).then(r => r.json())

/* RESULTS */
export const fetchStatus = (uuid: string) =>
  fetch(`${apiUrl}status/${uuid}/`, { cache: 'no-store' })
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchCost = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/cost/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchPrevalence = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/prevalence/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchMeanPrevalence = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/mean_prevalence/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchIncidence = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/incidence/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchCEA = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/cea/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchMeanCEA = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/mean_cea/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchPerPersonCost = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/per_person_cost/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchCostRaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/cost_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchPrevalenceRaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/prevalence_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchMeanPrevalenceRaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/mean_prevalence_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchIncidenceRaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/incidence_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchCEARaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/cea_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchMeanCEARaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/mean_cea_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchPerPersonCostRaw = (uuid: string) =>
  fetch(`${apiUrl}analysis/${uuid}/per_person_cost_raw/`)
    .then(r => r.json())
    .catch(err => console.log(err))

export const fetchPopulation = (uuid: string, access_token: string) =>
  fetch(`${apiUrl}population-datasets/${uuid}/runandupload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  }).then(r => r.json())

/* CONFIG */
export const saveConfigPackage = (
  uuid: string,
  configPackage: type.ConfigPackage,
  access_token: string,
): Promise<type.SaveConfigResults> =>
  fetch(`${apiUrl}save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      input_name: uuid,
      data: configPackage,
    }),
  }).then(r => r.json())

/* CONFIG */
export const loadConfigPackage = (
  uuid: string,
  access_token: string,
): Promise<type.SaveConfigResults> =>
  fetch(`${apiUrl}load_schema`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      input_name: uuid,
    }),
  }).then(r => r.json())

export const getConfigIds = (
  access_token: string,
): Promise<type.ListSchemaResults> =>
  fetch(`${apiUrl}list_schema`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  }).then(r => r.json())

export const deleteConfig = (
  access_token: string,
  uuid: string,
): Promise<type.ListSchemaResults> =>
  fetch(`${apiUrl}delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({
      input_name: uuid,
    }),
  }).then(async r => {
    const text = await r.text()
    return text ? JSON.parse(text) : {}
  })

/* Notifications */

export const getNotifications = (access_token: string) =>
  fetch(`${apiUrl}notifications`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  }).then(r => r.json() || [])

export const deleteNotification = (uuid: string, access_token: string) =>
  fetch(`${apiUrl}notifications`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({ uuid }),
  })

/* POPULATION DATASETS */

export const getPopulationDatasets = (access_token: string) =>
  fetch(`${apiUrl}population-datasets`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  }).then(r => r.json())

export const uploadPopulationDataset = (body, access_token: string) =>
  fetch(`${apiUrl}population-datasets/upload`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body,
  }).then(r => r.json())

export const downloadPopulationDataset = async (
  uuid: string,
  access_token: string,
) => {
  const response = await fetch(
    `${apiUrl}population-datasets/${uuid}/download`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    },
  )
  return response
}

export const editPopulationDataset = (
  uuid: string,
  description: string,
  access_token: string,
) =>
  fetch(`${apiUrl}population-datasets/${uuid}/edit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({ description }),
  }).then(r => r.json())

export const sharePopulationDataset = (
  uuid: string,
  receiver,
  access_token: string,
) =>
  fetch(`${apiUrl}population-datasets/${uuid}/share`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({ receiver }),
  })

export const deletePopulationDataset = (uuid: string, access_token: string) =>
  fetch(`${apiUrl}population-datasets/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })

/* CONFIG FILES */

export const getConfigFiles = (access_token: string) =>
  fetch(`${apiUrl}config-files`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
  }).then(r => r.json())

export const uploadConfigFile = (body, access_token: string) =>
  fetch(`${apiUrl}config-files/upload`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body,
  }).then(r => r.json())

export const saveConfigOnRun = (body, access_token: string) =>
  fetch(`${apiUrl}config-files/runandupload`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body,
  }).then(r => r.json())

export const shareConfigFile = (uuid: string, receiver, access_token: string) =>
  fetch(`${apiUrl}config-files/${uuid}/share`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify({ receiver }),
  })

export const deleteConfigFile = (uuid: string, access_token: string) =>
  fetch(`${apiUrl}config-files/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })

/* AUTH */

export const userAction = (endpoint: string, credentials: object) =>
  fetch(`${apiUrl}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then(r => r.json())

export const loginUser = (
  credentials: type.LoginCredentials,
): Promise<type.LoginResponse> => userAction('login/', credentials)

export const refreshUser = (
  credentials: type.RefreshCredentials,
): Promise<type.RefreshResponse> => userAction('login/refresh/', credentials)

export const registerUser = (
  credentials: type.RegistrationCredentials,
): Promise<type.RegistrationResponse> => userAction('register', credentials)
