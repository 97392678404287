import React from 'react'
import Chart from 'chart.js'
import SimpleTableOutput from './SimpleTableOutput'
import { Bar } from 'react-chartjs-2'
import styled from '@emotion/styled'
import styles from '../styles'
import utils from '../utils'
import * as type from '../type'

/* ChartJS */

Chart.defaults.global.defaultFontSize = 16
Chart.pluginService.register({
  beforeDraw: function(chartInstance) {
    var ctx = chartInstance.chart.ctx
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height)
  },
})

const Button = styled.button({
  display: 'block',
  margin: '0.75rem 0 0 auto',
  padding: '0',
  textAlign: 'right',
  ...styles.components.linkStyles,
  fontSize: '1.5rem',
})

type Props = {
  csvData: type.CSV
  foldable: boolean
  doNotFold?: Array<string>
  tableProps: object
  csvFilename: string,
  summary: type.ConfigSummary
}

export const generateHeader = key => {
  switch (key) {
    case 'qaly':
      return 'QALY'
    default:
      return key
        .split('_')
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(' ')
  }
}

export const generateColumns = (csvDatapoint, doNotFold = [] as Array<any>) =>
  Object.keys(csvDatapoint).map(key => ({
    Header: generateHeader(key),
    foldable: !doNotFold.includes(key),
    accessor: key,
    minWidth: key === ' ' ? 100 : key.length * 8.5 + 40,
    resizable: true,
    style: { textAlign: 'center' },
  }))

const IncidenceOutput: React.SFC<Props> = ({
  csvData,
  foldable,
  doNotFold,
  tableProps,
  csvFilename,
  summary
}) => {
  const filteredColumns = generateColumns(csvData[0], doNotFold)
    // remove Deceased & Count Surviving from chart as requested by RTI 04/02/2020
    .filter(utils.csv.removeFields(['Deceased', 'Count Surviving']))
  // the table uses Header(display)/accessor(property key) pairs to control display text
  // but chartjs uses the property key as the display label
  // keysToHeaders is used to map the property keys to the chartjs labels
  const keysToHeaders = filteredColumns.reduce((dict, col) => {
    if (!['intervention', 'time'].includes(col.accessor))
      dict[col.accessor] = col.Header
    return dict
  }, {})

  // this allows the last timestep to be the max PER scenario (essential for all deceased)
  const lastTimestepData = Object.values(csvData.reduce((r: Object, o: type.CSVRow) => {
    r[o.intervention] = (r[o.intervention] && r[o.intervention].value > o.value) ? r[o.intervention] : o
    return r
  }, {}))

  const numInterventions = csvData.length - 1
  let colorCounter = 0
  const baseHue = 45
  const chartData = {
    labels: Object.values(keysToHeaders),
    xAxisID: 'Risk Factors',
    yAxisID: 'Incidence (Final Year)',
    datasets: lastTimestepData.map(intervention => {
      const label = intervention['intervention']
      // generating non-repeating colors
      const hue =
        label === 'baseline'
          ? baseHue
          : baseHue + (++colorCounter * 360) / (1 + numInterventions)
      return {
        label,
        data: Object.keys(keysToHeaders).map(key => intervention[key]),
        backgroundColor: `hsla(${hue}, 100%, 80%)`,
        borderColor: `hsla(${hue}, 100%, 50%)`,
        borderWidth: 1,
      }
    }),
  }
  const options = {
    title: {
      display: true,
      text: 'Complication Cumulative Incidence (Final Year)',
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Counts',
        }
      }]
    },
  }

  const invertedCsvData = utils.results.simpleInvert(lastTimestepData, 'intervention')
  const invertedColumns = generateColumns(invertedCsvData[0], doNotFold)
  return (
    <div id="incidence-output-container">
      {
        summary['Basic Configurations']['Time horizon execution strategy'] === 'all_deceased'
        ?
        <div className="alert alert-warning" role="alert">
          The time horizon chosen for this simulation was 'all_deceased'. Complication Incidence will only display deceased for all individuals in the population and this section can be ignored.
          </div>
        : null
      }
      <Bar data={chartData} options={options} height={200} />
      <Button
        onClick={utils.csv.handleChartDownload(
          '#incidence-output-container canvas',
          csvFilename,
        )}>
        Download Chart as PDF
        <styles.components.LinkDownloadIcon />
      </Button>
      <hr />
      <SimpleTableOutput
        {...{
          csvData: invertedCsvData,
          foldable,
          columns: invertedColumns,
          tableProps,
          csvFilename,
        }}
      />
    </div>
  )
}

export default IncidenceOutput
