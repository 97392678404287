// COMPONENT
import React from 'react'
// TYPES
import * as type from '../type'
// STYLE
import styled from '@emotion/styled'

type TabbedViewsProps = {
  views: Array<View>
}
type TabbedViewsState = {
  views: Array<View>
}
type View = {
  name: string
  activated: boolean
  tabText: string | number
  renderPanel: () => React.ReactElement
}
type StyledTabProps = {
  activated: boolean
}
type TabProps = {
  name: string
  activated: boolean
  children: React.ReactChildren | string | number
  activateTab: () => void
  keyupEventListener: (e: React.KeyboardEvent) => void
}

const StyledTab = styled('button')<StyledTabProps>(
  {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    marginBottom: '0',
    border: '3px solid #2e71b6',
    color: '#2e71b6',
    borderBottom: 'none',
  },
  ({ activated }): type.CSSObject => ({
    backgroundColor: activated ? '#2e71b6' : 'white',
    color: activated ? 'white' : undefined,
  }),
)
const TabContainer = styled('div')({
  margin: 0,
  paddingLeft: '3rem',
})

const Tab: React.SFC<TabProps> = ({
  name,
  activated,
  children,
  activateTab,
  keyupEventListener,
}) => {
  const attributes = {
    role: 'tab',
    'aria-selected': activated,
    'aria-controls': `${name}-panel`,
    id: `${name}-tab`,
    tabIndex: undefined as number | undefined,
  }
  if (!activated) attributes.tabIndex = -1
  return (
    <StyledTab
      {...attributes}
      onClick={activateTab}
      onFocus={activateTab}
      onKeyUp={keyupEventListener}
      activated={activated}>
      {children}
    </StyledTab>
  )
}

const StyledPanel = styled('div')({
  marginBottom: '2rem',
  borderTop: '3px solid #2e71b6',
  padding: '3rem',
  backgroundColor: '#F1F4FA',
})

const Panel = ({ name, activated, children }: any) => {
  const attributes: any = {
    tabIndex: '0',
    role: 'tab-panel',
    id: `${name}-panel `,
    'aria-labelledby': `${name}-tab`,
  }
  if (!activated) attributes.hidden = true
  return <StyledPanel {...attributes}>{children}</StyledPanel>
}

export default class TabbedViews extends React.Component<
  TabbedViewsProps,
  TabbedViewsState
> {
  tablistEl: React.RefObject<any>

  constructor(props: TabbedViewsProps) {
    super(props)
    this.state = {
      views: props.views,
    }
    this.tablistEl = React.createRef()
  }

  activateTab = (index: number) => () => {
    const updatedViews = this.state.views.map((tab, i) => {
      // only activate tab with selected index
      tab.activated = i === index
      return tab
    })
    this.setState(state => ({
      ...state,
      views: updatedViews,
    }))

    const tabElements =
      this.tablistEl.current && this.tablistEl.current.children
    tabElements[index].focus()
  }

  keyupEventListener = (index: number) => (event: React.KeyboardEvent) => {
    // Add or substract depending on key pressed
    const direction: any = {
      37: -1,
      39: 1,
    }

    const { views } = this.state
    const key = event.keyCode
    if (Object.keys(direction).includes('' + key)) {
      let newIndex = index + direction[key]
      if (newIndex < 0) newIndex = 0
      else if (newIndex >= views.length) newIndex = views.length - 1
      this.activateTab(newIndex)()
    }
  }

  render() {
    const { views } = this.state
    return (
      <>
        <TabContainer className="usa-grid tabs-container">
          <div ref={this.tablistEl} role="tablist">
            {views.map(({ name, activated, tabText }, i) => (
              <Tab
                key={i}
                {...{ name, activated }}
                activateTab={this.activateTab(i)}
                keyupEventListener={this.keyupEventListener(i)}>
                {tabText}
              </Tab>
            ))}
          </div>
        </TabContainer>
        {views.map(({ name, activated }, i) => {
          return (
            <Panel key={i} {...{ name, activated }}>
              {this.props.views[i].renderPanel()}
            </Panel>
          )
        })}
      </>
    )
  }
}
