import { ACTION } from '../actions'
import * as type from '../../type'
import utils from '../../utils'

const initialState = {
  configJSON: {
    config: {},
    summary: {
      'Basic Configurations': {},
      'Modified Sections': {},
    },
    created_at: 'null',
  },
  status: '',
  errorMessage: '',
  cost: {},
  prevalence: {},
  mean_prevalence: {},
  incidence: {},
  cea: [],
  mean_cea: {},
  ppc: {},
  cost_raw: {},
  prevalence_raw: {},
  mean_prevalence_raw: {},
  incidence_raw: {},
  cea_raw: {},
  mean_cea_raw: {},
  ppc_raw: {},
}

export default (state: type.Results = initialState, action: type.Action) => {
  switch (action.type) {
    case ACTION.SET_RESULTS:
      const results = {
        ...state,
        ...action.payload,
      }
      utils.sessionStorage.saveResultsState(results)
      return results
    case ACTION.LOGOUT_USER:
    case ACTION.RESET_RESULTS:
      utils.sessionStorage.clearResultsState()
      return initialState
    default:
      return state
  }
}
