import React from 'react'
import USWDSVariableAccordion from './USWDSVariableAccordion'
import CeaOutput from './CeaOutput'
import IncidenceOutput from './IncidenceOutput'
import PrevalenceOutput from './PrevalenceOutput'

// import IncidenceTableOutput from './IncidenceTableOutput'
import {
  simpleReformatForCSV,
  incidenceReformatForCSV,
  hasRawData,
} from '../utils/csv'
import * as type from '../type'
import styled from '@emotion/styled'
import styles from '../styles'

/* COMPONENT SPECIFIC TYPES */
type Props = ContainerProps & AdditionalOutputs
type AdditionalOutputs = {
  additional: Partial<type.Results>
  scenarioName: string,
  summary: type.ConfigSummary
}
type ContainerProps = {
  positionStyles: type.CSSObject
}

/* STYLES */
const StyledAdditionalOutputsContainer = styled('section')<ContainerProps>(
  {},
  props => props.positionStyles,
)

const displayConfig = {
  cea: {
    heading: 'Cost Effectiveness Analysis Curve',
    filename: 'cea',
    Component: CeaOutput,
    toCSV: simpleReformatForCSV,
    foldable: false, // configures collapsible fields for table
    tableProps: () => ({}),
  },
  prevalence: {
    heading: 'Prevalence',
    filename: 'prevalence',
    Component: PrevalenceOutput,
    toCSV: simpleReformatForCSV,
    foldable: false, // configures collapsible fields for table
    doNotFold: [' '],
    tableProps: () => ({}),
  },
  incidence: {
    heading: 'Cumulative Incidence (Final Year)',
    filename: 'cumulative_incidence',
    Component: IncidenceOutput,
    toCSV: incidenceReformatForCSV,
    foldable: true, // configures collapsible fields for table
    doNotFold: ['time', 'intervention'],
    tableProps: csvData => ({
      pageSize: Object.keys(csvData[0]).length,
    }),
  },
}

const AdditionalItems: React.SFC<AdditionalOutputs> = ({
  additional,
  scenarioName,
  summary
}) => {
  const items = Object.entries(additional)
    .filter(([key, value]) => key !== 'mean_prevalence')
    .filter(([key, value]) => key !== 'prevalence')
    .filter(([key, value]) => key !== 'ppc')
    .filter(([key, value]) => key !== 'mean_cea')
    .filter(([key, value]) => hasRawData(value))
    .map(([key, value]) => {
      const {
        heading,
        Component,
        toCSV,
        foldable,
        doNotFold,
        tableProps,
      } = displayConfig[key]
      const csvData = toCSV(value)
      return {
        headingText: heading,
        Component,
        contentProps: {
          csvData,
          foldable,
          doNotFold: doNotFold || [],
          tableProps: tableProps(csvData) || {},
          csvFilename: `${scenarioName}_${displayConfig[key].filename}`,
          summary: summary
        },
      }
    })
  return <USWDSVariableAccordion items={items} />
}

const AdditionalOutputs: React.SFC<Props> = ({
  positionStyles,
  scenarioName,
  additional,
  summary,
}) => (
  <StyledAdditionalOutputsContainer positionStyles={positionStyles}>
    <styles.components.OrangeH2>Output Details</styles.components.OrangeH2>
    <AdditionalItems additional={additional} scenarioName={scenarioName} summary={summary} />
  </StyledAdditionalOutputsContainer>
)

export default AdditionalOutputs
