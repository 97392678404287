import { ACTION } from '../actions'
import deepClone from 'object-assign-deep'
import utils from '../../utils'
import * as type from '../../type'

export default function form(
  state: type.FormConfig = { pages: [] },
  action: type.Action,
) {
  switch (action.type) {
    case ACTION.SET_FORM_STORE:
    case ACTION.SET_POPULATION_DATASETS:
      return action.form
    case ACTION.SET_FORM_DATA:
      return action.payload
    case ACTION.UPDATE_FORM_DATA:
      const { layoutKey, newFormData } = action.payload
      const newState = deepClone({}, state)
      const fieldsetState = utils.form.getFieldsetState(newState, layoutKey)
      utils.form.dirtyDeepSetDefaults(fieldsetState, newFormData)
      return newState
    default:
      return state
  }
}
