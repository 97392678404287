// COMPONENT
import React, { FC, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'
import { OrangeH2 } from '../styles/components'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

type UploadData = {
  description: string
  configJSON: string
  filename: string
}

/* STYLES */

const ButtonContainer = styled('button')({
  ...styles.components.primaryButtonStyles,
  border: 'none !important',
  padding: '1rem 8rem',
  width: '100%',
  margin: 'auto',
  fontWeight: 'bold',
  '&:hover': {
    color: 'white',
    backgroundColor: '#205493',
  },
})

const FileInput = styled('input')({
  textAlignLast: 'center',
})

const Form = styled.form`
  margin: auto;
  width: fit-content;
  & label {
    margin-top: 0;
    width: 100%;
  }
`

const FormFieldset = styled('fieldset')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const initialUploadData = {
  description: '',
  configJSON: '',
  filename: '',
}

const basicConfigValidation = configObj => {
  const neededKeys = ['config', 'summary', 'created_at']
  const isValid = neededKeys.every(key => Object.keys(configObj).includes(key))
  if (!isValid) throw 'Config file does not contain the correct fields'
}

const UploadConfigFile: FC<Props> = props => {
  const inputEl = useRef<HTMLInputElement>(null)
  const [uploadData, setUploadData] = useState<UploadData>(initialUploadData)
  const [loading, setLoading] = useState(false)
  return (
    <>
      <OrangeH2>Upload a Config File</OrangeH2>
      <Form
        onSubmit={async e => {
          e.preventDefault()
          const { description, configJSON, filename } = uploadData
          if (configJSON) {
            setLoading(true)
            props.uploadConfigFile(
              description,
              configJSON,
              filename,
              //   type,
              async ({ response }) => {
                if (response === 'New config file registered.') {
                  if (inputEl.current) inputEl.current.value = ''
                  setUploadData(initialUploadData)
                  await props.loadConfigFiles()
                }
                setLoading(false)
              },
            )
          }
        }}>
        <FormFieldset>
          <label htmlFor="description">Description:</label>
          <input
            name="description"
            id="description"
            type="text"
            maxLength={80}
            value={uploadData.description}
            onChange={e => {
              const description = e.target.value
              setUploadData(prevState => ({
                ...prevState,
                description,
              }))
            }}
          />
          <FileInput
            ref={inputEl}
            type="file"
            title="file"
            onChange={e => {
              if (
                inputEl.current &&
                inputEl.current.files &&
                inputEl.current.files.length
              ) {
                // JSON file is immediately converted to string
                const file = inputEl.current.files[0]
                const fileReader = new FileReader()
                fileReader.readAsText(file, 'UTF-8')
                fileReader.onload = e => {
                  if (
                    inputEl.current &&
                    inputEl.current.files &&
                    inputEl.current.files[0]
                  ) {
                    let configString
                    const filename = inputEl.current.files[0].name
                    try {
                      configString =
                        typeof fileReader.result === 'string'
                          ? fileReader.result
                          : null
                      basicConfigValidation(JSON.parse(configString))
                    } catch (e) {
                      configString = null
                      inputEl.current.value = ''
                      const alertMessage =
                        e instanceof SyntaxError ? 'Invalid JSON File' : e
                      alert(alertMessage)
                    }
                    setUploadData(prevState => ({
                      ...prevState,
                      configJSON: configString,
                      filename: filename,
                    }))
                  }
                }
              }
            }}
          />
          <ButtonContainer type="submit">
            {loading ? 'Uploading...' : 'Upload'}
          </ButtonContainer>
        </FormFieldset>
      </Form>
    </>
  )
}

const mapStateToProps = store => ({})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  uploadConfigFile: async (
    description: string,
    configJSON: string,
    filename: string,
    cb,
  ) =>
    dispatch(
      await actions.uploadConfigFile(description, configJSON, filename, cb),
    ),
  loadConfigFiles: async () => dispatch(await actions.loadConfigFiles()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadConfigFile),
)
