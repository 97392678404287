import { deepSelectiveMap } from './schema'
import * as type from '../type'

export const loadState = (
  key: string = 'state',
  cb?: (savedState: type.SavedStoreItem) => type.SavedStoreItem,
) => {
  try {
    const serializedState = sessionStorage.getItem(key)
    // returning undefined allows redux to initialize a new store
    if (serializedState === null) return undefined
    // otherwise, initialize store with the state in sessionStorage
    const state = JSON.parse(serializedState)
    return cb ? cb(state) : state
  } catch (err) {
    // init new store when access denied to sessionStorage
    return undefined
  }
}

export const saveState = (key: string = 'state', state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem(key, serializedState)
  } catch (err) {
    /* ignore error */
  }
}

/* SESSION STORAGE KEYS */
const keyTo = {
  schema: 'schema',
  form: 'data',
  results: 'results',
  portfolio: 'portfolio',
}

/* SCHEMA */
export const saveSchemaState = (schema: type.ConfigSchema) =>
  saveState(keyTo.schema, schema)
export const loadSchemaState = () => {
  const schema = loadState(keyTo.schema)
  return schema
}
export const clearSchemaState = () => sessionStorage.removeItem(keyTo.schema)

/* FORM DATA */
export const saveDataState = async (data: type.FormConfig) =>
  await saveState(keyTo.form, replaceUndefinedsWithPlaceholder(data))
export const loadDataState = () =>
  loadState(keyTo.form, replacePlaceholderUndefineds)
export const clearDataState = async () =>
  await sessionStorage.removeItem(keyTo.form)

/* RESULTS */
export const saveResultsState = async (results: type.Results) =>
  await saveState(keyTo.results, results)
export const loadResultsState = () => loadState(keyTo.results)
export const clearResultsState = async () =>
  await sessionStorage.removeItem(keyTo.results)

/* PORTFOLIO */
export const savePortfolioState = async (portfolio: type.Portfolio) =>
  await saveState(keyTo.portfolio, portfolio)
export const loadPortfolioState = () => loadState(keyTo.portfolio)
export const clearPortfolioState = async () =>
  await sessionStorage.removeItem(keyTo.portfolio)

/* UTILS */
function replaceUndefinedsWithPlaceholder(
  data: type.SavedStoreItem,
): type.SavedStoreItem {
  return deepSelectiveMap(data, (key: string, value: any) => {
    return value === undefined ? { key, value: 'undefined' } : { key, value }
  })
}
function replacePlaceholderUndefineds(
  data: type.SavedStoreItem,
): type.SavedStoreItem {
  return deepSelectiveMap(data, (key: string, value: any) => {
    return value === 'undefined' ? { key, value: undefined } : { key, value }
  })
}
