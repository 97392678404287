import { Location } from 'history'
import * as type from '../type'

export function kebabToTitleCase(path: string) {
  return (
    path &&
    path
      .split('-')
      .map((word: string) => word[0].toUpperCase() + word.slice(1))
      .join(' ')
  )
}

export function parseLocationToHeading(location: Location) {
  const path = location.pathname.split('/')
  const trimmed = path[0] || path[1]
  return kebabToTitleCase(trimmed)
}

export function parseLocationToButtonContext(
  location: string,
  defaultText: string,
) {
  if (!location || location === '/') return defaultText
  const lastIndex = location.length - 1
  if (location[lastIndex] === '/') {
    location = location.slice(0, lastIndex)
  }
  const path = location.split('/')
  const pathEnd = path[path.length - 1]
  return kebabToTitleCase(pathEnd)
}

export function getHeaderButtonText(
  headerButtons: type.StaticHeaders | type.DynamicHeaders,
  i: number,
) {
  if (Array.isArray(headerButtons)) return headerButtons[i]
  else if (typeof headerButtons === 'object')
    return `${headerButtons.common_text}${headerButtons.numbered &&
      ` ${i + 1}`}`
  else return ''
}
