import { ACTION } from '../actions'
import utils from '../../utils'
import * as type from '../../type'

const initialState: type.Portfolio = {
  headings: utils.portfolio.summaryHeadingsMap(),
  rows: [],
}

export default function form(
  state: type.Portfolio = initialState,
  action: type.Action,
) {
  switch (action.type) {
    case ACTION.SET_SCENARIO_PORTFOLIO:
      return action.payload
    case ACTION.UPDATE_PORTFOLIO:
      return {
        ...state,
        ...action.payload,
      }
    case ACTION.UPDATE_PORTFOLIO_ITEM:
      let { uuid, portfolioItem } = action.payload
      return {
        ...state,
        rows: state.rows.map(r => (r.uuid === uuid ? portfolioItem : r)),
      }
    case ACTION.UPDATE_PORTFOLIO_ITEM_STATUS:
      uuid = action.payload.uuid
      const { simulation_status } = action.payload
      return {
        ...state,
        rows: state.rows.map(r =>
          r.uuid === uuid
            ? {
                ...r,
                simulation_status,
              }
            : r,
        ),
      }
    case ACTION.LOGOUT_USER:
      return initialState
    default:
      return state
  }
}
