// COMPONENTS
import React, { SFC } from 'react'
import { withRouter } from 'react-router-dom'
// UTILS
import utils from '../../utils'
// TYPES
import { RouteComponentProps } from 'react-router'
import * as type from '../../type'
// STYLE
import styled from '@emotion/styled'

type Props = Partial<type.PageButtonPaths> &
  RouteComponentProps & {
    contextualButtons: boolean
    handleSubmit: (e: React.MouseEvent) => void
    preNavigationCB?: () => Promise<boolean>
    form_submit_button?: boolean | string
  }

const pagingButtonWidth = 'calc(50% - 2.85rem)'

const ButtonContainer = styled('div')({
  width: `100%`,
  maxWidth: '74rem',
  margin: '3rem auto auto',
})

const PagingContainer = styled('div')({
  width: `100%`,
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
})
const PagingButton = styled('button')({
  width: `${pagingButtonWidth}`,
  margin: 0,
})
const PagingLinkButton = styled('button')({
  width: `${pagingButtonWidth}`,
  margin: 0,
})
const IntraFormSubmitButton = styled('button')({
  width: `100%`,
  margin: '1rem auto',
})

const handleNavigation = (history, path, preNavigationCB) => async () => {
  if (!preNavigationCB || (await preNavigationCB())) history.push(path)
}

const PagingButtons: SFC<Props> = ({
  prevPagePath,
  nextPagePath,
  handleSubmit,
  contextualButtons,
  history,
  preNavigationCB,
  form_submit_button,
}) => (
  <ButtonContainer>
    <PagingContainer>
      {prevPagePath ? (
        <PagingLinkButton
          onClick={handleNavigation(history, prevPagePath, preNavigationCB)}>
          {contextualButtons
            ? `Back to ${utils.misc.parseLocationToButtonContext(
                prevPagePath,
                'Previous Page',
              )}`
            : 'Previous Page'}
        </PagingLinkButton>
      ) : (
        <div />
      )}
      {nextPagePath ? (
        <PagingLinkButton
          onClick={handleNavigation(history, nextPagePath, preNavigationCB)}>
          {contextualButtons
            ? `Continue to ${utils.misc.parseLocationToButtonContext(
                nextPagePath,
                'Next Page',
              )}`
            : 'Next Page'}
        </PagingLinkButton>
      ) : (
        <PagingButton type="submit" onClick={handleSubmit}>
          Finish and Submit
        </PagingButton>
      )}
    </PagingContainer>
    {form_submit_button && (
      // blah blah
      <IntraFormSubmitButton type="submit" onClick={handleSubmit}>
        {typeof form_submit_button === 'string'
          ? form_submit_button
          : 'Finish and Submit'}
      </IntraFormSubmitButton>
    )}
  </ButtonContainer>
)

export default withRouter(PagingButtons)
