// COMPONENTS
import React from 'react'
import AccordionArrayFieldTemplate from './AccordionArrayFieldTemplate'
import TabbedViews from '../TabbedViews'
// UTILS
import utils from '../../utils'
import shortid from 'shortid'
import { getFieldType } from './utils'
// TYPE
import * as type from '../../type'
// STYLE
import styled from '@emotion/styled'

type ArrayFieldTemplateProps = {
  TitleField: (props: type.RJSFTitleFieldProps) => React.ReactElement
  canAdd: boolean
  className: string
  disabled: boolean
  items: Array<type.ArrayFieldTemplateItem>
  onAddClick: (e: React.MouseEvent) => void
  readonly: boolean
  required: boolean
  schema: type.Subschema
  uiSchema: type.UISchema
  title: string
  formContext: undefined
  formData: type.OutputData
}
type TemplateRenderDict = {
  [key: string]: (
    items: Array<type.ArrayFieldTemplateItem>,
    uiOptions: type.UIOptions,
  ) => React.ReactElement
}

const Grid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
})
const GridItem = styled('div')({
  width: '40rem',
  height: '15rem',
  paddingRight: '3rem',
})

const arrayTemplateDict: TemplateRenderDict = {
  default: items => <>{items.map(({ children }) => children)}</>,
  accordion: (items, uiOptions) => (
    <AccordionArrayFieldTemplate {...{ items, uiOptions }} />
  ),
  grid: items => (
    <Grid>
      {items.map(({ children }, i) => (
        <GridItem key={i}>{children}</GridItem>
      ))}
    </Grid>
  ),
  tabs: (items, uiOptions) => {
    return (
      <TabbedViews
        views={items.map(({ children }, i) => {
          return {
            name: `tabbed-views-fieldset-${shortid.generate()}`,
            activated: i === 0,
            tabText: utils.misc.getHeaderButtonText(
              uiOptions.header_buttons,
              i,
            ),
            renderPanel: () => children,
          }
        })}
      />
    )
  },
}

const CustomArrayFieldTemplate: React.SFC<ArrayFieldTemplateProps> = props => {
  const { TitleField, title, className, items, uiSchema } = props
  const itemsUiOptions = uiSchema.items && uiSchema.items['ui:options']
  return (
    <section className={className}>
      <TitleField title={title} />
      {arrayTemplateDict[getFieldType(uiSchema)](items, itemsUiOptions)}
    </section>
  )
}

export default CustomArrayFieldTemplate
