// COMPONENTS
import React, { Component } from 'react'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import Header from './components/Header'
import Login from './components/Login'
import SignUp from './components/SignUp'
import Home from './components/Home'
import Form from './components/form/Form'
import Output from './components/Output'
import Footer from './components/Footer'
import NotificationsDashboard from './components/NotificationsDashboard'
import PopulationDatasetsDashboard from './components/PopulationDatasetsDashboard'
import ConfigFileDashboard from './components/ConfigFileDashboard'
import FinishedSetupScreen from './components/form/FinishedSetupScreen'
// STATE
import { connect } from 'react-redux'
import * as actions from './store/actions'
// TYPES
import * as type from './type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styled from '@emotion/styled'
// import styles from './styles'
import './styles/App.css'

/* TYPES */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/* STYLES */
const StyledApp = styled('div')({
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const StyledFormContainer = styled('div')({
  width: '90%',
  margin: 'auto auto 10rem',
  maxWidth: '114rem',
})

class App extends Component<Props> {
  componentDidMount() {
    const { schemaReceived, initSchema } = this.props
    if (!schemaReceived) initSchema(undefined)
  }

  render() {
    const { schemaReceived, form } = this.props
    const loggedIn = false // for dev purposes
    return (
      <StyledApp className="App">
        <Header />
        <main>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/sign-up" component={SignUp} />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute
              path="/config-file-dashboard"
              component={ConfigFileDashboard}
            />
            <PrivateRoute
              path="/population-dashboard"
              component={PopulationDatasetsDashboard}
            />
            <PrivateRoute
              path="/notifications"
              component={NotificationsDashboard}
            />
            {schemaReceived && (
              <PrivateRoute
                path="/new-scenario"
                render={(routerProps: any) => (
                  <StyledFormContainer>
                    <Form schemaLayout={form} {...routerProps} />
                  </StyledFormContainer>
                )}
              />
            )}
            <PrivateRoute path="/output" component={Output} />
            <Redirect to={loggedIn ? '/home' : '/login'} />
          </Switch>
          <PrivateRoute
            path="/new-scenario/complete"
            component={FinishedSetupScreen}
          />
        </main>
        <Footer />
      </StyledApp>
    )
  }
}

const mapStateToProps = (state: type.Store) => ({
  schemaReceived: Object.keys(state.schema).length > 2,
  form: state.form,
})

const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  initSchema: loadedData => dispatch(actions.initSchema(loadedData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
