import React from 'react'
import styled from '@emotion/styled'
import styles from '../styles'
import { WidthSetter } from '../styles/components'

const StyledFooter = styled('footer')({
  marginTop: 'auto',
  textAlign: 'left',
})

const StyledUtilityNav = styled('nav')({
  ...styles.colors.lightGrayBackground,
})
const StyledUtilityNavItem = styled('a')({
  display: 'inline-block',
  margin: '1rem 3rem 1rem 0',
  textDecoration: 'none',
  ...styles.colors.blackText,
})

const StyledSiteInfo = styled('section')({
  boxSizing: 'border-box',
  margin: '0.75rem 0',
  paddingBottom: '3rem',
})
const UndecoratedAnchorTag = styled('a')({
  textDecoration: 'none',
})
const FlexedWidthSetter = styled(WidthSetter)({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
})
const StyledOwnershipInfo = styled('section')({
  textAlign: 'right',
})
const StyledSiteInfoItem = styled('p')({
  margin: '0.75rem 0',
})

export default function Footer() {
  return (
    <StyledFooter>
      <StyledUtilityNav className="utility-nav">
        <styles.components.WidthSetter>
          <StyledUtilityNavItem href="https://www.cdc.gov/about/default.htm">
            About CDC
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://jobs.cdc.gov/">
            Jobs
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://www.cdc.gov/funding/">
            Funding
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://www.cdc.gov/Other/policies.html">
            Policies
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://www.cdc.gov/other/privacy.html">
            Privacy
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://www.cdc.gov/od/foia/">
            FOIA
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://www.cdc.gov/eeo/nofearact/index.htm">
            No Fear Act
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html">
            Vulnerability Disclosure Policy
          </StyledUtilityNavItem>
          <StyledUtilityNavItem href="https://oig.hhs.gov">
            OIG
          </StyledUtilityNavItem>
        </styles.components.WidthSetter>
      </StyledUtilityNav>
      <StyledSiteInfo className="site-info">
        <FlexedWidthSetter>
          <section className="contact-info">
            <StyledSiteInfoItem>
              1600 Clifton Road Atlanta, GA 30329-4027 USA
            </StyledSiteInfoItem>
            <StyledSiteInfoItem>
              1-800-CDC-INFO (800-232-4636), TTY: 888-232-6348
            </StyledSiteInfoItem>
            <StyledSiteInfoItem>
              <UndecoratedAnchorTag href="https://wwwn.cdc.gov/dcs/ContactUs/Form">
                Email CDC-INFO
              </UndecoratedAnchorTag>
            </StyledSiteInfoItem>
          </section>
          <StyledOwnershipInfo className="ownership-info">
            <StyledSiteInfoItem>
              U.S. Department of Health & Human Services
            </StyledSiteInfoItem>
            <StyledSiteInfoItem>HHS/Open</StyledSiteInfoItem>
            <StyledSiteInfoItem>USA.gov</StyledSiteInfoItem>
          </StyledOwnershipInfo>
        </FlexedWidthSetter>
      </StyledSiteInfo>
    </StyledFooter>
  )
}
