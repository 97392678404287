import utils from '../utils'
import { getState } from '../store'
import { evaluateConditions } from '../utils/form'
import * as type from '../type'

export default {
  evaluateConditionsFromState: (expandIf: type.DynamicCondition) => {
    const state: type.Store = getState()
    const compiledData = utils.form.compileform(state)

    return evaluateConditions(expandIf, compiledData)
  },
}
