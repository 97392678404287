import React from 'react'
import Chart from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import ReactTable from 'react-table'
import { handleCSVDownload, handleChartDownload } from '../utils/csv'
import styled from '@emotion/styled'
import styles from '../styles'
import utils from '../utils'
import * as type from '../type'

/* ChartJS */

Chart.defaults.global.defaultFontSize = 16
Chart.pluginService.register({
  beforeDraw: function(chartInstance) {
    var ctx = chartInstance.chart.ctx
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height)
  },
})

const generateColumns = csvDatapoint =>
  Object.keys(csvDatapoint).map(key => ({
    Header: key,
    accessor: key,
  }))

const Button = styled.button({
  display: 'block',
  margin: '0.75rem 0 0 auto',
  padding: '0',
  textAlign: 'right',
  ...styles.components.linkStyles,
  fontSize: '1.5rem',
})

type Props = {
  csvData: type.Cea
  tableProps: object
  csvFilename: string
}

const CeaOutput: React.SFC<Props> = ({ csvData, tableProps, csvFilename }) => {
  const CRATIO = 'cratio'

  // extract label ("baseline", "intervention001", etc.) of each dataset
  // using the first datapoint, not including "cratio"
  const datasetLabels = Object.keys(csvData[0]).filter(
    label => label !== CRATIO,
  )
  const numInterventions = datasetLabels.length - 1
  let colorCounter = 0
  const baseHue = 45
  const chartData = {
    // x-axis labels
    labels: csvData.map(datapoint => datapoint[CRATIO]),
    xAxisID: 'Willingness-to-Pay',
    yAxisID: '% of Iterations Cost-Effective',
    datasets: datasetLabels.map(label => {
      // generating non-repeating colors
      const hue =
        label === 'baseline'
          ? baseHue
          : baseHue + (++colorCounter * 360) / (1 + numInterventions)
      const color = `hsla(${hue}, 100%, 50%)`
      // dataset object
      return {
        label,
        data: csvData.map(datapoint => ({
          x: datapoint[CRATIO],
          y: datapoint[label],
        })),
        fill: false,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        showLine: true,
      }
    }),
  }
  const options = {
    title: {
      display: true,
      text: 'CE Acceptability Curve',
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Willingness-to-Pay',
          },
          ticks: {
            min: 0,
            max: 500000,
            stepSize: 50000,
            maxRotation: 25,
            minRotation: 25,
          },
          gridLines: { drawOnChartArea: false },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '% of Iterations Cost-Effective',
          },
          ticks: {
            min: 0,
            max: 1,
            stepSize: 0.1,
            callback: function(value) {
              return `${value * 100}%`
            },
          },
          gridLines: { drawOnChartArea: false },
        },
      ],
    },
    elements: {
      line: { tension: 0 },
    },
  }

  const invertedCsvData = utils.results.simpleInvert(csvData)
  const invertedColumns = generateColumns(invertedCsvData[0])

  return (
    <div id="cea-chart">
      <Scatter data={chartData} options={options} height={200} />
      <Button onClick={handleChartDownload('#cea-chart canvas', csvFilename)}>
        Download Chart as PDF
        <styles.components.LinkDownloadIcon />
      </Button>
      <hr />
      <ReactTable
        className="-striped -highlight"
        defaultPageSize={10}
        pageSizeOptions={[5, 10, 20, 25, 50]}
        data={invertedCsvData}
        columns={invertedColumns}
        {...tableProps}
      />
      <Button onClick={handleCSVDownload(csvData, csvFilename)}>
        Download Table as CSV
        <styles.components.LinkDownloadIcon />
      </Button>
    </div>
  )
}

export default CeaOutput
