// COMPONENTS
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// STATE
import * as actions from '../../store/actions'
// TYPES
import { RouteComponentProps } from 'react-router'
import * as type from '../../type'

type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = type.BuiltinPageConfig &
  RouteComponentProps &
  DispatchProps & {
    nextPagePath: string
  }

const handleNavigation = (history, path, cb) => () => {
  if (typeof cb === 'function') cb()
  history.push(path)
}

const FormIntroductionPage: React.SFC<Props> = ({
  title,
  message,
  history,
  nextPagePath,
  resetForm,
  button_text,
}) => (
  <div>
    <h1>{title || 'New Form'}</h1>
    <p>{message || ''}</p>
    <button onClick={handleNavigation(history, nextPagePath, resetForm)}>
      {button_text || 'Get Started'}
    </button>
  </div>
)

const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  resetForm: () => dispatch(actions.resetData()),
})

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(FormIntroductionPage))
