import { ACTION } from '../actions'
import * as type from '../../type'

export const initialState = []

export default function populationDatasets(
  state: Array<type.PopulationDatasetMetadata> = initialState,
  action: type.Action,
) {
  switch (action.type) {
    case ACTION.GET_POPULATION_DATASETS:
      return action.payload
    default:
      return state
  }
}
