import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import schema from './reducers/schema'
import populationDatasets, {
  initialState as initialPopulationDatasets,
} from './reducers/populationDatasets'
import configFiles, {
  initialState as initialConfigFiles,
} from './reducers/configFiles'
import notifications, {
  initialState as initialNotifications,
} from './reducers/notifications'
import form from './reducers/form'
import results from './reducers/results'
import auth from './reducers/auth'
import portfolio from './reducers/portfolio'
import {
  loadSchemaState,
  loadDataState,
  loadResultsState,
  loadPortfolioState,
  savePortfolioState,
} from '../utils/sessionStorage'
import { loadAuthState, saveAuthState } from '../utils/localStorage'
import deepClone from 'object-assign-deep'
import * as type from '../type'

const persistedState = loadSchemaState()
const persistedFormData = loadDataState()
const persistedResults = loadResultsState()
const persistedAuthState = loadAuthState()
const persistedPortfolioState = loadPortfolioState()

const composeEnhancers = composeWithDevTools({})

const store = createStore(
  combineReducers({
    schema,
    notifications,
    populationDatasets,
    configFiles,
    form,
    results,
    auth,
    portfolio,
  }),
  {
    schema: persistedState,
    notifications: initialNotifications,
    populationDatasets: initialPopulationDatasets,
    configFiles: initialConfigFiles,
    form: persistedFormData,
    results: persistedResults,
    auth: persistedAuthState,
    portfolio: persistedPortfolioState,
  },
  composeEnhancers(applyMiddleware(thunk)),
)

export const getState: () => type.Store = store.getState

const selectToken = (tokenKey: string, state: type.Store) =>
  state.auth[tokenKey]

let currentTokens = deepClone({}, getState().auth)
function handleAuthChanges() {
  const previousTokens = deepClone({}, currentTokens)
  for (let key in currentTokens) {
    currentTokens[key] = selectToken(key, getState())
  }
  for (let key in currentTokens) {
    if (previousTokens[key] !== currentTokens[key]) {
      saveAuthState(currentTokens)
      break
    }
  }
}

let currentPortfolio = JSON.stringify(getState().portfolio)
function handlePortfolioChanges() {
  const previousPortfolio = currentPortfolio
  const { portfolio } = getState()
  currentPortfolio = JSON.stringify(portfolio)
  if (previousPortfolio !== currentPortfolio) {
    savePortfolioState(portfolio)
  }
}

function handleChanges() {
  handleAuthChanges()
  handlePortfolioChanges()
}

store.subscribe(handleChanges)

export default store
