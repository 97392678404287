// COMPONENTS
import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import CoreOutputs from './CoreOutputs'
import AdditionalOutputs from './AdditionalOutputs'
import { JsonTable } from 'react-json-to-html'
// STATE
import { connect } from 'react-redux'
// UTILS
import utils from '../utils'
// TYPES
import * as type from '../type'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'

/* STATE */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps

/* STYLES */
const StyledOutputContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
})
const StyledOutputSection = styled('section')({
  width: '75rem',
  marginRight: '2rem',
})
const SummarySection = styled('section')({
  margin: '0rem 5rem 0 2rem',
  width: '37rem',
  minWidth: '35rem',
  textAlign: 'left',

  td: {
    backgroundColor: 'red',
  },
})
const StyledUtilityMenu = styled('section')({
  margin: '0 4px 4rem',
  display: 'flex',
})
const StyledDownloadButton = styled('button')({
  ...styles.components.linkStyles,
  padding: '0',
})
// const StyledDeleteButton = styled('button')({
//   ...styles.components.linkStyles,
//   marginLeft: '2rem',
//   padding: '0',
//   ...styles.colors.blackText,
// })
const StyledInfo = styled('aside')({
  padding: '0 4px',
  marginBottom: '1rem',
  fontSize: '1.5rem',
})
const coreOutputsPosition = {
  marginBottom: '7.5rem',
}
const additionalOutputsPosition = {
  marginBottom: '7.5rem',
}

class Output extends Component<Props> {
  render() {
    const {
      summary,
      created_at,
      core,
      additional,
      raw_files,
      config,
    } = this.props
    const scenario_name =
      summary['Basic Configurations']['Name of the scenario']
    summary['Basic Configurations']['Created on'] = created_at
    return (
      <StyledOutputContainer>
        {Object.keys(config).length > 0 && (
          <>
            <SummarySection>
              {summary && (
                <>
                  <JsonTable
                    json={{
                      'Basic Configurations': summary['Basic Configurations'],
                    }}
                  />
                  <JsonTable
                    json={{ 'Modified Sections': summary['Modified Sections'] }}
                  />
                </>
              )}
            </SummarySection>
            <StyledOutputSection>
              <CoreOutputs
                core={core}
                scenarioName={scenario_name}
                positionStyles={coreOutputsPosition}
              />
              <styles.components.OrangeH2>
                Simulation Output Files
              </styles.components.OrangeH2>
              <StyledInfo>
                To preserve space, output files will automatically be deleted
                from your account in{' '}
                <styles.components.RedText>7</styles.components.RedText> days.
                <br />
                We recommend downloading simulation output files upon
                completion.
              </StyledInfo>
              <StyledUtilityMenu>
                <StyledDownloadButton
                  onClick={() =>
                    utils.csv.handleAllDownloads(summary, created_at, raw_files)
                  }>
                  Download Output Files
                  <styles.components.LinkDownloadIcon />
                </StyledDownloadButton>
                {/* <StyledDeleteButton>Delete</StyledDeleteButton> */}
              </StyledUtilityMenu>
              <AdditionalOutputs
                additional={additional}
                scenarioName={scenario_name}
                positionStyles={additionalOutputsPosition}
                summary={summary}
              />
            </StyledOutputSection>
          </>
        )}
      </StyledOutputContainer>
    )
  }
}

const mapStateToProps = (state: type.Store) => ({
  ...state.results.configJSON,
  core: {
    cost: state.results.cost,
  },
  additional: {
    prevalence: state.results.prevalence,
    mean_prevalence: state.results.mean_prevalence,
    incidence: state.results.incidence,
    cea: state.results.cea,
    mean_cea: state.results.mean_cea,
    ppc: state.results.ppc,
  },
  raw_files: {
    cost_raw: state.results.cost_raw,
    prevalence_raw: state.results.prevalence_raw,
    mean_prevalence_raw: state.results.mean_prevalence_raw,
    incidence_raw: state.results.incidence_raw,
    cea_raw: state.results.cea_raw,
    mean_cea_raw: state.results.mean_cea_raw,
    ppc_raw: state.results.ppc_raw,
  },
})
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Output)
