import * as colors from './colors'

export const scenariosPortfolioBorder = `1px ${
  colors.grayBackground.backgroundColor
} solid`

// use with position fixed or absolute
export const fullScreenStyles = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

// use with position fixed or absolute
export const centeredScreenStyles = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

export const spaceRight = {
  margin: '0 0.6rem 0 0',
}
