// COMPONENT
import React, { FC, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import UploadPopulationDataset from './UploadPopulationDataset'
import { ActionButton, DoubleCheckButton } from './ScenariosPortfolio'
// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// UTILS
import utils from '../utils'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps

/* STYLES */
const StyledHome = styled('section')({
  display: 'flex',
  justifyContent: 'center',
})

const StyledScenariosPortfolio = styled('section')({
  borderBottom: 'none',
  marginBottom: '40px',
})

const PortfolioGridContainer = styled('div')(
  ({ theme: computedGridStyles }) => ({
    display: 'grid',
    fontSize: '14px',
    width: '100%',
    maxHeight: '80vh',
    overflow: 'scroll',
    ...computedGridStyles,
  }),
)

const gridItemStyles = {
  padding: '0 1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const TableHeaderCel = styled('div')(({ theme }) => ({
  backgroundColor: '#b6b6b677',
  borderTop: '3px solid #00000000',
  borderBottom: '3px solid #00000000',
  boxSizing: 'border-box',
  fontWeight: 'bold',
  ...gridItemStyles,
  ...theme,
}))

const TableCell = styled('div')(({ theme }) => ({
  ...gridItemStyles,
  ...theme,
}))

const EmptyPortfolio = styled('div')(({ theme }) => ({
  ...gridItemStyles,
  ...theme,
  fontWeight: 'bold',
  padding: '1rem',
  backgroundColor: '#f6f6f6',
}))

const TableContent = styled.p({
  margin: '0.5rem',
  maxWidth: '550px',
})
const ButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

let FilterContainer = styled.div({
  display: 'flex',
  padding: '5px',
})

const FilterButton = styled('button')(({ theme }) => ({
  display: 'inline-block',
  margin: '0 10px',
  cursor: 'pointer',
  ...theme,
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  font: 'inherit',
  outline: 'inherit',
  '&:hover': {
    background: 'none',
    color: 'inherit',
  },
}))

let headings = {
  uploaded_at: 'Uploaded at',
  filename: 'Filename',
  description: 'Description',
  type: 'Diabetes Type',
  uuid: 'Actions',
}

let populationDatasetTypes = {
  t1d: 'Type 1',
  t2d: 'Type 2',
  pre: 'Prediabetes',
  screen: 'Screening',
}

let typeFilterOptions = {
  all: 'All',
  ...populationDatasetTypes,
}

/* COMPONENTS */
let uniqueKey = 0
const PortfolioTable: FC<Props> = ({
  populationDatasets,
  downloadPopulationDataset,
  editPopulationDataset,
  sharePopulationDataset,
  deletePopulationDataset,
}) => {
  const [selectedType, setSelectedType] = useState('all')
  const [filteredDatasets, setFilteredDatasets] = useState(populationDatasets)
  const [currentDownloadUuid, setCurrentDownloadUuid] = useState(false)

  useEffect(() => {
    applyFilter()
  }, [selectedType])

  useEffect(() => {
    setFilteredDatasets(populationDatasets)
  }, [populationDatasets])

  const handleDownload = async uuid => {
    setCurrentDownloadUuid(uuid)
    await downloadPopulationDataset(uuid)
    setCurrentDownloadUuid(false)
  }

  const applyFilter = () => {
    let filtered
    if (selectedType === 'all') filtered = populationDatasets
    else
      filtered = populationDatasets.filter(
        dataset => dataset.type === selectedType,
      )
    setFilteredDatasets(filtered)
  }

  const columnCount = Object.keys(headings).length
  const rowCount = populationDatasets.length + 1
  return (
    <div>
      <FilterContainer>
        <b>Type:</b>
        {Object.entries(typeFilterOptions).map(([key, type]) => {
          return (
            <FilterButton
              role="button"
              key={key}
              //selected={key===selectedType}
              theme={{
                textDecoration: key === selectedType ? 'underline' : 'none',
              }}
              aria-pressed={key === selectedType ? true : false}
              onClick={() => setSelectedType(key)}>
              {type}
            </FilterButton>
          )
        })}
      </FilterContainer>
      <PortfolioGridContainer
        className="scroll-box"
        theme={{
          gridTemplateColumns: `repeat(${columnCount}, auto)`,
          gridTemplateRows: `repeat(${rowCount}, auto)`,
        }}>
        {Object.entries(headings).map(([key, heading]) => {
          return (
            <TableHeaderCel
              key={uniqueKey++}
              theme={{}}
              onClick={() => console.log('sort')}>
              <TableContent>{heading}</TableContent>
            </TableHeaderCel>
          )
        })}

        {filteredDatasets.length ? (
          filteredDatasets.map((population, i) =>
            Object.keys(headings).map(key => {
              let value
              if (key === 'uuid' && population.creator !== null) {
                value = (
                  <ButtonContainer>
                    <ActionButton
                      disabled={currentDownloadUuid}
                      theme={{}}
                      onClick={() => handleDownload(population.uuid)}>
                      {currentDownloadUuid === population.uuid
                        ? 'Downloading'
                        : 'Download'}
                    </ActionButton>
                    <ActionButton
                      disabled={false}
                      theme={{}}
                      onClick={() => editPopulationDataset(population.uuid)}>
                      Edit
                    </ActionButton>
                    <ActionButton
                      disabled={false}
                      theme={{}}
                      onClick={() => sharePopulationDataset(population.uuid)}>
                      Share
                    </ActionButton>
                    <DoubleCheckButton
                      onClick={() => deletePopulationDataset(population.uuid)}
                      theme={{ color: '#d60000' }}>
                      Delete
                    </DoubleCheckButton>
                  </ButtonContainer>
                )
              } else if (key === 'uuid' && population.creator === null) {
                value = (
                  <ButtonContainer>
                    <ActionButton
                      disabled={currentDownloadUuid}
                      theme={{}}
                      onClick={() => handleDownload(population.uuid)}>
                      {currentDownloadUuid === population.uuid
                        ? 'Downloading'
                        : 'Download'}
                    </ActionButton>
                  </ButtonContainer>
                )
              } else if (key === 'uploaded_at') {
                value = (
                  <TableContent>
                    {utils.portfolio.abbreviateDate(population.uploaded_at)}
                  </TableContent>
                )
              } else if (key === 'type') {
                value = (
                  <TableContent>
                    {populationDatasetTypes[population.type]}
                  </TableContent>
                )
              } else {
                value = <TableContent>{population[key]}</TableContent>
              }
              return (
                <TableCell
                  key={uniqueKey++}
                  theme={{
                    backgroundColor: i % 2 ? '#ebebeb77' : '#ffffff77',
                  }}>
                  {value}
                </TableCell>
              )
            }),
          )
        ) : (
          <EmptyPortfolio
            theme={{
              gridColumnStart: 1,
              gridColumnEnd: Object.keys(headings).length + 1,
            }}>
            <TableContent>---None---</TableContent>
          </EmptyPortfolio>
        )}
      </PortfolioGridContainer>
    </div>
  )
}

const PopulationDatasetsDashboard: FC<Props> = props => {
  useEffect(() => {
    props.loadPopulationDatasets()
  }, [])
  return (
    <StyledHome>
      <StyledScenariosPortfolio>
        <styles.components.OrangeH2>
          Population Datasets
        </styles.components.OrangeH2>
        <PortfolioTable {...props} />
        <UploadPopulationDataset />
      </StyledScenariosPortfolio>
    </StyledHome>
  )
}

const mapStateToProps = store => ({
  populationDatasets: store.populationDatasets,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  loadPopulationDatasets: async () =>
    dispatch(await actions.loadPopulationDatasets()),
  downloadPopulationDataset: async (uuid: string, cb = response => {}) =>
    dispatch(await actions.downloadPopulationDataset(uuid, cb)),
  editPopulationDataset: async (uuid: string, cb = response => {}) =>
    dispatch(await actions.editPopulationDataset(uuid, cb)),
  sharePopulationDataset: async (uuid: string, cb = response => {}) =>
    dispatch(await actions.sharePopulationDataset(uuid, cb)),
  deletePopulationDataset: async (uuid: string, cb = response => {}) =>
    dispatch(await actions.deletePopulationDataset(uuid, cb)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PopulationDatasetsDashboard),
)
