export const black = '#444'
export const white = '#f0f0f0'
export const socialIcon = '#666'

export const blackText = { color: black }
export const whiteText = { color: white }
export const blue = '#26b'

export const whiteBackground = { backgroundColor: white }
export const lightGrayBackground = { backgroundColor: '#eaeaea' }
export const grayBackground = { backgroundColor: '#d0d0d0' }
