import { ACTION } from '../actions'
import * as type from '../../type'

const initialState = {
  access: null,
  refresh: null,
}

export default function schema(
  state: type.AuthState = initialState,
  action: type.Action,
) {
  switch (action.type) {
    case ACTION.LOGIN_USER:
      return action.payload
    case ACTION.LOGOUT_USER:
      return initialState
    case ACTION.REFRESH_USER_ACCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
