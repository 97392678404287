import React from 'react'
import utils from '../../utils'
import shortid from 'shortid'
import * as type from '../../type'

type Props = ArrayTemplateRenderProps | GroupRenderProps
type ArrayTemplateRenderProps = {
  items: Array<type.ArrayFieldTemplateItem>
  uiOptions: type.UIOptions
}
type GroupRenderProps = {
  items: Array<GroupItem>
  uiOptions: type.GroupOptions
}
type GroupItem = {
  children: React.ReactElement
}

const AccordionArrayFieldTemplate: React.SFC<Props> = ({
  items,
  uiOptions,
}) => (
  <ul className="usa-accordion">
    {map(items, (item: type.ArrayFieldTemplateItem | GroupItem, i: number) => {
      const { children } = item
      const contentId = `usa-accordion-item-${shortid.generate()}`
      const { default_collapsed } = uiOptions
      return (
        <li key={i}>
          <button
            className="usa-accordion-button"
            aria-expanded={
              typeof default_collapsed === 'boolean'
                ? !default_collapsed
                : i === 0
            }
            aria-controls={contentId}>
            {utils.misc.getHeaderButtonText(uiOptions.header_buttons, i)}
          </button>
          <div
            id={contentId}
            className="usa-accordion-content"
            aria-hidden={
              typeof default_collapsed === 'boolean' ? default_collapsed : i > 0
            }>
            {children}
          </div>
        </li>
      )
    })}
  </ul>
)

// workaround to map disjoint union array type
function map<I extends type.ArrayFieldTemplateItem | GroupItem>(
  items: I[],
  cb: (item: I, i: number) => React.ReactElement,
) {
  return items.map(cb)
}

export default AccordionArrayFieldTemplate
