// COMPONENTS
import React from 'react'
import FormPage from './FormPage'
import FormIntroductionPage from './FormIntroductionPage'
import FormDatasetPicker from './FormDatasetPicker'
import FormSummaryPage from './FormSummaryPage'
import { Switch, Route, Redirect } from 'react-router-dom'
import FinishedSetupScreen from './FinishedSetupScreen'
import ScrollToTop from '../ScrollToTop'
// STATE
import selectors from '../../store/selectors'
// TYPES
import * as type from '../../type'
import { RouteComponentProps } from 'react-router'

type FormProps = type.FormConfig & RouteComponentProps

type BuiltInPages = {
  [key: string]: BuiltInPageRenderer
}
type BuiltInPageRenderer = (
  configs: type.BuiltinPageConfig,
  pageButtonPaths: type.PageButtonPaths,
  visiblePages: Array<type.Page>,
  page: type.Page,
) => React.ReactElement
function isBuiltInPageRenderer(thing: any): thing is BuiltInPageRenderer {
  return typeof thing === 'function'
}

const getAdjacentPagePaths = (
  visiblePages: Array<type.Page>,
  i: number,
  match: RouteComponentProps['match'],
): type.PageButtonPaths => {
  const prevPage = visiblePages[i - 1]
  const prevPagePath = prevPage && `${match.path}${prevPage.url_path}`
  const nextPage = visiblePages[i + 1]
  const nextPagePath = nextPage && `${match.path}${nextPage.url_path}`
  return {
    prevPagePath,
    nextPagePath,
  }
}

const builtInPages: BuiltInPages = {
  introduction: (
    configs: type.BuiltinPageConfig,
    { prevPagePath, nextPagePath }: type.PageButtonPaths,
  ) => <FormIntroductionPage {...configs} nextPagePath={nextPagePath} />,
  'dataset-picker': (
    configs: type.BuiltinPageConfig,
    pageButtonPaths: type.PageButtonPaths,
    visiblePages: Array<type.Page>,
    page: type.Page,
  ) => (
    <FormDatasetPicker
      {...{
        ...configs,
        ...pageButtonPaths,
        visiblePages,
        schema: page.fieldsets as type.Fieldsets,
      }}
    />
  ),
  summary: (
    configs: type.BuiltinPageConfig,
    pageButtonPaths: type.PageButtonPaths,
    visiblePages: Array<type.Page>,
  ) => (
    <FormSummaryPage {...configs} {...pageButtonPaths} {...{ visiblePages }} />
  ),
}

const renderFormPage = (match: RouteComponentProps['match']) => (
  page: type.Page,
  i: number,
  visiblePages: Array<type.Page>,
) => {
  const adjacentPagePaths = getAdjacentPagePaths(visiblePages, i, match)
  const builtInPageConfig = type.isBuiltinPage(page) && page.built_in_page
  const builtInPage =
    type.isBuiltinPageConfig(builtInPageConfig) &&
    builtInPages[builtInPageConfig.type]
  return (
    <Route
      exact={!page.url_path || page.url_path === '/'}
      path={`${match.path}${page.url_path}`}
      key={i}
      render={() =>
        type.isBuiltinPage(page) &&
        type.isBuiltinPageConfig(builtInPageConfig) &&
        isBuiltInPageRenderer(builtInPage)
          ? builtInPage(
              builtInPageConfig,
              adjacentPagePaths,
              visiblePages,
              page,
            )
          : type.isFormPage(page) && (
              <FormPage {...page} {...adjacentPagePaths} />
            )
      }
    />
  )
}

const Form: React.SFC<FormProps> = props => {
  const { schemaLayout, match } = props
  const visiblePages = !type.isEmptyObject(schemaLayout)
    ? schemaLayout.pages.filter((page: type.Page) =>
        page.expand_if
          ? selectors.evaluateConditionsFromState(page.expand_if)
          : true,
      )
    : []
  const finishSetupPath = `${match.path}/:placeholder/form-complete`
  return (
    <ScrollToTop>
      <Switch>
        {visiblePages.map(renderFormPage(match))}
        <Redirect to={`${match.path}${visiblePages[0].url_path}`} />
      </Switch>
      <Route path={finishSetupPath} component={FinishedSetupScreen} />
    </ScrollToTop>
  )
}

export default Form
