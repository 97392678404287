import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { RouteComponentProps } from 'react-router'
import * as api from '../../api'
import * as actions from '../../store/actions'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import styles from '../../styles'
import * as type from '../../type'

/* COMPONENT SPECIFIC PROPS */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = RouteComponentProps & StateProps & DispatchProps

/* STYLES */
const StyledFinishedSetupScreen = styled('div')({
  position: 'fixed',
  ...styles.misc.fullScreenStyles,
  zIndex: 1000,
})
const shadowGray = '#666'
const StyledModal = styled('aside')({
  position: 'absolute',
  ...styles.misc.centeredScreenStyles,
  zIndex: 1002,
  width: '40vw',
  minWidth: '52rem',
  border: `1px solid ${shadowGray}`,
  borderRadius: '0.3rem',
  boxShadow: `0 0 16px ${shadowGray}`,
  ...styles.colors.whiteBackground,
  textAlign: 'center',
  lineHeight: '2.3rem',
})
const StyledHeading = styled('h2')({
  margin: '3rem auto 2.2rem',
  fontWeight: 'normal',
})
const StyledForm = styled('form')({
  margin: '0.75rem 3.7rem 0',
  height: '13.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const loadingSpinnerStyles = css`
  margin: 2.5rem auto;
`

/* commented styles below for temporary removal of email prompt */
// const StyledFieldset = styled('fieldset')({ // with email prompt
//   border: '0',
// })
// const StyledCheckbox = styled(styles.components.Checkbox)({}) // with email prompt
// const StyledLabel = styled('label')({}) // with email prompt
const StyledFinishButton = styled('button')({
  // alignSelf: 'flex-end', // with email prompt
  margin: '3rem auto', // without email prompt
  width: '20rem', // without email prompt
  minHeight: '4rem',
  ...styles.components.primaryButtonStyles,
  paddingTop: '0.75rem',
  paddingBottom: '0.75rem',
})
const StyledBackgroundDimmer = styled('div')({
  position: 'absolute',
  ...styles.misc.fullScreenStyles,
  zIndex: 1001,
  background: 'hsla(0, 0%, 0%, 0.3)',
})

class FinishedSetupScreen extends Component<Props> {
  closeModal = (e: any) => {
    e.preventDefault()
  }

  handleClick = (hadError: boolean) => (e: any) => {
    e.preventDefault()
    const { history, location, resetData } = this.props
    let url = '/home'
    const splitUrl = location.pathname.split('/')
    if (hadError) {
      url = splitUrl.slice(0, splitUrl.length - 1).join('/')
    } else {
      url = '/output'
      resetData
    }
    history.push(url)
  }

  goHome = (e: any) => {
    e.preventDefault()
    const { history, resetData } = this.props
    resetData
    history.push('/home')
  }

  componentWillUnmount = this.props.endWait

  render() {
    const { status, errorMessage } = this.props
    const complete = status === api.successMessage
    const hadError = !!errorMessage
    return (
      <StyledFinishedSetupScreen>
        <StyledModal role="dialog">
          <StyledHeading>
            {status || 'Sending Simulation Configuration'}
          </StyledHeading>
          {complete ? (
            ''
          ) : hadError ? (
            errorMessage || ''
          ) : (
            <ClipLoader
              css={loadingSpinnerStyles}
              sizeUnit={'rem'}
              size={4}
              color={'#bbb'}
            />
          )}
          <StyledForm>
            {complete ? (
              <StyledFinishButton onClick={this.handleClick(hadError)}>
                {`Close and ${hadError ? 'return to form' : 'view results'}`}
              </StyledFinishButton>
            ) : (
              <>
                {hadError || <>Simulation status is available on Home page</>}
                <StyledFinishButton onClick={this.goHome}>
                  Return to Home
                </StyledFinishButton>
              </>
            )}
          </StyledForm>
        </StyledModal>
        <StyledBackgroundDimmer />
      </StyledFinishedSetupScreen>
    )
  }
}

const mapStateToProps = (store: type.Store) => ({
  ...store.results,
})

const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  resetData: () => dispatch(actions.resetData()),
  endWait: () => dispatch(actions.endWait()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FinishedSetupScreen),
)
