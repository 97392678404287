// COMPONENTS
import React from 'react'
import ScenariosPortfolio from './ScenariosPortfolio'

// STATE
import { connect } from 'react-redux'
import * as actions from '../store/actions'
// TYPE
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
import { History } from 'history'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'
//import { Link } from 'react-router-dom'

/* TYPES */
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = RouteComponentProps & DispatchProps

/* STYLES */
const StyledHome = styled('section')({
  display: 'flex',
  justifyContent: 'center',
})
const StyledScenariosContainer = styled('section')({
  margin: '0 0 9rem',
  padding: '0 10vw',
})
const NewSimulationContainer = styled('section')({})

const StyledAddScenarioButton = styled('button')({
  textAlign: 'center',
})
const ButtonContainer = styled('div')({
  textAlign: 'center',
})

const handleNewScenario = (
  resetForm: DispatchProps['resetForm'],
  history: History,
) => async (e: React.MouseEvent) => {
  await resetForm()
  history.push('/new-scenario')
}

const Home: React.SFC<Props> = props => {
  return (
    <StyledHome>
      <StyledScenariosContainer>
        <ScenariosPortfolio />
        <NewSimulationContainer>
          <styles.components.OrangeH2>
            Begin a New Simulation
          </styles.components.OrangeH2>
          <ButtonContainer>
            <StyledAddScenarioButton
              onClick={handleNewScenario(props.resetForm, props.history)}>
              New Scenario
            </StyledAddScenarioButton>
            <StyledAddScenarioButton
              onClick={() => props.history.push('/config-file-dashboard')}>
              Shared Scenarios
            </StyledAddScenarioButton>
          </ButtonContainer>
        </NewSimulationContainer>
      </StyledScenariosContainer>
    </StyledHome>
  )
}

const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  resetForm: () => dispatch(actions.resetData()),
})

export default connect(null, mapDispatchToProps)(Home)
