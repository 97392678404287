import { dereference } from '@jdw/jst'
import deepClone from 'object-assign-deep'
import * as type from '../type'

const forEachPropIn = (obj, cb) =>
  Object.values(obj.properties).forEach(property => cb(property))

function augmentProperties(originalSchema: type.JSONSchema) {
  const schema = deepClone({}, originalSchema)
  Object.entries(schema.properties).forEach(([key, property]) => {
    if (type.isSubschema(property) && property._watch_for_changes) {
      traverseSubproperties(property, { __key: key, ...property })
    }
  })
  return schema
}

function traverseSubproperties(
  property: type.Subschema,
  watcher: type.Subschema,
) {
  if (property.type === 'array' && type.isSubschema(property.items)) {
    forEachPropIn(property.items, subProp =>
      traverseSubproperties(subProp, watcher),
    )
  } else if (property.type === 'object') {
    forEachPropIn(property, subProp => traverseSubproperties(subProp, watcher))
  } else {
    augmentSubproperty(property, watcher)
  }
}

function augmentSubproperty(
  property: type.JSONSchema,
  watcher: type.JSONSchema,
) {
  property.watcher =
    typeof watcher._watch_for_changes === 'string'
      ? watcher._watch_for_changes
      : watcher.title || watcher.description || watcher.__key
  property.__initial_default = property.default
}

// adds keys to layout that saves the page data locations with respect to the original schema
function augmentLayout(originalSchema: type.JSONSchema) {
  const schema = deepClone({}, originalSchema)
  let pageNum = 0
  let groupIndices: Array<number> = []
  // mapping of schema keys to layout keys
  let keyMap: type.StringMap = {}

  schema['ui:layout'].pages.forEach((page: type.Page, n: number) => {
    pageNum = n
    if (type.isFormPage(page)) augmentFieldsets(page.fieldsets)
  })

  return { schema, keyMap }

  function augmentFieldsets(fieldsets: type.Fieldsets) {
    if (Array.isArray(fieldsets)) {
      augmentGroup(fieldsets)
    } else {
      augmentFieldset(fieldsets)
    }
    groupIndices.pop()
  }
  function augmentGroup(fieldsets: type.Fieldsets) {
    fieldsets.forEach((fieldset: any, fieldsetNum: number) => {
      groupIndices.push(fieldsetNum)
      augmentFieldsets(fieldset)
    })
  }
  function augmentFieldset(fieldSet: type.Fieldset) {
    if (!fieldSet || fieldSet.hasOwnProperty('built_in_page')) return

    const refString = fieldSet['$ref']
    if (refString && refString.slice(0, 13) === '#/properties/') {
      fieldSet.layout_key = `pages.${pageNum}.fieldsets.${groupIndices.join(
        '.',
      )}`
      fieldSet.schema_key = refString.slice(13)
      keyMap[fieldSet.schema_key] = fieldSet.layout_key
    } else
      throw "Error (ui:layout) - $refs in fieldsets must point to a field in schema's properties."
  }
}

function derefSchema(schema: type.ConfigSchema) {
  return dereference(schema)
}

/**
 * getSchema
 */
function getSchema() {
  return fetch(
    `https://raw.githubusercontent.com/RTIInternational/comprehensive-model-schema/master/schema.json`,
  ).then(res => res.json())
}

/**
 * deepSelectiveMap
 *
 * Takes an object and performs callback function modifying any non-object values
 * recursively works through nested objects
 * removes property when callback returns key as null or undefined
 *
 * @param obj
 * @param cb : function takes key: string and value and returns modified value
 */
function deepSelectiveMap(
  obj: type.SavedStoreItem,
  cb: (
    key: string,
    value: any,
  ) => { key: string | null | undefined; value?: any },
) {
  const mappedObj = deepClone(Array.isArray(obj) ? [] : {}, obj)
  Object.keys(obj).forEach((key: string) => {
    const value = obj[key]
    if (Array.isArray(value)) {
      mappedObj[key] = value.map(element => {
        return typeof element === 'object'
          ? deepSelectiveMap(element, cb)
          : element
      })
    } else if (value && typeof value === 'object') {
      mappedObj[key] = deepSelectiveMap(value, cb)
    } else {
      const { key: newKey, value: newValue } = cb(key, value)
      if (newKey !== null && newKey !== undefined) mappedObj[newKey] = newValue
      else delete mappedObj[key]
    }
  })
  return mappedObj
}

export {
  augmentProperties,
  augmentLayout,
  derefSchema,
  deepSelectiveMap,
  getSchema,
}
