// COMPONENTS
import React from 'react'
// STATE
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { login } from '../store/actions'
// TYPES
import * as type from '../type'
import { RouteComponentProps } from 'react-router'
// STYLE
import styled from '@emotion/styled'
import styles from '../styles'

/* COMPONENT SPECIFIC PROPS */
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = RouteComponentProps<any> & StateProps & DispatchProps
type LoginState = {
  loginFailed: boolean
  loginMessage: String | undefined
}

/* STYLES */
const { blackText, whiteText } = styles.colors

const StyledSection = styled('section')({
  width: '24rem',
  margin: 'auto',
})

const welcomePromptStyles: type.CSSObject = {
  margin: '0.6rem 0',
  fontSize: '2.4rem',
  fontWeight: 'normal',
  ...blackText,
}
const StyledWelcomePrompt = styled('div')`
  margin: 5.1rem 0 2.4rem;
`
const StyledWelcome = styled('h2')(welcomePromptStyles)
const StyledSigninPrompt = styled('p')(welcomePromptStyles)
const StyledFailedLoginMessage = styled('p')({
  color: 'red',
  fontSize: '1rem',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: '-1rem',
})

const StyledFieldset = styled('fieldset')`
  border: none;
  padding: 0;
`
const constantWidth: type.CSSObject = {
  display: 'block',
  width: '100%',
  boxSizing: 'border-box',
}
const fieldHeight = { height: '4rem' }
const borderColor = '#aaa'
const buttonColor = '#666'
const roundedCorners = { borderRadius: '0.75rem' }
const StyledLabel = styled('label')({
  marginTop: '0.75rem',
  paddingLeft: '0.75rem',
  ...constantWidth,
  textAlign: 'left',
  ...blackText,
})
const StyledInput = styled('input')({
  ...fieldHeight,
  ...constantWidth,
  padding: '0 1.5rem',
  border: `solid 1px ${borderColor}`,
  ...roundedCorners,
})
const StyledSignupLinkContainer = styled('p')({
  margin: '0.15rem 0 0 auto',
  width: 'fit-content',
  paddingBottom: '0.05rem',
  textAlign: 'right',
  fontSize: '1rem',
})
const StyledSignupLink = styled(Link)({
  borderBottom: `1.3px solid ${buttonColor}`,
})

const StyledSigninButton = styled('button')({
  margin: '2.3rem 0 8rem',
  ...fieldHeight,
  ...constantWidth,
  backgroundColor: buttonColor,
  ...roundedCorners,
  ...whiteText,
  fontSize: '1.5rem',
})

class Login extends React.Component<Props, LoginState> {
  username
  password

  constructor(props) {
    super(props)
    this.state = { loginFailed: false, loginMessage: '' }
    this.username = React.createRef()
    this.password = React.createRef()
  }
  signIntoHome = async (e: React.MouseEvent) => {
    e.preventDefault()
    const loginAttempt = await this.props.handleLogin({
      username: this.username.current.value,
      password: this.password.current.value,
    })
    if (loginAttempt.status) {
      if (this.state.loginFailed) {
        this.setState({ loginFailed: false, loginMessage: '' })
      }
      this.props.history.push('/home')
    } else {
      this.setState({ loginFailed: true, loginMessage: loginAttempt.message })
    }
  }

  render() {
    return (
      <StyledSection className="login">
        <StyledWelcomePrompt>
          <StyledWelcome>Welcome!</StyledWelcome>
          <StyledSigninPrompt className="signin-prompt">
            Please sign in:
          </StyledSigninPrompt>
        </StyledWelcomePrompt>
        <form>
          <StyledFieldset>
            <StyledLabel htmlFor="user_id">User ID</StyledLabel>
            <StyledInput
              name="User ID"
              id="user_id"
              type="text"
              required
              ref={this.username}
            />
            <StyledLabel htmlFor="password">Password</StyledLabel>
            <StyledInput
              name="Password"
              type="password"
              id="password"
              required
              ref={this.password}
            />
            <StyledSignupLinkContainer>
              <StyledSignupLink to="sign-up">
                Don't have an account? Sign up here.
              </StyledSignupLink>
            </StyledSignupLinkContainer>
            {this.state.loginFailed && (
              <StyledFailedLoginMessage>
                {this.state.loginMessage !== ''
                  ? this.state.loginMessage
                  : 'Incorrect username or password.'}
              </StyledFailedLoginMessage>
            )}
            <StyledSigninButton type="submit" onClick={this.signIntoHome}>
              Sign In
            </StyledSigninButton>
          </StyledFieldset>
        </form>
      </StyledSection>
    )
  }
}

const mapStateToProps = (state: type.Store) => ({
  state,
})
const mapDispatchToProps = (dispatch: type.TDispatch) => ({
  handleLogin: (credentials: type.LoginCredentials) =>
    dispatch(login(credentials)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
