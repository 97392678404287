import * as type from '../type'

const defaultRowHeaderKey = ' '
export const simpleInvert = (
  csv: type.CSV,
  rowHeaderKey = defaultRowHeaderKey,
): type.CSV => {
  const currentRow1 = csv[0]
  const invertedRowHeaders = Object.keys(currentRow1).filter(
    key => key !== rowHeaderKey,
  )
  const invertedColumnHeaders = csv.map(row => row[rowHeaderKey])
  const invertedCsv = invertedRowHeaders.map(rowHeader => ({
    [defaultRowHeaderKey]: rowHeader,
    ...invertedColumnHeaders.reduce((column, columnHeader) => {
      const row = csv.find(row => row[rowHeaderKey] === columnHeader)
      if (row)
        return {
          ...column,
          [columnHeader]: row[rowHeader],
        }
      else throw `unexpected missing row ${columnHeader}`
    }, {}),
  }))
  return invertedCsv
}
