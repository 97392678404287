import converter from 'json-2-csv'
import jsPDF from 'jspdf'
import * as type from '../type'
import { simpleInvert } from './results'

export const simpleReformatForCSV = (rawData: Object): type.CSV =>
  Array.isArray(rawData)
    ? rawData
    : Object.entries(rawData).map(([key, value]) => {
        return {
          [' ']: key,
          ...value,
        } as type.CSVRow
      })

const emptyCSVIncidenceSet = () => [] as Array<type.CSVIncidenceRow>

export const incidenceReformatForCSV = rawData => {
  const csvData = Object.entries(rawData).reduce(
    (accData, [intervention, rawInterventionData]) =>
      [
        ...accData,
        ...Object.entries(rawInterventionData as ArrayLike<unknown>).reduce(
          (csvInterventionData, [time, rawTimestepData]) =>
            [
              ...csvInterventionData,
              {
                time: parseInt(time),
                intervention,
                ...(rawTimestepData as Object),
              },
            ] as Array<type.CSVIncidenceRow>,
          emptyCSVIncidenceSet(),
        ),
      ] as Array<type.CSVIncidenceRow>,
    emptyCSVIncidenceSet(),
  )
  return csvData as type.CSV
}

export const icerReformatForCSV = rawData => {
  const csvData = Object.entries(rawData).reduce(
    (csvData, [intervention, data]) => {
      if (type.hasICERData(data)) {
        return [
          ...csvData,
          {
            intervention,
            cost_increment: data.cost_increment,
            qaly_increment: data.qaly_increment,
          },
        ] as type.ICERCsv
      } else return csvData
    },
    [] as type.ICERCsv,
  )
  return csvData
}

export const handleCSVDownload = (rawData, filename) => () => {
  filename = `${filename}_table.csv`
  converter.json2csv(rawData, (err, csv) => {
    if (err) throw err
    else if (csv) {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
  })
}

export const hasRawData = rawData =>
  // raw data must not be falsy
  rawData &&
  // raw data must not be an error message
  typeof rawData === 'object' &&
  !rawData.hasOwnProperty('error') &&
  !rawData.hasOwnProperty('1') &&
  !rawData.hasOwnProperty('0') &&
  // raw data must not be an empty array or object
  ((Array.isArray(rawData) && rawData.length) || Object.keys(rawData).length)

  export const hasRawDataDownload = rawData =>
    // raw data must not be falsy
    rawData &&
    // raw data must not be an error message
    typeof rawData === 'object' &&
    !rawData.hasOwnProperty('error') &&
    // raw data must not be an empty array or object
    ((Array.isArray(rawData) && rawData.length) || Object.keys(rawData).length)

const isCanvasElement = (canvas: any): canvas is HTMLCanvasElement => {
  return canvas && canvas.nodeName === 'CANVAS'
}
// download chart as pdf
export const handleChartDownload = (canvasSelector, filename) => () => {
  const canvas = document.querySelector(canvasSelector)
  if (isCanvasElement(canvas)) {
    const canvasImg = canvas.toDataURL('image/jpeg', 1.0)
    var doc = new jsPDF('landscape')
    doc.setFontSize(20)
    doc.text(15, 15, 'Cool Chart')
    doc.addImage(canvasImg, 'JPEG', 10, 10, 280, 150)
    doc.save(`${filename}_chart.pdf`)
  }
}

type File = {
  key: string
  filename: string
  data: any
  csvTransform: Function
  canvasSelector?: string
}

export const handleAllDownloads = (summary, created_at, raw_files) => {
  summary.created_at = created_at
  const scenarioName = summary['Basic Configurations']['Name of the scenario']
  const files: Array<File> = [
    {
      key: 'cost',
      data: raw_files.cost_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data)),
    },
    {
      key: 'prevalence',
      data: raw_files.prevalence_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data)),
    },
    {
      key: 'mean_prevalence',
      data: raw_files.mean_prevalence_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data)),
    },
    {
      key: 'cumulative_incidence',
      data: raw_files.incidence_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data)),
    },
    {
      key: 'cea',
      data: raw_files.cea_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data)),
    },
    {
      key: 'mean_cost_effectiveness',
      data: raw_files.mean_cea_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data), ' '),
    },
    {
      key: 'per_person_cost_effectiveness',
      data: raw_files.ppc_raw,
      csvTransform: data => simpleInvert(simpleReformatForCSV(data)),
    },
  ]
    .filter(({ data }) => hasRawDataDownload(data))
    .map(file => {
      return {
        ...file,
        filename: `${scenarioName}_${file.key}`,
      }
    })
  files.forEach(({ filename, data, csvTransform, canvasSelector }) => {
    handleCSVDownload(csvTransform(data), filename)()
    if (canvasSelector) {
      handleChartDownload(canvasSelector, filename)()
    }
  })
}

// Filter callback for removing fields
export const removeFields = (unwantedHeaders: Array<string>) => (
  field: type.ParsedCSVRow,
): boolean => !unwantedHeaders.includes(field.Header)
